import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {BRIDGE_URL} from '../../utils/constants';
import {DelayExecutionRule} from './model/delay-execution-rule';
import {DelayExecutionRules} from './model/delay-execution-rules';
import {RuleService} from '../commons/interfaces/rule-service.interface';

@Injectable({
  providedIn: 'root'
})
export class DelayExecRulesService implements RuleService<DelayExecutionRules, DelayExecutionRule> {

  readonly PATH = '/rules/delay-execution-rules';

  constructor(private http: HttpClient) {
  }

  getRules(uuid: string): Observable<DelayExecutionRules> {
    return this.http.get<DelayExecutionRules>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}`);
  }

  applyChanges(uuid: string): Observable<void> {
    return this.http.post<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/reload`, null);
  }

  refreshRules(uuid: string): Observable<DelayExecutionRules> {
    return this.http.post<DelayExecutionRules>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/refresh`, null);
  }

  delete(uuid: string, id: string): Observable<void> {
    return this.http.delete<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/${id}`);
  }

  update(uuid: string, model: DelayExecutionRule): Observable<void> {
    return this.http.put<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/${model.uuid}`, model);
  }

  create(uuid: string, model: DelayExecutionRule): Observable<void> {
    return this.http.post<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}`, model);
  }
}
