import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ChartDateRange, DateRange} from '../../interfaces/date-range';
import {from} from 'rxjs';
import {ChartData, DateSnack} from '../../interfaces';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnChanges {
  @Input() data: ChartData;
  @Input() dateLabels: DateSnack[];
  @Input() isLineChart: boolean;

  @Output() changeDateClick = new EventEmitter<ChartDateRange>();

  public selectedLabel?: string;
  public options = {
    legend: false,
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  };

  ngOnChanges({dateLabels}: SimpleChanges): void {
    if (dateLabels && dateLabels.currentValue) {
      this.selectedLabel = dateLabels.currentValue[0].value;
    }
  }

  public onSetDateRangeClick(dateRange: DateRange): void {
    this.isLineChart = Math.abs(new Date(dateRange.to).valueOf() - new Date(dateRange.from).valueOf()) / 86400000 > 7;
    this.changeDateClick.emit({
      date: {
        from: dateRange.from,
        to: dateRange.to
      },
      isLineChart: this.isLineChart
    });
    this.selectedLabel = null;
  }

  public onSelectedDateChange(selectedDate: string): void {
    this.selectedLabel = selectedDate;
    this.isLineChart = new Date(this.dateLabels[2].value) > new Date(selectedDate);
    this.changeDateClick.emit({
      date: {
        from: selectedDate,
        to: this.dateLabels[0].value
      },
      isLineChart: this.isLineChart
    });
  }
}
