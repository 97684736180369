import {AbstractControl, ValidationErrors} from '@angular/forms';

export class BMValidators {
    static blank(control: AbstractControl): ValidationErrors | null {
        if ((!control.value || /^\s*$/.test(control.value))) {
            return {
                blank: true
            };
        } else {
            return null;
        }
    }
}
