import {Component, EventEmitter, Input, Output } from '@angular/core';
import { Calculation, Transmit } from '../../interfaces';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent {
  @Input() multiplierData: Calculation[];

  @Output() calculationClick = new EventEmitter<Calculation>();
  @Output() downloadMultiplierClick = new EventEmitter<void>();
  @Output() uploadMultiplierCsvClick = new EventEmitter<Transmit>();
  @Output() formApplyClick = new EventEmitter<void>();

  public clonedMultiplier: { [s: string]: Calculation; } = {};
  public tableColumns = [
    { field: 'brokerSymbolName', header: 'Broker symbols' },
    { field: 'lpSymbolName', header: 'LP symbols' },
    { field: 'brokerContractSize', header: 'Broker contract size' },
    { field: 'lpContractSize', header: 'LP contract size' },
    { field: 'calculatedMultiplier', header: 'Calculated multiplier' }
  ];

  public onRowEditInitClick(row: Calculation): void {
    this.clonedMultiplier[row.brokerSymbolName] = {...row};
  }

  public onRowEditSaveClick(row: Calculation, index: number): void {
    if (row.brokerContractSize === 0 || row.lpContractSize === 0) {
      this.multiplierData[index] = this.clonedMultiplier[row.brokerSymbolName];
    } else if (row.brokerContractSize === null || row.lpContractSize === null) {
      row.calculatedMultiplier = null;
    } else {
      this.calculationClick.emit(row);
    }

    delete this.clonedMultiplier[row.brokerSymbolName];
  }

  public onRowEditCancelClick(row: Calculation, index: number): void {
    this.multiplierData[index] = this.clonedMultiplier[row.brokerSymbolName];
    delete this.clonedMultiplier[row.brokerSymbolName];
  }

  public onDownloadMultiplierClick(): void {
    this.downloadMultiplierClick.emit();
  }

  public onUploadMultiplierCsvClick(event: FileUpload, fileUpload: FileUpload): void {
    this.uploadMultiplierCsvClick.emit({
      event: event,
      fileUpload: fileUpload,
    });
  }

  public onFormApplyClick(): void {
    this.formApplyClick.emit();
  }
}
