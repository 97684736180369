import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {BridgeLogsSettings} from 'src/app/details-bridge/commons/model/logs/bridge-logs-settings';
import {Subscription} from 'rxjs';
import {LoaderService} from 'src/app/commons/loader.service';
import {NotificationsService} from 'src/app/commons/notifications.service';
import {ActivatedRoute} from '@angular/router';
import {CurrentLogsService} from 'src/app/details-bridge/current-logs/current-logs.service';
import {SearchLogsService} from 'src/app/details-bridge/search-logs/search-logs.service';
import {AuthenticationService} from 'src/app/commons/security/authentication.service';
import {ErrorManagerService} from 'src/app/commons/error/error-manager.service';
import {CallWrapperService} from 'src/app/commons/call-wrapper.service';
import {TextDecoratorService} from 'src/app/details-bridge/commons/text-decorator.service';
import {SelectItem} from 'primeng/api';
import {Level} from 'src/app/details-bridge/commons/model/logs/level';

@Component({
    selector: 'app-bridge-manager-logs',
    templateUrl: './bridge-manager-logs.component.html',
    styleUrls: ['./bridge-manager-logs.component.scss']
})
export class BridgeManagerLogsComponent implements OnInit, OnDestroy {
    bridgeLogLevels: SelectItem[] = [
        {label: 'All', value: Level[Level.ALL]},
        {label: 'Trace', value: Level[Level.TRACE]},
        {label: 'Debug', value: Level[Level.DEBUG]},
        {label: 'Info', value: Level[Level.INFO]},
        {label: 'Warn', value: Level[Level.WARN]},
        {label: 'Error', value: Level[Level.ERROR]},
        {label: 'Fatal', value: Level[Level.FATAL]}
    ];

    @ViewChild('logs', {static: false}) logsTable: any;
    today = new Date();
    searchSettings: BridgeLogsSettings;
    bridgeLogs: string[] = null;

    private _subscription: Subscription;

    constructor(
        private loader: LoaderService,
        private notification: NotificationsService,
        private activeRoute: ActivatedRoute,
        private bridgeLogsService: CurrentLogsService,
        private bridgeSearchService: SearchLogsService,
        private authService: AuthenticationService,
        private errorManager: ErrorManagerService,
        private callWrapper: CallWrapperService,
        private decorator: TextDecoratorService,
        private loadingService: LoaderService) {
        this.setEmptyFilters();
    }

    ngOnInit(): void {
        this._subscription = this.bridgeLogsService
            .getCurrentBMLogs()
            .subscribe(bridgeLogs => this.setLogs(bridgeLogs.bmLogs));
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    search(): void {
        this.loadingService.loading = true;
        this.bridgeLogs = null;
        this.bridgeSearchService.searchBmLogs(this.searchSettings, 0).subscribe(
            bridgeLogs => {
                this.loadingService.loading = false;
                if (bridgeLogs) {
                    this.setLogs(bridgeLogs.bmLogs);
                } else {
                    this.bridgeLogs = [];
                }
                this.logsTable.first = 0;
            },
            err =>
                this.errorManager.handle(err, {
                    onDone: () => {
                        this.loadingService.loading = false;
                        this.bridgeLogs = [];
                    }
                })
        );
    }

    private setLogs(logs: string[]): void {
        this.bridgeLogs = this.decorator.decorateLogs(logs);
    }

    private setEmptyFilters(): void {
        const to = new Date();
        to.setSeconds(0, 0);

        const from = new Date(Date.now() - 86400000);
        from.setSeconds(0, 0);
        this.searchSettings = {
            level: this.bridgeLogLevels[0].value.name,
            orderId: '',
            from: from,
            to: to,
            text: ''
        };
    }

    loadData(event: any): void {
        this.loadingService.loading = true;
        this.bridgeLogs = null;
        this.bridgeSearchService
            .searchBmLogs(this.searchSettings, event.first)
            .subscribe(
                bridgeLogs => {
                    this.loadingService.loading = false;
                    if (bridgeLogs) {
                        this.setLogs(bridgeLogs.bmLogs);
                    } else {
                        this.bridgeLogs = [];
                    }
                },
                err =>
                    this.errorManager.handle(err, {
                        onDone: () => {
                            this.loadingService.loading = false;
                            this.bridgeLogs = [];
                        }
                    })
            );
    }
}
