import {Injectable} from '@angular/core';
import {RxStompService} from '@stomp/ng2-stompjs';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class WSConnectionInfoService {

    constructor(private stomp: RxStompService) {
    }

    onConnected(): Observable<void> {
        return this.stomp.connected$.pipe(map(() => {
        }));
    }

    onError(): Observable<any> {
        return this.stomp.stompErrors$;
    }
}
