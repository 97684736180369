import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MarketWatchRowDisplay} from '../../market-watch/model/market-watch-row-display';

@Directive({
    selector: '[appLastUpdateRowHighlight]'
})
export class LastUpdateRowHighlightDirective implements OnChanges {

    @Input()
    orderBook: MarketWatchRowDisplay;

    @Input()
    lastUpdate: number;

    @Input()
    interval: number;

    ngOnChanges(changes: SimpleChanges): void {
        this.highlight(this.interval);
    }

    constructor(private el: ElementRef) {
    }

    highlight(interval: number) {
        if (!interval) {
            interval = 5;
        }
        const hrs = this.lastUpdate / 3600;
        if (this.lastUpdate > interval && this.orderBook.isUpdated) {
            this.el.nativeElement.style.backgroundColor = '#eb2405';
        } else if (this.lastUpdate <= interval && this.orderBook.isUpdated) {
            this.el.nativeElement.style.backgroundColor = '#27eb05';
        } else {
            this.el.nativeElement.style.backgroundColor = '#c4c4c4';
            if (hrs > 168) {
                this.el.nativeElement.innerText = 'N/A';
            }
        }
    }
}
