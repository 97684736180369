import {SelectItem} from 'primeng/api';

export const RULE_TYPE: SelectItem[] = [
    {title: 'ALL', value: ''},
    {title: 'VOLUME IN LOTS', value: 'VOLUME_IN_LOTS'},
    {title: 'EXPOSURE', value: 'EXPOSURE'},
    {title: 'PROFIT', value: 'PROFIT'},
];

export const CONDITION_TYPE: SelectItem[] = [
    {title: 'ALL', value: ''},
    {title: 'ABOVE', value: 'ABOVE'},
    {title: 'BELOW', value: 'BELOW'},
    {title: 'ABOVE OR EQUALS', value: 'ABOVE_OR_EQUALS'},
    {title: 'BELOW OR EQUALS', value: 'BELOW_OR_EQUALS'},
];

export const RECEIVERS_LIST: SelectItem[] = [
    {title: 'ALL', value: ''},
    {title: 'OWNER', value: 'OWNER'},
    {title: 'ADMIN', value: 'ADMIN'},
    {title: 'OWNER AND ADMIN', value: 'OWNER_AND_ADMIN'},
];
