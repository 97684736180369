import {SelectItem} from 'primeng/api';
import {BookEnum} from '../enums/book.enum';

export type BookFilterType = BookEnum | 'BOTH';

export const BOOK_TYPES: SelectItem[] = [
  { label: 'Both', value: 'BOTH' },
  { label: 'A-Book', value: 'ABOOK' },
  { label: 'B-Book', value: 'BBOOK' },
];
