export interface NewUserModel {
    name: string;
    password: string;
    repeatPassword: string;
}

export function emptyNewUserModel(): NewUserModel {
    return {
        name: null,
        password: null,
        repeatPassword: null
    };
}
