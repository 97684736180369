import {Injectable} from '@angular/core';
import {SymbolsChangeService} from './symbols-change.service';
import {CallWrapperService} from 'src/app/commons/call-wrapper.service';
import {CoverageRuleService} from '../coverage-rules/coverage-rule.service';
import {ABookRuleService} from '../abook-rules/a-book-rule.service';
import {delay} from 'rxjs/operators';
import {LoaderService} from 'src/app/commons/loader.service';
import {ErrorManagerService} from 'src/app/commons/error/error-manager.service';
import {NotificationsService} from 'src/app/commons/notifications.service';
import {BrokerSymbolsService} from '../commons/broker-symbols.service';
import {LPSymbolsService} from '../commons/lp-symbols.service';


@Injectable()
export class SessionRestartServiceService {

  constructor(private symbolsChange: SymbolsChangeService,
    private brokerSymbolService: BrokerSymbolsService,
    private lpSymbolService: LPSymbolsService,
    private callWrapper: CallWrapperService,
    private coveRuleService: CoverageRuleService,
    private aBookRuleService: ABookRuleService,
    private loader: LoaderService,
    private errorManager: ErrorManagerService,
    private notifications: NotificationsService, ) { }

  restartSessionAfterSymbolsChanged(name, bridge, session): boolean {

    switch (name) {
      case 'broker': {

        this.brokerSymbolService.restartSession(bridge, session).subscribe(() => {
          this.loader.loading = false;
          this.notifications.showSuccessMessage('Session is restarting. Check recent logs');
        }, err => this.errorManager.handle(err, {
          onDone: () => this.loader.loading = false
        }));
        break;
      }
      case 'lp': {

        this.lpSymbolService.restartSession(bridge, session).subscribe(() => {
          this.loader.loading = false;
          this.notifications.showSuccessMessage('Session is restarting. Check recent logs');
        }, err => this.errorManager.handle(err, {
          onDone: () => this.loader.loading = false
        }));


        break;
      }
      case 'coverage': {
        this.callWrapper.withLoaderAndSingleUuid(
          uuid => this.coveRuleService.applyChanges(uuid).pipe(delay(500)),
          () => this.notifications.showSuccessMessage('Changes loaded!')
        );

        break;
      }
      case 'abook': {
        this.callWrapper.withLoaderAndSingleUuid(
          uuid => this.aBookRuleService.applyChanges(uuid).pipe(delay(500)),
          () => this.notifications.showSuccessMessage('Changes loaded!')
        );

        break;
      }
      default: {
        break;
      }
    }
    return false;
  }
}
