import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {EditRulesScheduler, ManualOverrideData, RulesScheduler} from '../interfaces';
import {BrokerSession} from '../../commons/model/symbol/broker/broker-session';
import {map} from 'rxjs/operators';

@Injectable()
export class RulesSchedulerDataHttpService {
  readonly PATH = '/rules/scheduler';
  readonly MANUAL_OVERRIDE_PATH = '/manual-override';

  constructor(private http: HttpClient) {}

  public getRulesScheduler(uuid: string): Observable<RulesScheduler[]> {
    return this.http.get<RulesScheduler[]>(`${environment.hostUrl}${this.PATH}/bridge/${uuid}`)
        .pipe(map(rules => this._datePreparation(rules)));
  }

  public getBrokerSession(uuid: string): Observable<BrokerSession[]> {
    return this.http.get<BrokerSession[]>(`${environment.hostUrl}/bridges/${uuid}/symbols/broker`);
  }

  public postRulesScheduler(rule: EditRulesScheduler): Observable<RulesScheduler> {
    return this.http.post<RulesScheduler>(`${environment.hostUrl}${this.PATH}`, rule);
  }

  public putRulesScheduler(id: number, rule: EditRulesScheduler): Observable<RulesScheduler> {
    return this.http.put<RulesScheduler>(`${environment.hostUrl}${this.PATH}/${id}`, rule);
  }

  public deleteRuleScheduler(id: number): Observable<unknown> {
    return this.http.delete<unknown>(`${environment.hostUrl}${this.PATH}/${id}`);
  }

  public setManualOverride(
    manualOverrideData: ManualOverrideData
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.hostUrl}${this.PATH}/${manualOverrideData.ruleId}${this.MANUAL_OVERRIDE_PATH}`,
      manualOverrideData
    );
  }

  public setRuleToAuto(
    ruleId: string
  ): Observable<void> {
    return this.http.delete<void>(
      `${environment.hostUrl}${this.PATH}/${ruleId}${this.MANUAL_OVERRIDE_PATH}`
    );
  }

  private _datePreparation(rules: RulesScheduler[]): RulesScheduler[] {
    return rules.map(rule => {
      return {
        ...rule,
        from: {
          ...rule.from,
          hour: +rule.from.hour < 10 ? `0${rule.from.hour}` : rule.from.hour,
          minute: +rule.from.minute < 10 ? `0${rule.from.minute}` : rule.from.minute,
        },
        to: {
          ...rule.to,
          hour: +rule.to.hour < 10 ? `0${rule.to.hour}` : rule.to.hour,
          minute: +rule.to.minute < 10 ? `0${rule.to.minute}` : rule.to.minute,
        }
      };
    });
  }
}
