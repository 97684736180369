import {Injectable} from '@angular/core';
import {RulesScheduler} from '../interfaces';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RulesSchedulerEnum} from '../enums';

@Injectable()
export class FormCreatorService {
  constructor(private fb: FormBuilder ) {}

  public getRulesSchedulerForm (bridgeUUID: string, selectedRule?: RulesScheduler): FormGroup {
    return this.fb.group({
      id: [selectedRule ? selectedRule.id : ''],
      bridgeUUID: [selectedRule ? selectedRule.bridgeUUID : bridgeUUID, Validators.required],
      from: this.fb.group({
        day: [selectedRule ? selectedRule.from.day : 0, Validators.required],
        time: [selectedRule ? `${selectedRule.from.hour}:${selectedRule.from.minute}` : '', Validators.required]
      }),
      to: this.fb.group({
        day: [selectedRule ? selectedRule.to.day : 0, Validators.required],
        time: [selectedRule ? `${selectedRule.to.hour}:${selectedRule.to.minute}` : '', Validators.required]
      }),
      symbols: [selectedRule ? selectedRule.symbols : '', Validators.required],
      sessionName: [selectedRule ? selectedRule.sessionName : '', Validators.required],
      field: [selectedRule ? selectedRule.field : RulesSchedulerEnum.bidMarkup, Validators.required],
      adjustment: [selectedRule ? selectedRule.adjustment : '', Validators.required],
      active: [selectedRule ? selectedRule.active : false],
      manualOverrideData: [selectedRule ? selectedRule.manualOverrideData : null],
    });
  }
}
