import {DateInterval} from '../enums/date-interval';
import {DateSnack} from '../interfaces';

export class DateLabels {
    public static getDateLabels(): DateSnack[] {
        return [
            {title: 'Today', value: this.getDate(DateInterval.TODAY)},
            {title: 'Yesterday', value: this.getDate(DateInterval.YESTERDAY)},
            {title: 'Last Week', value: this.getDate(DateInterval.LAST_WEEK)},
            {title: 'Last Month', value: this.getDate(DateInterval.LAST_MONTH)},
            {title: 'Last Year', value: this.getDate(DateInterval.LAST_YEAR)}
        ];
    }

    public static getShortDateLabels(): DateSnack[] {
        return [
            {title: 'Last Week', value: this.getDate(DateInterval.LAST_WEEK)},
            {title: 'Last Month', value: this.getDate(DateInterval.LAST_MONTH)},
            {title: 'Last Year', value: this.getDate(DateInterval.LAST_YEAR)}
        ];
    }

    private static getDate(dateInterval: DateInterval): string {
        const currentDate = new Date();
        const dataOffset = 86400000;

        switch (dateInterval) {
            case DateInterval.YESTERDAY: currentDate.setTime(currentDate.getTime() - dataOffset); break;
            case DateInterval.LAST_WEEK: currentDate.setTime(currentDate.getTime() - (dataOffset * 6)); break;
            case DateInterval.LAST_MONTH: currentDate.setMonth(currentDate.getMonth() - 1); break;
            case DateInterval.LAST_YEAR: currentDate.setFullYear(currentDate.getFullYear() - 1); break;
        }

        return currentDate.toISOString().split('T')[0];
    }
}
