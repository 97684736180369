import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {ErrorManagerService} from './error-manager.service';

@Injectable()
export class ErrorHandlerImpl implements ErrorHandler {

    constructor(private injector: Injector, private ngZone: NgZone) {
    }

    handleError(error: Error | HttpErrorResponse): void {
        if (this.isNotIgnoredError(error)) {
            console.error(error);

            const manager = this.injector.get(ErrorManagerService);
            this.ngZone.run(() => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        manager.handleForbiddenRequest();
                    } else {
                        manager.handleOtherHttpError(error);
                    }
                }
            });
        }
    }

    private isNotIgnoredError(error: Error | HttpErrorResponse) {
        return !error.message.startsWith('ExpressionChangedAfterItHasBeenCheckedError');
    }
}
