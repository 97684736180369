import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SearchLogsService} from '../search-logs/search-logs.service';
import {ActivatedRoute} from '@angular/router';
import {map, skip, switchMap, take} from 'rxjs/operators';
import {SelectItem} from 'primeng/api';
import {Level} from '../commons/model/logs/level';
import {BridgeLogsSettings} from '../commons/model/logs/bridge-logs-settings';
import {interval, Subscription} from 'rxjs';
import {LoaderService} from '../../commons/loader.service';
import {NotificationsService} from '../../commons/notifications.service';
import {ErrorManagerService} from '../../commons/error/error-manager.service';
import {TextDecoratorService} from '../commons/text-decorator.service';
import {CurrentLogsService} from '../current-logs/current-logs.service';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {CallWrapperService} from '../../commons/call-wrapper.service';

@Component({
    selector: 'app-hedge-logs',
    templateUrl: './hedge-logs.component.html',
    styleUrls: ['./hedge-logs.component.scss']
})
export class HedgeLogsComponent implements OnInit, OnDestroy {

    bridgeLogLevels: SelectItem[] = [
        {label: 'All', value: Level[Level.ALL]},
        {label: 'Trace', value: Level[Level.TRACE]},
        {label: 'Debug', value: Level[Level.DEBUG]},
        {label: 'Info', value: Level[Level.INFO]},
        {label: 'Warn', value: Level[Level.WARN]},
        {label: 'Error', value: Level[Level.ERROR]},
        {label: 'Fatal', value: Level[Level.FATAL]}
    ];

    today = new Date();
    uuid: string;
    searchSettings: BridgeLogsSettings;
    bridgeLogs: string[] = null;

    private _subscription = new Subscription();

    constructor(
        private loader: LoaderService,
        private notification: NotificationsService,
        private activeRoute: ActivatedRoute,
        private bridgeLogsService: CurrentLogsService,
        private bridgeSearchService: SearchLogsService,
        private authService: AuthenticationService,
        private errorManager: ErrorManagerService,
        private callWrapper: CallWrapperService,
        private decorator: TextDecoratorService,
        private loadingService: LoaderService
    ) {
        this.setEmptyFilters();
    }

    ngOnInit() {
        this._subscription.add(
            this.activeRoute.params.pipe(map(params => this.uuid = params['uuid']),
                switchMap(uuid => this.bridgeLogsService.getBridgeHedgeLogs(uuid)))
                .subscribe(bridgeLogs => this.setLogs(bridgeLogs.logs)));
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }

    search() {
        this.loadingService.loading = true;
        this.bridgeLogs = null;
        this.bridgeSearchService.searchHedgeLogs(this.uuid, this.searchSettings).subscribe(
            bridgeLogs => {
                this.loadingService.loading = false;
                if (bridgeLogs) {
                    this.setLogs(bridgeLogs.logs);
                } else {
                    this.bridgeLogs = [];
                }
            },
            err =>
                this.errorManager.handle(err, {
                    onDone: () => {
                        this.loadingService.loading = false;
                        this.bridgeLogs = [];
                    }
                })
        );
    }

    private setLogs(logs: string[]) {
        this.bridgeLogs = this.decorator.decorateLogs(logs);
    }

    private setEmptyFilters() {
        const to = new Date();
        to.setSeconds(0, 0);

        const from = new Date(Date.now() - 86400000);
        from.setSeconds(0, 0);
        this.searchSettings = {
            level: this.bridgeLogLevels[0].value.name,
            orderId: '',
            from: from,
            to: to,
            text: ''
        };
    }
}
