import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BridgeLogs} from '../commons/model/logs/bridge-logs';
import {BridgeLogsSettings} from '../commons/model/logs/bridge-logs-settings';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BridgeManagerLogs} from '../commons/model/logs/bridge-manager-logs';

@Injectable({
    providedIn: 'root'
})
export class SearchLogsService {

    readonly searchBridgeLogsUrl = '/search-bridge-logs';

    constructor(private http: HttpClient) {
    }

    search(uuid: string, settings: BridgeLogsSettings): Observable<BridgeLogs> {
        const url = environment.hostUrl + this.searchBridgeLogsUrl + '/' + uuid;
        return this.http.post<BridgeLogs>(url, settings);
    }

    searchBmLogs(settings: BridgeLogsSettings, page: number): Observable<BridgeManagerLogs> {
        const url = environment.hostUrl + this.searchBridgeLogsUrl + '/bm-logs/' + page;
        return this.http.post<BridgeManagerLogs>(url, settings);
    }

    searchAutoHedgeLogs(settings: BridgeLogsSettings, page: number, uuid: string): Observable<BridgeManagerLogs> {
        const url = environment.hostUrl + this.searchBridgeLogsUrl + '/auto-hedge/' + uuid + '/' + page;
        return this.http.post<BridgeManagerLogs>(url, settings);
    }

    searchHedgeLogs(uuid: string, settings: BridgeLogsSettings): Observable<BridgeLogs> {
        const url = `${environment.hostUrl}${this.searchBridgeLogsUrl}/hedging/${uuid}`;
        return this.http.post<BridgeLogs>(url, settings);
    }
}
