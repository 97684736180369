import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MarketWatchRow} from '../model/market-watch-row';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {UserService} from '../../users/user.service';
import {UserDto} from '../../commons/dto/user-dto';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {map, switchMap} from 'rxjs/operators';
import {genMWRowKey} from '../../utils/utils';

@Component({
    selector: 'app-filter-interval',
    templateUrl: './filter-interval.component.html',
    styleUrls: ['./filter-interval.component.scss']
})
export class FilterIntervalComponent implements OnInit, OnDestroy {

    @Input()
    uuid: string;

    @Input()
    positions: any[];

    @Output()
    saveChanges = new EventEmitter<{ [key: string]: { [key: string]: number } }>();
    filterIntervalMap: { [key: string]: { [key: string]: number } } = {};
    interval = new FormControl('', [Validators.min(0), Validators.pattern('^\\d+$')]);
    form: FormGroup;
    cols = [
        {field: 'symbol', header: 'Symbol'},
        {field: 'filterInterval', header: 'Filter interval (sec.)'}
    ];
    private currentUser: UserDto;
    private sub: Subscription;

    constructor(private auth: AuthenticationService,
                private userService: UserService) {
    }

    ngOnInit() {
        this.sub = this.auth.userInfo.pipe(
            map(auth => this.currentUser = auth),
            switchMap(data => this.userService.getFilterInterval(data.name))
        ).subscribe(el => {
            if (el && el[this.uuid]) {
                this.filterIntervalMap = this.initIntervalMap(el);
            } else {
                this.filterIntervalMap = this.prepareTable();
            }
        });

        this.form = new FormGroup({
            interval: this.interval
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    change(row: MarketWatchRow, event: any) {
        const key = genMWRowKey(row);
        const mapElement = this.filterIntervalMap[this.uuid];
        mapElement[key] = +this.interval.value;
    }

    save() {
        this.saveChanges.emit(this.filterIntervalMap);
    }

    private prepareTable() {
        const symbolMap: { [key: string]: number } = {};
        const result: { [key: string]: { [key: string]: number } } = {};
        this.positions.forEach(symbol => {
            const key = genMWRowKey(symbol);
            symbolMap[key] = 5;
            result[this.uuid] = symbolMap;
        });
        return result;
    }

    private initIntervalMap(filterInterval: { [p: string]: { [p: string]: number } }) {
        const symbolMap: { [key: string]: number } = {};
        const result: { [p: string]: { [p: string]: number } } = {};
        this.positions.forEach(symbol => {
            const key = genMWRowKey(symbol);
            if (!filterInterval[this.uuid] || !filterInterval[this.uuid][key]) {
                symbolMap[key] = 5;
            } else {
                symbolMap[key] = filterInterval[this.uuid][key];
            }
        });
        result[this.uuid] = symbolMap;
        return result;
    }
}
