import { Pipe, PipeTransform } from '@angular/core';
import { RulesScheduler } from '../interfaces';
import { DAY_FOR_THE_WEEK } from '../helpers/filter-data';

@Pipe({
  name: 'prepareDate'
})
export class PrepareDatePipe implements PipeTransform {
  transform(rule: RulesScheduler, key: 'from' | 'to'): string {
    return `${DAY_FOR_THE_WEEK.find(day => day.value === rule[key].day).label}, ${rule[key].hour}:${rule[key].minute}`;
  }

}
