import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../commons/security/authentication.service';
import {Router} from '@angular/router';
import {LoaderService} from '../commons/loader.service';
import {NotificationsService} from '../commons/notifications.service';
import {enterComponent} from '../commons/animations';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [enterComponent]
})

export class LoginComponent implements OnInit {

    credentials = {username: '', password: ''};

    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private loaderService: LoaderService,
                private notifications: NotificationsService) {
    }

    ngOnInit(): void {
        this.loaderService.forceStopLoading();
    }

    login() {
        this.loaderService.setLoading(true);
        this.authenticationService
            .authenticate(this.credentials)
            .subscribe(
                () => {
                    this.router.navigateByUrl('/');
                },
                (error) => {
                    this.loaderService.setLoading(false);
                    if (error.status === 401) {
                        this.notifications.showWarningMessage('Login fail', 'Wrong email or password');
                    } else {
                        this.notifications.showWarningMessage('Internal Server Error', 'Please contact with admin');
                    }
                });
    }
}
