import {Pipe, PipeTransform} from '@angular/core';
import {OrderBook} from '../dto/order-book';
import Big from 'big.js';

@Pipe({
    name: 'spread'
})
export class SpreadPipe implements PipeTransform {

    transform(orderBook: OrderBook): Big {
        if (orderBook.bidPositions
            && orderBook.askPositions
            && orderBook.askPositions.length > 0
            && orderBook.bidPositions.length > 0) {
            return new Big(orderBook.askPositions[0].price).minus(new Big(orderBook.bidPositions[0].price));
        } else {
            return new Big(0);
        }
    }
}
