import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ChartData} from '../../interfaces';
import {dashCaseToCamelCase} from '@angular/compiler/src/util';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnChanges {
  @Input() data?: ChartData;
  @Input() subTitle: string;
  @Input() mark?: string;

  public isNumericValue: boolean;
  public statistic = {defaultData: [], percentData: []};
  public readonly options = {
    legend: false,
    cutoutPercentage: '80',
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      enabled: false
    }
  };

  public ngOnChanges({ data }: SimpleChanges): void {
    if (data.currentValue) {
      const statisticData = data.currentValue.datasets[0].data;
      const statisticSumma = statisticData[1] + statisticData[0];
      this.statistic = {
        defaultData: statisticData,
        percentData: [
          this.getPercentValue(statisticData[0], statisticSumma),
          this.getPercentValue(statisticData[1], statisticSumma)
        ]
      };
    }
  }

  public onChangeStrategyClick(event: {checked: boolean; originalEvent: PointerEvent}): void {
    this.isNumericValue = event.checked;
  }

  private getPercentValue(statisticValue: number, statisticSumma: number): string {
    return (statisticValue ? statisticValue / statisticSumma * 100 : 0).toFixed(2) + '%';
  }
}
