import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    counter = 0;

    private subject = new Subject<boolean>();

    constructor() {
    }

    get loader(): Observable<boolean> {
        return this.subject.asObservable();
    }

    set loading(isLoading: boolean) {
        if (isLoading) {
            this.counter++;
        } else {
            this.counter--;
        }

        if (this.counter > 0) {
            this.subject.next(true);
        } else {
            this.counter = 0;
            this.subject.next(false);
        }
    }

    setLoading(isLoading: boolean) {
        if (isLoading) {
            this.counter++;
        } else {
            this.counter--;
        }

        if (this.counter > 0) {
            this.subject.next(true);
        } else {
            this.counter = 0;
            this.subject.next(false);
        }
    }

    forceStopLoading() {
        if (this.counter !== 0) {
            this.counter = 0;
            this.subject.next(false);
        }
    }
}
