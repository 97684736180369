import {SelectItem} from 'primeng/api';

export const DAY_FOR_THE_WEEK: SelectItem[] = [
    {label: 'Monday', value: 0 },
    {label: 'Tuesday', value: 1},
    {label: 'Wednesday', value: 2},
    {label: 'Thursday', value: 3},
    {label: 'Friday', value: 4},
    {label: 'Saturday', value: 5},
    {label: 'Sunday', value: 6}
];

export const RULE_SCHEDULER: SelectItem[] = [
    {label: 'BID_MARKUP [pips]', value: 'BID_MARKUP'},
    {label: 'ASK_MARKUP [pips]', value: 'ASK_MARKUP'},
    {label: 'MIN_SPREAD [pips]', value: 'MIN_SPREAD'},
    {label: 'MAX1_SPREAD [pips]', value: 'MAX1_SPREAD'},
    {label: 'BBOOK_EXEC_DELAY [ms]', value: 'BBOOK_EXEC_DELAY'},
];

export const STATUSES: SelectItem[] = [
    {label: 'ALL', value: ''},
    {label: 'ACTIVE', value: true},
    {label: 'INACTIVE', value: false}
];
