import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {FormStatusEnum} from '../../../../commons/enums';
import {EditRulesScheduler, ManualOverrideData, OverrideType, RulesScheduler} from '../../interfaces';
import {RulesSchedulerEnum} from '../../enums';
import {SelectItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {CallWrapperService} from '../../../../commons/call-wrapper.service';
import {BrokerSymbolsService} from '../../../commons/broker-symbols.service';
import {DAY_FOR_THE_WEEK, RULE_SCHEDULER} from '../../helpers/filter-data';
import {BrokerSession} from '../../../commons/model/symbol/broker/broker-session';

@Component({
  selector: 'app-rules-scheduler-dialog',
  templateUrl: './rules-scheduler-dialog.component.html',
  styleUrls: ['./rules-scheduler-dialog.component.scss']
})
export class RulesSchedulerDialogComponent implements OnInit, OnChanges, OnDestroy {
  @Input() rulesSchedulerForm: FormGroup;
  @Input() formStatus: FormStatusEnum;
  @Input() brokerSessions: BrokerSession[];
  @Output() copyFormSubmitClick = new EventEmitter<RulesScheduler>();
  @Output() setToAutoSubmitClick = new EventEmitter<string>();
  @Output() manualOverrideSubmitClick = new EventEmitter<ManualOverrideData>();
  @Output() createFormSubmitClick = new EventEmitter<EditRulesScheduler>();
  @Output() updateFormSubmitClick = new EventEmitter<EditRulesScheduler>();
  @Output() deleteFormSubmitClick = new EventEmitter<void>();

  public brokerItems: SelectItem[] = [];
  public ruleScheduler = RULE_SCHEDULER;
  public daysOfTheWeek = DAY_FOR_THE_WEEK;
  public brokerSymbols: SelectItem[] = [];
  public ruleSchedulerEnum = RulesSchedulerEnum;
  public formStatusEnum = FormStatusEnum;

  private _sessionNameSub: Subscription;
  private _fieldSub: Subscription;
  private _subscription: Subscription;

  constructor(
      private callWrapper: CallWrapperService,
      private brokerSymbolService: BrokerSymbolsService) {}

  public ngOnInit(): void {
    this._subscription = this.callWrapper.withStableUuid(uuid => this.brokerSymbolService.getBrokerSessionsNames(uuid),
        brokerSessions => {
          this.brokerItems = brokerSessions.map(el => {
            return {label: el, value: el};
          });
          this.brokerItems.unshift({label: '', value: ''});
        }
    );
  }

  public ngOnChanges({rulesSchedulerForm}: SimpleChanges) {
    if (rulesSchedulerForm && rulesSchedulerForm.currentValue) {
      this.brokerSymbols = [];
      if (this._sessionNameSub) { this._sessionNameSub.unsubscribe(); }
      if (this._sessionNameSub) { this._fieldSub.unsubscribe(); }
      if (this.formStatus === FormStatusEnum.edit) {
        this._getBrokerSymbols(rulesSchedulerForm.currentValue.controls['sessionName'].value);
      }
      this._addSessionNameSub(rulesSchedulerForm.currentValue);
      this._addFieldSub(rulesSchedulerForm.currentValue);
    }
  }

  get isRuleActive(): boolean {
    return !!this.rulesSchedulerForm.controls['active'].value;
  }

  get hasManualOverrideData(): boolean {
    return !!this.rulesSchedulerForm.controls['manualOverrideData'].value;
  }

  public isAdjustmentAbove(): boolean {
    return this.rulesSchedulerForm.controls['field'].value !== this.ruleSchedulerEnum.bBookExecDelay &&
    (this.rulesSchedulerForm.controls['adjustment'].value > 100 || this.rulesSchedulerForm.controls['adjustment'].value < -100);
  }

  public onFormCreateSubmitClick(): void {
    if (this.rulesSchedulerForm.valid) {
      this.createFormSubmitClick.emit(this._preparingSubmitData());
    }
  }

  public onCopy(): void {
    this.copyFormSubmitClick.emit({
      ...this.rulesSchedulerForm.getRawValue(),
      ...this._preparingSubmitData(),
    });
  }

  public setManualOverride(isRuleActive: boolean): void {
    const {bridgeUUID, id: ruleId} = this.rulesSchedulerForm.value;
    this.manualOverrideSubmitClick.emit({
      bridgeUUID,
      ruleId,
      overrideType: isRuleActive ? OverrideType.DEACTIVATION : OverrideType.ACTIVATION
    });
  }

  public setRuleToAuto(): void {
    this.setToAutoSubmitClick.emit(this.rulesSchedulerForm.value.id);
  }

  public onFormUpdateSubmitClick(): void {
    if (this.rulesSchedulerForm.valid) {
      this.updateFormSubmitClick.emit(this._preparingSubmitData());
    }
  }

  public onDeleteFormSubmitClick(): void {
    this.deleteFormSubmitClick.emit();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _addSessionNameSub(rulesSchedulerForm: FormGroup): void {
    this._sessionNameSub = rulesSchedulerForm.controls['sessionName'].valueChanges.subscribe(brokerName => {
    this.rulesSchedulerForm.get('symbols').setValue([]);

      if (!brokerName) {
        this.brokerSymbols = [];
        return;
      }

      this._getBrokerSymbols(brokerName);
    });
  }

  private _addFieldSub(rulesSchedulerForm: FormGroup): void {
    this._fieldSub = rulesSchedulerForm.controls['field'].valueChanges.subscribe(field => {
      field.value === RulesSchedulerEnum.bBookExecDelay
          ? rulesSchedulerForm.controls['adjustment'].setValidators([Validators.required, Validators.min(0)])
          : rulesSchedulerForm.controls['adjustment'].setValidators(Validators.required);
      rulesSchedulerForm.controls['adjustment'].updateValueAndValidity();
    });
  }

  private _getBrokerSymbols(brokerName: string): void {
    this.brokerSymbols = this.brokerSessions
        .find(session => session.sessionName === brokerName).symbols
        .map(symbol => ({ label: symbol.symbolName, value: symbol.symbolName }))
        .sort((firstElement, secondElement) =>
            (firstElement.label < secondElement.label ? -1 : 1));
  }

  private _preparingSubmitData(): EditRulesScheduler {
    const fromTime = this.rulesSchedulerForm.get('from').get('time').value;
    const toTime = this.rulesSchedulerForm.get('to').get('time').value;
    const { bridgeUUID, sessionName, symbols, field, adjustment } = this.rulesSchedulerForm.value;
    return {
      bridgeUUID,
      sessionName,
      symbols,
      field,
      adjustment,
      from: {
        day: this.rulesSchedulerForm.get('from').get('day').value,
        hour: fromTime.split(':')[0].toString(),
        minute: fromTime.split(':')[1].toString(),
      },
      to: {
        day: this.rulesSchedulerForm.get('to').get('day').value,
        hour: toTime.split(':')[0].toString(),
        minute: toTime.split(':')[1].toString(),
      }
    };
  }
}
