import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { BridgeSimpleStatus } from '../interfaces/bridge-simple-status';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class BridgesPublisherService {
    private _bridgesInfo = new ReplaySubject<BridgeSimpleStatus[]>(1);

    get bridgesInfo(): Observable<BridgeSimpleStatus[]> {
        return this._bridgesInfo.pipe(
            distinctUntilChanged((x, y) => _.isEqual(x, y)),
        );
    }

    set(list: BridgeSimpleStatus[]) {
        this._bridgesInfo.next(list);
    }
}
