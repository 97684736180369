import {RuleService} from 'src/app/details-bridge/commons/interfaces/rule-service.interface';
import {CallWrapperService} from '../../../commons/call-wrapper.service';
import {RowReorderEvent} from '../../../commons/interfaces/row-reorder';

interface RuleDisplay {
  brokerName: string[];
  lpName?: string[];
  weight: number;
}

export abstract class RulesRowReorder<T, M, K extends RuleDisplay> {
  rules: K[];

  constructor(
    protected callWrapper: CallWrapperService,
    protected ruleService: RuleService<T, M>
  ) {}

  onRowReorder({ dropIndex }: RowReorderEvent, isCoverage?: boolean): void {
    const currentReorderRule = this.rules[dropIndex];
    const reorderedRule = {
      ...currentReorderRule,
      ...(currentReorderRule.lpName
        ? { lpName: currentReorderRule.lpName.join(',') }
        : {}),
      brokerName: currentReorderRule.brokerName.join(','),
      [isCoverage ? 'coverageWeight' : 'weight']: dropIndex,
    } as unknown as M;

    this.callWrapper.withLoaderAndSingleUuid(
      (uuid) => this.ruleService.update(uuid, reorderedRule),
      () => {
        this.rules = this.updateOnReorder(this.rules, isCoverage);
      }
    );
  }

  // TODO: Move sorting logic to backend side.
  //       Current version has sorting logic on frontend.
  //       When reordering every weight parameter has to be indexed(from 0, 1, 2, ...., to the last)
  //       So we are updating weight of every rule when row is reordered if needed(weight !== index).
  updateOnReorder(rules: K[], isCoverage = false): K[] {
    const weightKey = isCoverage ? 'coverageWeight' : 'weight';
    return rules.map((el, index) => {
      if (el[weightKey] !== index) {
        const indexedRule = {
          ...el,
          ...(el.lpName ? { lpName: el.lpName.join(',') } : {}),
          brokerName: el.brokerName.join(','),
          [weightKey]: index,
        } as unknown as M;

        this.callWrapper.withLoaderAndSingleUuid(
          (uuid) => this.ruleService.update(uuid, indexedRule),
          () => {}
        );

        return {
          ...indexedRule,
          brokerName: el.brokerName,
          lpName: el.lpName,
          weight: index,
        } as unknown as K;
      }
      return el;
    });
  }
}
