import {Component} from '@angular/core';
import {State} from '../../../commons/dto/state';
import {Observable} from 'rxjs';
import {BridgeDetailsPublisherService} from '../../commons/bridge-details-publisher.service';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {LpSession} from '../../commons/model/status/lp-session';
import * as _ from 'lodash';

@Component({
    selector: 'app-lp-session-status',
    templateUrl: './lp-session-status.component.html',
    styleUrls: ['./lp-session-status.component.scss']
})
export class LpSessionStatusComponent {
    $sessions: Observable<LpSession[]>;
    noLpSessions = false;

    constructor(private bridgeDetailsPublisher: BridgeDetailsPublisherService) {
        this.$sessions = this.bridgeDetailsPublisher
            .bridgeInfo
            .pipe(
                distinctUntilChanged((x, y) => _.isEqual(x.lpSessions, y.lpSessions)),
                map(value => {
                    if (value.lpSessions === null) {
                        this.noLpSessions = true;
                    }
                    return value.lpSessions;
                }),
            );
    }

    private getStatusLightColor(state: State): string {
        switch (State[state.toString()]) {
            case State.Active:
                return 'green';
            case State.Inactive:
                return 'yellow';
            case State.Error:
                return 'red';
            case State.New:
                return 'grey';
            case State.NotConfigured:
                return '#1e284c';
        }
    }
}
