import {Injectable} from '@angular/core';
import {BRIDGE_SYMBOL_URL, BRIDGE_URL} from '../../utils/constants';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LPSymbolWithSessionName} from './model/symbol/lp/lp-symbol-with-session-name';
import {LPSession} from './model/symbol/lp/l-p-session';
import {environment} from '../../../environments/environment';
import {LPSymbol} from './model/symbol/lp/lp-symbol';

@Injectable({
    providedIn: 'root'
})
export class LPSymbolsService {

    readonly lpSymbolsUrl = BRIDGE_SYMBOL_URL + '/lp';

    constructor(private http: HttpClient) {
    }

    getLpSessionsNames(uuid: string): Observable<string[]> {
        const url = this.getUrl(uuid) + '/get-lp-sessions-names';
        return this.http.get<string[]>(url);
    }

    getSessions(uuid: string): Observable<LPSession[]> {
        const url = this.getUrl(uuid);
        return this.http.get<LPSession[]>(url);
    }

    getSession(uuid: string, sessionName: string): Observable<LPSession> {
        const url = this.getUrl(uuid) + '/' + sessionName;
        return this.http.get<LPSession>(url);
    }

    refreshSessions(uuid: string): Observable<LPSession[]> {
        const url = this.getUrl(uuid) + '/refresh-sessions';
        return this.http.post<LPSession[]>(url, {});
    }

    refreshSession(uuid: string, sessionName: string): Observable<LPSession> {
        const url = this.getUrl(uuid) + '/refresh-session';
        return this.http.post<LPSession>(url, sessionName);
    }

    updateSymbol(uuid: string, updatedSymbol: LPSymbol): Observable<any> {
        const url = this.getUrl(uuid) + '/update';
        return this.http.put(url, updatedSymbol);
    }

    deleteSymbol(uuid: string, symbolId: number): Observable<any> {
        const url = this.getUrl(uuid) + '/' + symbolId;
        return this.http.delete(url);
    }

    addNewSymbol(uuid: string, newSymbolWithSessionName: LPSymbolWithSessionName): Observable<any> {
        const url = this.getUrl(uuid) + '/add-new';
        return this.http.put(url, newSymbolWithSessionName);
    }

    restartSession(uuid: string, sessionName: string) {
        const url = this.getUrl(uuid) + '/restart-session';
        return this.http.post(url, sessionName);
    }

    private getUrl(uuid: string) {
        return `${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.lpSymbolsUrl}`;
    }

    private encodeRFC3986URIComponent(str) {
        return encodeURIComponent(str)
            .replace(
                /[!'()*]/g,
                (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
            );
    }
}
