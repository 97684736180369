import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'convertSecondsToTimePattern'
})
export class ConvertSecondsToTimePatternPipe implements PipeTransform {

    transform(value: number, args?: any): string {
        let minStr, hrStr;
        let sec = (value % 60).toFixed(0);
        const minute = (Math.floor(value / 60) % 60);
        const hour = Math.floor(+value / 3600);
        hrStr = hour < 10 ? `0${hour}` : hour;
        minStr = minute < 10 ? `0${minute}` : minute;
        sec = sec.length === 1 ? `0${sec}` : sec;
        return `${hrStr}:${minStr}:${sec}`;
    }
}
