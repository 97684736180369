export class BrokerSymbol {
    id: number;
    symbolName: string;
    aliasName: string;
    enabled: boolean;
    bidMarkup: string;
    askMarkup: string;
    coverageRatio: number;
    limitDeviation: string;
    bbookPriceMultiplier: string;
    bbookVolumeMultiplier: string;
    minSpread: string;
    max1Spread: string;
    max2Spread: string;
    bbookExecDelay: number;
    precision: number;
}
