import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ABookRuleDisplay} from '../../abook-rules/model/abook-rule-display';
import {ABookRule} from '../../abook-rules/model/abook-rule';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BMValidators} from '../../../commons/b-m-validators';
import {SelectItem} from 'primeng/api';
import {Subscription, zip} from 'rxjs';
import {CallWrapperService} from '../../../commons/call-wrapper.service';
import {BrokerSymbolsService} from '../../commons/broker-symbols.service';
import {LPSymbolsService} from '../../commons/lp-symbols.service';
import {PriceImprovementRuleDisplay} from '../model/price-improvement-rule-display';
import {PriceImprovementRule} from '../model/price-improvement-rule';

@Component({
  selector: 'app-new-price-improvement-rule',
  templateUrl: './new-price-improvement-rule.component.html',
  styleUrls: ['./new-price-improvement-rule.component.scss']
})
export class NewPriceImprovementRuleComponent implements OnInit, OnDestroy {

  @Input()
  set value(newState: PriceImprovementRuleDisplay) {
    this._value = newState;
    if (newState) {
      this.form.patchValue(this._value);
    } else {
      this.form.reset();
    }
  }

  get value() {
    return this._value;
  }

  @Output()
  update = new EventEmitter<PriceImprovementRule>();

  @Output()
  delete = new EventEmitter<PriceImprovementRule>();

  brokerName = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  instrument = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
  groupName = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
  account = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);

  @Output()
  form = new FormGroup({
    uuid: new FormControl(),
    brokerName: this.brokerName,
    instrument: this.instrument,
    groupName: this.groupName,
    account: this.account,
  });

  brokerItems: SelectItem[] = [];

  private _value: PriceImprovementRuleDisplay;
  private _subscription: Subscription;

  constructor(private callWrapper: CallWrapperService,
              private brokerSymbolService: BrokerSymbolsService) {
  }

  ngOnInit() {
    this._subscription = this.callWrapper.withStableUuid(uuid => this.brokerSymbolService.getBrokerSessionsNames(uuid),
        brokerSessions => {
          this.brokerItems = brokerSessions.map(el => {
            return {label: el, value: el};
          });
        }
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  send() {
    this.update.emit(this.convertToPriceRule(this.form.value) as PriceImprovementRule);
  }

  deleteItem() {
    this.delete.emit(this.form.value as PriceImprovementRule);
  }

  private convertToPriceRule(priceImprovementRuleDisplay: PriceImprovementRuleDisplay) {
    return {
      account: priceImprovementRuleDisplay.account,
      brokerName: priceImprovementRuleDisplay.brokerName.join(','),
      instrument: priceImprovementRuleDisplay.instrument,
      groupName: priceImprovementRuleDisplay.groupName,
      uuid: priceImprovementRuleDisplay.uuid,
    };
  }
}
