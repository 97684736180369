import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BRIDGE_SYMBOL_URL, BRIDGE_URL} from '../../utils/constants';
import {BrokerSymbolWithSessionName} from './model/symbol/broker/broker-symbol-with-session-name';
import {BrokerSession} from './model/symbol/broker/broker-session';
import {environment} from '../../../environments/environment';
import {BrokerSymbol} from './model/symbol/broker/broker-symbol';

@Injectable({
    providedIn: 'root'
})
export class BrokerSymbolsService {

    readonly brokerSymbolsUrl = BRIDGE_SYMBOL_URL + '/broker';

    constructor(private http: HttpClient) {
    }

    getDecimalPlaces(uuid: string): Observable<number | null> {
        const url = this.getUrl(uuid) + '/decimal-places';
        return this.http.get<number | null>(url);
    }

    getBrokerSessionsNames(uuid: string): Observable<string[]> {
        const url = this.getUrl(uuid) + '/get-broker-sessions-names';
        return this.http.get<string[]>(url);
    }

    getSessions(uuid: string): Observable<BrokerSession[]> {
        const url = this.getUrl(uuid);
        return this.http.get<BrokerSession[]>(url);
    }

    getSession(uuid: string, sessionName: string): Observable<BrokerSession> {
        const url = this.getUrl(uuid) + '/' + sessionName;
        return this.http.get<BrokerSession>(url);
    }

    refreshSessions(uuid: string): Observable<BrokerSession[]> {
        const url = this.getUrl(uuid) + '/refresh-sessions';
        return this.http.post<BrokerSession[]>(url, {});
    }

    refreshSymbol(uuid: string, sessionName: string): Observable<BrokerSession> {
        const url = this.getUrl(uuid) + '/refresh-session';
        return this.http.post<BrokerSession>(url, sessionName);
    }

    updateSymbol(uuid: string, updatedSymbol: BrokerSymbol): Observable<any> {
        const url = this.getUrl(uuid) + '/update';
        return this.http.put(url, updatedSymbol);
    }

    deleteSymbol(uuid: string, symbolId: number): Observable<any> {
        const url = this.getUrl(uuid) + '/' + symbolId;
        return this.http.delete(url);
    }

    addNewSymbol(uuid: string, newSymbolWithSessionName: BrokerSymbolWithSessionName): Observable<any> {
        const url = this.getUrl(uuid) + '/add-new';
        return this.http.put(url, newSymbolWithSessionName);
    }

    restartSession(uuid: string, sessionName: string) {
        const url = this.getUrl(uuid) + '/restart-session';
        return this.http.post(url, sessionName);
    }


    private getUrl(uuid: string) {
        return `${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.brokerSymbolsUrl}`;
    }
}
