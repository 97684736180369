import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SymbolTickMonitoring } from '../../commons/interfaces/symbol-tick-monitoring.interface';

@Injectable()
export class TicksHttpService {
  constructor(private http: HttpClient) {}

  public getSymbolTickMonitoring(
    bridgeUuid: string,
    brokerName: string
  ): Observable<SymbolTickMonitoring[]> {
    return this.http.get<SymbolTickMonitoring[]>(
      `${environment.hostUrl}/ticks/${bridgeUuid}`,
      {
        params: { brokerName },
      }
    );
  }

  public disableSymbolTickMonitoring(
    bridgeUuid: string,
    symbolId: string
  ): Observable<void> {
    return this.http.delete<void>(
      `${environment.hostUrl}/ticks/${bridgeUuid}`,
      {
        params: { symbolId },
      }
    );
  }

  public enableSymbolTickMonitoring(
    bridgeUuid: string,
    symbolId: string
  ): Observable<void> {
    return this.http.post<void>(
      `${environment.hostUrl}/ticks/${bridgeUuid}`,
      null,
      {
        params: { symbolId },
      }
    );
  }
}
