import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, of, ReplaySubject} from 'rxjs';
import {catchError, distinctUntilChanged, finalize, tap} from 'rxjs/operators';
import {UserDto} from '../dto/user-dto';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private _authState = new ReplaySubject<boolean>(1);

    private _userInfo = new ReplaySubject<UserDto>(1);

    private readonly userUrl = environment.hostUrl + '/user';

    constructor(private http: HttpClient) {
    }

    checkState(): Observable<UserDto> {
        return this.http.get<UserDto>(this.userUrl, {})
            .pipe(
                catchError(() => of(null)),
                tap(response => this._userInfo.next(response)),
                tap(response => this._authState.next(response ? !!response['name'] : false)),
            );
    }

    get authState(): Observable<boolean> {
        return this._authState.pipe(distinctUntilChanged());
    }

    get userInfo(): Observable<UserDto> {
        return this._userInfo;
    }

    authenticate(credentials): Observable<any> {
        const headers = new HttpHeaders({
            'Authorization': 'Basic ' + btoa(credentials.username + ':' + credentials.password)
        });

        return this.http.get<UserDto>(this.userUrl, {headers: headers})
            .pipe(
                tap(response => this._userInfo.next(response)),
                tap(response => this._authState.next(response ? !!response['name'] : false)),
            );
    }

    logout(): Observable<any> {
        return this.http.post(`${environment.hostUrl}/logout`, null)
            .pipe(finalize(() => {
                this._userInfo.next(null);
                this._authState.next(false);
            }));
    }
}
