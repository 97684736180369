import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SetSymoblPrecisionService {

  precisionChange = new Subject<any>();
  data = this.precisionChange.asObservable();
  constructor() { }

  setCurrentSymbolPrecisionAndBidAskValues(prec: number, showUpdateDialog: boolean) {
    this.data['precision'] = prec;
    this.data['showUpdateDialog'] = showUpdateDialog;

    this.precisionChange.next(this.data);
  }


}
