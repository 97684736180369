import {animate, group, query, style, transition, trigger} from '@angular/animations';

export const enterComponent = trigger('enterComponent', [
    transition(':enter', [
        style({opacity: 0}),
        animate('0.3s ease-in-out', style({opacity: 1})),
    ]),
    transition(':leave', [
        animate('0.3s ease-in-out', style({opacity: 0}))
    ])
]);

export const mainRouteAnimations =
    trigger('routeAnimations', [
        transition('Login => *', [
            query(':enter, :leave', style({position: 'fixed', width: '100%', height: '100%'}), {optional: true}),
            group([
                query(':enter', [
                    style({opacity: 0}),
                    animate('0.3s ease-in-out', style({opacity: 1}))
                ], {optional: true}),
                query(':leave', [
                    style({opacity: 1}),
                    animate('0.3s ease-in-out', style({opacity: 0}))
                ], {optional: true}),
            ])
        ]),
        transition('AppHolder  => *', [
            query(':enter, :leave', style({position: 'fixed', width: '100%', height: '100%'}), {optional: true}),
            group([
                query(':enter', [
                    style({opacity: 0}),
                    animate('0.3s ease-in-out', style({opacity: 1}))
                ], {optional: true}),
                query(':leave', [
                    style({opacity: 1}),
                    animate('0.3s ease-in-out', style({opacity: 0}))
                ], {optional: true}),
            ])
        ]),
    ]);
