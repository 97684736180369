import {Component, OnDestroy, OnInit} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import fr from '@angular/common/locales/fr';
import {Observable, Subscription, Subject, combineLatest, EMPTY} from 'rxjs';
import {filter, map, switchMap, take, debounceTime, catchError, tap} from 'rxjs/operators';
import {SelectItem} from 'primeng/api';
import {flatMap} from 'lodash';
import {LoaderService} from '../commons/loader.service';
import {AuthenticationService} from '../commons/security/authentication.service';
import {BridgesPublisherService} from '../all-bridges/services/bridges-publisher.service';
import {BridgeSimpleStatus} from '../all-bridges/interfaces/bridge-simple-status';
import {ExposureDataHttpService} from './services/exposure-data-http.service';
import {BooksTable} from './interfaces/books-table.interface';
import {ExposureRulesTab} from './interfaces/exposure-rules.interface';
import {VolumeProfit} from './interfaces/volume-profit.interface';
import {BookFilterType, BOOK_TYPES} from './helpers/book.helper';
import {ErrorManagerService} from '../commons/error/error-manager.service';
import {CallWrapperService} from '../commons/call-wrapper.service';
import {SelectedBridgeService} from '../market-depth/selected-bridge.service';
import {BridgeSettings} from '../commons/dto/user-dto';

interface ExposureParams {
  bridgeUuid: string;
  bridgeName: string;
  selectedBookType: BookFilterType;
  excludeSymbols: string[];
}

@Component({
  selector: 'app-exposure',
  templateUrl: './exposure.component.html',
  styleUrls: ['./exposure.component.scss'],
  providers: [CallWrapperService, SelectedBridgeService],
})
export class ExposureComponent implements OnInit, OnDestroy {
  isLoading = true;
  isShowUnits = false;
  showOrderBookSelector = false;

  bookTypes = BOOK_TYPES;
  bridges: SelectItem[];

  selectedBridge: SelectItem;
  selectedBookType: BookFilterType = 'BOTH';
  excludeSymbols: string[] = [];

  exposureRules: ExposureRulesTab;
  volumeProfit: VolumeProfit;
  bridgeBooksTable: BooksTable;

  private exposureParams$ = new Subject<ExposureParams>();
  private readonly debounceTimeMs = 700;

  private _sub: Subscription = new Subscription();

  constructor(
    private loadingService: LoaderService,
    private authenticationService: AuthenticationService,
    private statusPublisher: BridgesPublisherService,
    private exposureDataHttp: ExposureDataHttpService,
    private errorManager: ErrorManagerService,
    private selectedBridgeService: SelectedBridgeService
  ) {}

  public ngOnInit(): void {
    registerLocaleData(fr);

    this.statusPublisher.bridgesInfo
      .pipe(
        take(1),
        map((bridgeSimpleStatusList) =>
          bridgeSimpleStatusList.map((el) => this.mapToSelectItem(el))
        ),
        filter((list) => list.length > 0)
      )
      .subscribe((list) => {
        this.bridges = list.sort((a, b) => a.label.localeCompare(b.label));
        this.selectedBridge = this.bridges[0];
        const { label: bridgeName, value: bridgeUuid } = this.selectedBridge;
        this._sub = this.getRequestDataOnButtonClick();
        this.exposureParams$.next({
          bridgeName,
          bridgeUuid,
          selectedBookType: this.selectedBookType,
          excludeSymbols: this.excludeSymbols,
        });
      });
  }

  public onBridgeSelect({ value }: { value: SelectItem }): void {
    this.selectedBridge = value;
    this.selectedBridgeService.sendUuid(value.value);
    this.excludeSymbols = [];
  }

  public setNewSymbols({ brokerObSettings }: BridgeSettings): void {
    this.excludeSymbols = flatMap(brokerObSettings, ({ obs }) =>
      flatMap(obs, 'symbol')
    );
    this.showOrderBookSelector = false;
  }

  public getRequestDataOnButtonClick(): Subscription {
    return this.exposureParams$
      .pipe(
        debounceTime(this.debounceTimeMs),
        tap(() => this.loadingService.setLoading(true)),
        switchMap((exposureParams) =>
          this.authenticationService.authState.pipe(
            map((isLogged) => ({ isLogged, exposureParams }))
          )
        ),
        filter(({ isLogged }) => isLogged),
        switchMap(({ exposureParams }) =>
          this.getExposureData(
            exposureParams.bridgeUuid,
            exposureParams.selectedBookType,
            exposureParams.excludeSymbols
          ).pipe(
            map(([bridgeBooksTable, exposureRules, volumeProfit]) => ({
              bridgeBooksTable,
              exposureRules,
              volumeProfit,
              exposureParams,
            }))
          )
        )
      )
      .subscribe(
        ({ bridgeBooksTable, exposureRules, volumeProfit, exposureParams }) => {
          const { bridgeName } = exposureParams;
          this.isLoading = false;
          this.loadingService.setLoading(false);

          this.bridgeBooksTable = {
            ...bridgeBooksTable,
            bridgeName,
          };

          this.exposureRules = exposureRules;

          this.volumeProfit = volumeProfit;
        }
      );
  }

  public sendRequest(
    bridgeName: string,
    bridgeUuid: string,
    selectedBookType: BookFilterType,
    excludeSymbols: string[]
  ): void {
    this.exposureParams$.next({
      bridgeName,
      bridgeUuid,
      selectedBookType,
      excludeSymbols,
    });
  }

  public ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private mapToSelectItem(el: BridgeSimpleStatus): SelectItem {
    return { label: el.name, value: el.uuid };
  }

  private resetExposureData(): void {
    const { value: bridgeUuid, label: bridgeName } = this.selectedBridge;
    this.bridgeBooksTable = null;
    this.exposureRules = {
      uuid: bridgeUuid,
      bridgeName,
      rules: null,
    };
    this.volumeProfit = null;
  }

  private getExposureData(
    uuid: string,
    bookType: BookFilterType,
    excludeSymbols: string[]
  ): Observable<[BooksTable, ExposureRulesTab, VolumeProfit]> {
    return combineLatest([
      this.exposureDataHttp.getBridgeBooksTable(uuid),
      this.exposureDataHttp.getExposureRulesWithCurrent(uuid),
      this.exposureDataHttp.getVolumesAndProfits(
        uuid,
        bookType,
        excludeSymbols.join(',')
      ),
    ]).pipe(
      catchError((err) => {
        this.errorManager.handle(err, {
          onDone: () => {
            this.isLoading = false;
            this.loadingService.setLoading(false);
            this.resetExposureData();
          },
        });
        return EMPTY;
      })
    );
  }
}
