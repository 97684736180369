import {Component, OnDestroy} from '@angular/core';
import {BridgeSimpleStatusService} from '../../services/bridge-simple-status.service';
import {State} from '../../../commons/dto/state';
import {BridgeSimpleStatus, SelectedBridge} from '../../interfaces/bridge-simple-status';
import {ConfirmationService, MessageService, SelectItem} from 'primeng/api';
import {BridgeRegisterService} from '../../services/bridge-register.service';
import {AuthenticationService} from '../../../commons/security/authentication.service';
import {Router} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {LoaderService} from '../../../commons/loader.service';
import {BridgesPublisherService} from '../../services/bridges-publisher.service';
import {ErrorManagerService} from '../../../commons/error/error-manager.service';
import {NotificationsService} from '../../../commons/notifications.service';
import {filter, map, skip, switchMap, takeUntil, tap} from 'rxjs/operators';
import {UserRole} from '../../../commons/dto/user-role';
import {UserService} from '../../../users/user.service';
import {UserDto} from '../../../commons/dto/user-dto';
import {OverlayPanel} from 'primeng/primeng';
import {SimpleBridgeHelper} from '../../helpers/utils/simple-bridge.helper';
import {BridgeStatusHelper} from '../../helpers/utils/bridge-status.helper';
import {TableField} from '../../../commons/interfaces/table-field';

@Component({
    selector: 'app-all-bridge-view',
    templateUrl: './all-bridge-view.component.html',
    styleUrls: ['./all-bridge-view.component.scss']
})
export class AllBridgeViewComponent implements OnDestroy {
    public allBridgeSimpleStatusArray: BridgeSimpleStatus[];
    public isLoading = true;
    public users: SelectItem[];
    public failOverBridges: SelectItem[];
    public selectedBridgeInfo: BridgeSimpleStatus = null;
    public allBridgeSimpleStatusKeys: TableField[] = BridgeStatusHelper.ALL_BRIDGE_SIMPLE_STATUS_KEYS;
    public stateStatus: SelectItem[] = BridgeStatusHelper.STATE_STATUS;

    private isAdminLogged = false;
    private _subscription: Subscription;
    private readonly _completeSubject = new Subject<void>();

    constructor(
        private bridgeSimpleStatusService: BridgeSimpleStatusService,
        private bridgeRegisterService: BridgeRegisterService,
        private authenticationService: AuthenticationService,
        private messageService: MessageService,
        private statusPublisher: BridgesPublisherService,
        private router: Router,
        private confirmationService: ConfirmationService,
        private loadingService: LoaderService,
        private errorManger: ErrorManagerService,
        private notifications: NotificationsService,
        private userService: UserService) {
        this.initialization();
    }

    public getStatusLightColor(state: State): string {
        switch (State[state.toString()]) {
            case State.Active:
                return 'green';
            case State.Inactive:
                return 'yellow';
            case State.Error:
                return 'red';
            case State.New:
                return 'grey';
            case State.NotConfigured:
                return '#1e284c';
            case State.FailoverActive:
                return '#8aff54';
            case State.FailoverConflict:
                return '#754701';
            case State.FailoverStarted:
                return 'orange';
            default:
                return 'red';
        }
    }

    public hedgeServiceActive(): boolean {
        return !(this.selectedBridgeInfo.autoHedgeEnabled === null
            && this.selectedBridgeInfo.hedgeMonitorRefreshPeriod === null
            && this.selectedBridgeInfo.hedgeMonitorEnabled === null);
    }

    public refreshBridgeStatus(bridgeSimpleStatus: BridgeSimpleStatus): void {
        this.loadingService.setLoading(true);
        this.bridgeSimpleStatusService.refreshBridgeSimpleStatus(bridgeSimpleStatus.uuid)
            .subscribe(
                newBridgeStatus => this.handleSuccessRefresh(bridgeSimpleStatus, newBridgeStatus),
                err => this.errorManger.handle(err, {
                        httpErrorMessage: 'Bridge (uuid: ' + bridgeSimpleStatus.uuid + ') status could not be refreshed.',
                        onDone: () => this.loadingService.loading = false
                    }
                )
            );
    }

    public trackByFn(index: number, item: BridgeSimpleStatus): string {
        return item.uuid;
    }

    public openMenu(bridge: BridgeSimpleStatus, event: any, overlayPanel: OverlayPanel): void {
        if (this.isAdminLogged) {
            const selectedBridge = this.allBridgeSimpleStatusArray.find(value => value.uuid === bridge.uuid);
            this.selectedBridgeInfo = SimpleBridgeHelper.copyBridgeSimpleStatus(selectedBridge);
            overlayPanel.toggle(event);
        }
    }

    public saveSettings(): void {
        this._subscription.unsubscribe();
        this.loadingService.loading = true;
        if (this.selectedBridgeInfo.autoHedgeEnabled) {
            this.notifications.showInfoMessage('Please be informed that you have enabled option to automatically hedge all the differences between ' +
                'your liquidity account and aBook clients accounts if they occur.');
        }
        if (this.selectedBridgeInfo.hedgeMonitorEnabled != null && this.selectedBridgeInfo.hedgeMonitorEnabled === false) {
            this.selectedBridgeInfo.autoHedgeEnabled = false;
        }
        this.userService.setSettings(this.selectedBridgeInfo)
            .subscribe(() => {
                this.updateBridgesInfoList();
                this.loadingService.loading = false;
                this.notifications.showSuccessMessage('Bridge (uuid: ' + this.selectedBridgeInfo.uuid + ') settings changed');
                this.subscribeToStatusUpdates();
            }, err => this.errorManger.handle(err, {
                httpErrorMessage: 'Bridge (uuid: ' + this.selectedBridgeInfo.uuid + ') could not change settings',
                badRequestMessage: 'Bridge (uuid: ' + this.selectedBridgeInfo.failoverBridgeUuid + ') already has failOver slave. Please choose other bridge.',
                onDone: () => {
                    this.loadingService.loading = false;
                    this.subscribeToStatusUpdates();
                }
            }));
    }

    public confirmRemoval(bridgeSimpleStatus: BridgeSimpleStatus): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to remove this bridge from database?',
            header: 'Removal confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.loadingService.loading = true;
                this.removeBridgeFromDatabase(bridgeSimpleStatus);
            }

        });
    }

    public goToDetails(rowData: BridgeSimpleStatus): void {
        this.router.navigateByUrl(`/app/bridge/${rowData.uuid}`);
    }

    public unsubUpdates(): void {
        this._subscription.unsubscribe();
    }

    ngOnDestroy(): void {
        this.loadingService.forceStopLoading();
        this._subscription.unsubscribe();
        this._completeSubject.next();
        this._completeSubject.complete();
    }

    private mapToSelectItemBridge(list: BridgeSimpleStatus[]): SelectedBridge[] {
        return list
            .filter(el => !el.failoverSlave)
            .map(bridge => {
                return {
                    label: bridge.name,
                    value: bridge.uuid
                };
            });
    }

    private handleSuccessRefresh(bridge: BridgeSimpleStatus, newBridgeStatus: BridgeSimpleStatus): void {
        this.loadingService.loading = false;
        SimpleBridgeHelper.rewriteStatuses(bridge, newBridgeStatus);
        this.notifications.showSuccessMessage(`Bridge (uuid: ${bridge.uuid}) status was refreshed.`);
    }

    private removeBridgeFromDatabase(bridgeSimpleStatus: BridgeSimpleStatus): void {
        this.bridgeRegisterService.deleteBridge(bridgeSimpleStatus.uuid)
            .subscribe(
                () => {
                    const index = this.allBridgeSimpleStatusArray.findIndex(value => value.uuid === bridgeSimpleStatus.uuid);
                    this.allBridgeSimpleStatusArray.splice(index, 1);
                    this.notifications.showSuccessMessage(`Bridge (uuid: ${bridgeSimpleStatus.uuid}) was removed from database.`);
                    this.loadingService.loading = false;
                },
                err => this.errorManger.handle(err, {
                    httpErrorMessage: 'Bridge (uuid: ' + bridgeSimpleStatus.uuid + ') could not be removed from database.',
                    onDone: () => this.loadingService.loading = false
                })
            );
    }

    private extractUsersSelectItem(users: UserDto[]): SelectItem[] {
        return users
            .filter(value => value.role !== UserRole.ADMIN)
            .map(value => {
                return {
                    label: value.name,
                    value: value.name
                };
            });
    }

    private subscribeToStatusUpdates(): void {
        this._subscription = this.statusPublisher
            .bridgesInfo
            .pipe(skip(1))
            .subscribe(list => this.allBridgeSimpleStatusArray = SimpleBridgeHelper.copyBridgeSimpleStatusArray(list));
    }

    private updateBridgesInfoList(): void {
        const bridge = this.allBridgeSimpleStatusArray.find(value => value.uuid === this.selectedBridgeInfo.uuid);
        SimpleBridgeHelper.rewriteSettings(bridge, this.selectedBridgeInfo);
    }

    private initialization(): void {
        this.authenticationService.userInfo.pipe(
            filter(value => value !== null && value.role === UserRole.ADMIN),
            tap(() => this.isAdminLogged = true),
            switchMap(() => this.userService.getAll()),
            takeUntil(this._completeSubject),
        ).subscribe(users => {
            this.users = [{
                value: null,
                label: 'Not defined'
            }];
            this.users.push(...this.extractUsersSelectItem(users));
        });

        this._subscription = this.statusPublisher
            .bridgesInfo
            .subscribe(list => {
                this.allBridgeSimpleStatusArray = SimpleBridgeHelper.copyBridgeSimpleStatusArray(list);
                this.failOverBridges = this.mapToSelectItemBridge(list);
                this.failOverBridges.unshift({
                    value: null,
                    label: 'Not defined'
                });
                this.isLoading = false;
                this.loadingService.setLoading(false);
            });
    }
}
