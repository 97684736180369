import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BridgeLogsSettings} from '../commons/model/logs/bridge-logs-settings';
import {SearchLogsService} from './search-logs.service';
import {Level} from '../commons/model/logs/level';
import {SelectItem} from 'primeng/api';
import {skip, switchMap, take} from 'rxjs/operators';
import {LoaderService} from '../../commons/loader.service';
import {NotificationsService} from '../../commons/notifications.service';
import {interval, Subscription} from 'rxjs';
import {ErrorManagerService} from '../../commons/error/error-manager.service';
import {TextDecoratorService} from '../commons/text-decorator.service';

@Component({
    selector: 'app-search-logs',
    templateUrl: './search-logs.component.html',
    styleUrls: ['./search-logs.component.scss']
})
export class SearchLogsComponent implements OnInit, OnDestroy {

    static LOGS_LIMIT = 100;

    bridgeLogLevels: SelectItem[] = [
        {label: 'All', value: Level[Level.ALL]},
        {label: 'Trace', value: Level[Level.TRACE]},
        {label: 'Debug', value: Level[Level.DEBUG]},
        {label: 'Info', value: Level[Level.INFO]},
        {label: 'Warn', value: Level[Level.WARN]},
        {label: 'Error', value: Level[Level.ERROR]},
        {label: 'Fatal', value: Level[Level.FATAL]}
    ];

    today = new Date();
    searchLogsResult: string[] = [];
    searchSettings: BridgeLogsSettings;

    private _pathSub: Subscription;
    private _todayTimeSub: Subscription;

    constructor(private activatedRoute: ActivatedRoute,
                private bridgeSearchLogsService: SearchLogsService,
                private loadingService: LoaderService,
                private notifications: NotificationsService,
                private errorManager: ErrorManagerService,
                private decorator: TextDecoratorService) {
        this.setEmptyFilters();
    }

    ngOnInit(): void {
        this._todayTimeSub = interval(30000).subscribe(() => {
            this.today = new Date();
        });

        this._pathSub = this.activatedRoute.params.pipe(
            skip(1)
        ).subscribe(() => {
            this.setEmptyFilters();
            this.searchLogsResult = [];
        });
    }

    ngOnDestroy(): void {
        this._pathSub.unsubscribe();
        this._todayTimeSub.unsubscribe();
    }

    search() {
        this.loadingService.loading = true;
        this.searchLogsResult = null;
        this.activatedRoute.params.pipe(
            take(1),
            switchMap(params => this.bridgeSearchLogsService.search(params['uuid'], this.searchSettings))
        ).subscribe(
            bridgeLogs => {
                this.loadingService.loading = false;
                if (bridgeLogs) {
                    this.searchLogsResult = this.decorateLogs(bridgeLogs.logs);
                } else {
                    this.searchLogsResult = [];
                }
            }, err => this.errorManager.handle(err, {
                onDone: () => {
                    this.loadingService.loading = false;
                    this.searchLogsResult = [];
                }
            }));
    }

    private setEmptyFilters() {
        const to = new Date();
        to.setSeconds(0, 0);

        const from = new Date(Date.now() - 86400000);
        from.setSeconds(0, 0);
        this.searchSettings = {
            level: this.bridgeLogLevels[0].value.name,
            orderId: '',
            from: from,
            to: to,
            text: ''
        };
    }

    private decorateLogs(bridgeLogs: string[]): string[] {
        let logs = bridgeLogs;
        if (this.searchSettings.orderId) {
            logs = this.decorator.decorateFragment(logs, this.searchSettings.orderId, 'find-fragment');
        }
        if (this.searchSettings.text) {
            logs = this.decorator.decorateFragment(logs, this.searchSettings.text, 'find-fragment');
        }

        logs = this.decorator.decorateLogs(logs);

        if (bridgeLogs.length === SearchLogsComponent.LOGS_LIMIT) {
            logs.push(`<span class="limit-log-message">Logs count limited to ${SearchLogsComponent.LOGS_LIMIT}. Please choose a more precise data range...</span>`);
        }
        return logs;
    }
}
