export function generatePrompt(multiplier: string,
                               brokerDecimalPlaces: number,
                               lpDecimalPlaces: number,
                               separator: string = '<br>'): string {
    const decPlaces = brokerDecimalPlaces - lpDecimalPlaces;

    const value = Math.round(Number(multiplier) / Math.pow(10, decPlaces));
    if (value > 1) {
        return `Volume in Broker System: 1 ${separator} Volume in LP System: ${value}`;
    } else {
        return `Volume in Broker System: ${1 / value} ${separator} Volume in LP System: 1`;
    }
}
