import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BridgeSimpleStatus} from '../interfaces/bridge-simple-status';
import {BRIDGE_STATUS_URL} from '../../utils/constants';
import {environment} from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BridgeSimpleStatusService {
    private url = BRIDGE_STATUS_URL + '/simple-status';
    private postUrl = this.url + '/refresh';

    constructor(private http: HttpClient) {}

    public getAllBridgesSimpleStatus(): Observable<BridgeSimpleStatus[]> {
        return this.http.get<BridgeSimpleStatus[]>(this.url);
    }

    public refreshBridgeSimpleStatus(uuid: string): Observable<BridgeSimpleStatus> {
        return this.http.post<BridgeSimpleStatus>(this.postUrl, uuid);
    }
}
