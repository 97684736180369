import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {OrderBookService} from './order-book.service';
import {LoaderService} from '../commons/loader.service';
import {CallWrapperService} from '../commons/call-wrapper.service';
import {OrderBook} from './dto/order-book';
import {WSConnectionInfoService} from '../commons/wsconnection-info.service';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import {DisplayGrid, GridsterConfig, GridsterItem, GridType} from 'angular-gridster2';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {AuthenticationService} from '../commons/security/authentication.service';
import {BridgeSettings} from '../commons/dto/user-dto';
import {NotificationsService} from '../commons/notifications.service';
import {OrderBookType} from './dto/order-book-type';
import {UserSettingsService} from '../commons/user-settings.service';
import {BridgesPublisherService} from 'src/app/all-bridges/services/bridges-publisher.service';
import {SelectedBridgeService} from './selected-bridge.service';
import {BridgeSimpleStatus} from '../all-bridges/interfaces/bridge-simple-status';

interface GridItem {
    readonly position: GridsterItem;
    orderBook: OrderBook;
}

@Component({
    selector: 'app-market-watch',
    templateUrl: './market-depth.component.html',
    styleUrls: ['./market-depth.component.scss'],
    providers: [CallWrapperService, SelectedBridgeService]
})
export class MarketDepthComponent implements OnInit, OnDestroy {

    showOrderBookSelector = false;
    positionsCount = 5;
    positionCountOptions: SelectItem[] = [
        {label: '5', value: 5},
        {label: '10', value: 10}
    ];

    options: GridsterConfig = this.createOptions(this.positionsCount);
    bridges: SelectItem[] = [];
    positions: GridItem[] = [];

    private _actionType: 'delete' | 'resize' | null | 'new' = null;
    private _processingOrderBook: OrderBook | null = null;
    private _subscription: Subscription = new Subscription();
    private positionsMap: [];
    private _countChange = new BehaviorSubject<number>(this.positionsCount);

    constructor(private orderBookService: OrderBookService,
                private loader: LoaderService,
                private callWrapper: CallWrapperService,
                private authenticationService: AuthenticationService,
                private userSettingsService: UserSettingsService,
                private wsConnInfo: WSConnectionInfoService,
                private notifications: NotificationsService,
                private confirmationService: ConfirmationService,
                private statusPublisher: BridgesPublisherService,
                private selectedBridgeService: SelectedBridgeService) {
    }

    ngOnInit() {
        this.statusPublisher.bridgesInfo.pipe(
            take(1),
            map(bridgeSimpleStatusList => bridgeSimpleStatusList.map(el => this.mapToSelectItem(el))),
            filter(list => list.length > 0),
        ).subscribe(list => {
            this.bridges = list.sort((a, b) => a.label.localeCompare(b.label));
            this.selectedBridgeService.sendUuid(list[0].value);
            this.subscribeToOrders();
        });
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    positionCountsChange() {
        this._actionType = 'resize';
        this.options = this.createOptions(this.positionsCount);
        this._countChange.next(this.positionsCount);
    }

    setNewSymbols(bridgeSettings: BridgeSettings) {
        this._actionType = 'new';
        this.callWrapper.withLoader(this.userSettingsService.setBridgeSettings(bridgeSettings),
            () => {
                this.showOrderBookSelector = false;
                this.notifications.showSuccessMessage('Market watch configuration submitted');
            });
    }

    deleteBook(ob: OrderBook) {
        this.loader.loading = true;
        this._actionType = 'delete';
        this._processingOrderBook = ob;

        this.userSettingsService.getBridgeSettings(this.selectedBridgeService.uuid)
            .pipe(take(1),
                tap(symbols => this.removeSymbolFromCollection(symbols, ob)),
                switchMap(settings => this.userSettingsService.setBridgeSettings(settings)),
            ).subscribe(() => {
            this.loader.loading = false;
        });
    }

    displaySymbols4ChosenBridge(event) {
        this.selectedBridgeService.sendUuid(event.value);
    }

    private subscribeToOrders() {
        this._subscription.add(this.wsConnInfo.onConnected()
            .pipe(
                switchMap(() => combineLatest([this.selectedBridgeService.bridgeChange, this._countChange])),
                switchMap(data => this.userSettingsService.getBridgeSettings(data[0])),
                switchMap(symbols => {
                    if (this._actionType === 'delete') {
                        this.removeWidget(this._processingOrderBook);
                        this.clearActionState();
                    } else if (this._actionType === 'resize') {
                        this.clearActionState();
                    } else if (this._actionType === 'new') {
                        this.updateWidgets(symbols);
                        this.clearActionState();
                    } else {
                        this.setInitialWidgets(symbols);
                    }

                    return this.createRequest(symbols, this.selectedBridgeService.uuid);
                })
            ).subscribe(list => this.updatePositions(list)));
    }

    private createRequest(symbols, uuid) {
        return this.orderBookService.watchToOrderBooks({
            symbols: symbols,
            uuid: uuid,
            count: this.positionsCount,
            mwDepth: true
        });
    }

    private createGridItem(data: { symbol: string, sessionName: string, type: OrderBookType }, index: number): GridItem {
        return {
            orderBook: {
                bidPositions: null,
                askPositions: null,
                sessionName: data.sessionName,
                symbol: data.symbol,
                type: data.type
            }
            ,
            position: {
                cols: 1,
                rows: 1,
                x: index,
                y: 0
            }
        };
    }

    private createOptions(count: number): GridsterConfig {
        let height = 254;
        if (count === 10) {
            height = 365;
        }

        return {
            minCols: 6,
            maxCols: 6,
            gridType: GridType.Fixed,
            displayGrid: DisplayGrid.OnDragAndResize,
            compactType: 'compactUp&Left',
            pushItems: true,
            draggable: {
                enabled: true,
                ignoreContent: true,
                dragHandleClass: 'drag-handler'
            },
            resizable: {
                enabled: false
            },
            fixedRowHeight: height,
            fixedColWidth: 245,
            swap: true
        };
    }

    private updatePositions(list: OrderBook[]) {
        list.forEach(newItem => {
            const itemToUpdate = this.positionsMap[this.createKey(newItem)];
            if (itemToUpdate) {
                itemToUpdate.orderBook = newItem;
            }
        });
    }

    private createKey(newItem: OrderBook): string {
        return `${newItem.type}${newItem.sessionName}${newItem.symbol}`;
    }

    private setInitialWidgets(symbols: BridgeSettings | null) {
        this.positionsMap = [];

        let index = 0;
        if (symbols) {
            symbols.brokerObSettings.forEach((request) => {
                request.obs.forEach(ob => {
                    const item = this.addBrokerWidget(request, ob, index);
                    this.positionsMap[this.createKey(item.orderBook)] = item;
                    ++index;
                });
            });
            symbols.lpObSettings.forEach((request) => {
                request.obs.forEach(ob => {
                    const item = this.addLpWidget(request, ob, index);
                    this.positionsMap[this.createKey(item.orderBook)] = item;
                    ++index;
                });
            });
        }
        this.positions = Object.values(this.positionsMap);
    }

    private removeWidget(ob: OrderBook) {
        delete this.positionsMap[this.createKey(ob)];
        this.positions = Object.values(this.positionsMap);
    }

    private removeSymbolFromCollection(symbols: BridgeSettings, ob: OrderBook) {
        let settings = symbols.brokerObSettings;
        if (ob.type === OrderBookType.LP) {
            settings = symbols.lpObSettings;
        }

        const sessionSettings = settings.find(item => item.sessionName === ob.sessionName);

        if (sessionSettings) {
            const number = sessionSettings.obs.findIndex(value => value.symbol === ob.symbol);
            sessionSettings.obs.splice(number, 1);
        }
    }

    private mapToSelectItem(el: BridgeSimpleStatus): SelectItem {
        return {label: el.name, value: el.uuid};
    }

    private clearActionState() {
        this._actionType = null;
        this._processingOrderBook = null;
    }

    private updateWidgets(symbols: BridgeSettings | null) {
        let index = this.positions.length;
        const updatedWidgets: [] = [];

        if (symbols) {
            symbols.brokerObSettings.forEach((request) => {
                request.obs.forEach(ob => {
                    const key = this.createKeyFromArgs(OrderBookType.BROKER, request.sessionName, ob.symbol);
                    let item = this.positionsMap[key];
                    if (!item) {
                        item = this.addBrokerWidget(request, ob, index);
                        this.positionsMap[key] = item;
                        ++index;
                    }
                    updatedWidgets[key] = item;
                });
            });
            symbols.lpObSettings.forEach((request) => {
                request.obs.forEach(ob => {
                    const key = this.createKeyFromArgs(OrderBookType.LP, request.sessionName, ob.symbol);
                    let item = this.positionsMap[key];
                    if (!item) {
                        item = this.addLpWidget(request, ob, index);
                        this.positionsMap[key] = item;
                        ++index;
                    }
                    updatedWidgets[key] = item;
                });
            });

            const keys = Object.keys(this.positionsMap);
            keys.forEach(key => {
                if (!updatedWidgets[key]) {
                    delete this.positionsMap[key];
                }
            });
            this.positions = Object.values(this.positionsMap);
        }
    }

    private addLpWidget(request, ob, index): GridItem {
        return this.createGridItem({
            sessionName: request.sessionName,
            symbol: ob.symbol,
            type: OrderBookType.LP
        }, index);
    }

    private addBrokerWidget(request, ob, index): GridItem {
        return this.createGridItem({
            sessionName: request.sessionName,
            symbol: ob.symbol,
            type: OrderBookType.BROKER
        }, index);
    }

    private createKeyFromArgs(type: OrderBookType, sessionName: string, symbol: string): string {
        return `${type}${sessionName}${symbol}`;
    }
}
