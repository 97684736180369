import {SelectItem} from 'primeng/api';
import {TableField} from '../../../commons/interfaces/table-field';

export class BridgeStatusHelper {
    public static ALL_BRIDGE_SIMPLE_STATUS_KEYS: TableField[] = [
        {field: 'name', header: 'Name'},
        {field: 'hostIp', header: 'Host'},
        {field: 'aliveState', header: 'Alive'},
        {field: 'lpSessionState', header: 'Lp'},
        {field: 'brokerFeederSessionState', header: 'Prices'},
        {field: 'brokerBrokerSessionState', header: 'Trades'}
    ];

    public static STATE_STATUS: SelectItem[] = [
        {label: 'All', value: null},
        {label: 'Active', value: 'Active'},
        {label: 'Error', value: 'Error'},
        {label: 'Inactive', value: 'Inactive'},
        {label: 'Not Configured', value: 'NotConfigured'},
        {label: 'New', value: 'New'}
    ];
}
