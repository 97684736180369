import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ChartDateRange, DateRange} from '../../interfaces/date-range';
import {ChartData, DateSnack} from '../../interfaces';

@Component({
  selector: 'app-total-summary',
  templateUrl: './total-summary.component.html',
  styleUrls: ['./total-summary.component.scss']
})
export class TotalSummaryComponent implements OnChanges {
  @Input() dateLabels: DateSnack[];
  @Input() data?: ChartData[];

  @Output() changeDateClick = new EventEmitter<ChartDateRange>();

  public selectedLabel: string | null = null;

  ngOnChanges({dateLabels}: SimpleChanges): void {
    if (dateLabels && dateLabels.currentValue) {
      this.selectedLabel = dateLabels.currentValue[0].value;
    }
  }

  public onSelectedDateChange(selectedDate: string): void {
    this.selectedLabel = selectedDate;
    this.changeDateClick.emit({
      date: {
        from: selectedDate,
        to: this.dateLabels[0].value
      }
    });
  }

  public onSetDateRangeClick(dateRange: DateRange): void {
    this.selectedLabel = null;
    this.changeDateClick.emit({
      date: {
        from: dateRange.from,
        to: dateRange.to
      }
    });
  }
}
