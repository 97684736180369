import {BridgeSimpleStatus} from '../../interfaces/bridge-simple-status';

export class SimpleBridgeHelper {
    static rewriteStatuses(old: BridgeSimpleStatus, newState: BridgeSimpleStatus) {
        old.aliveState = newState.aliveState;
        old.lpSessionState = newState.lpSessionState;
        old.brokerFeederSessionState = newState.brokerFeederSessionState;
        old.brokerBrokerSessionState = newState.brokerBrokerSessionState;
        return old;
    }

    static rewriteSettings(old: BridgeSimpleStatus, newState: BridgeSimpleStatus) {
        old.owner = newState.owner;
        old.hedgeMonitorEnabled = newState.hedgeMonitorEnabled;
        old.hedgeMonitorRefreshPeriod = newState.hedgeMonitorRefreshPeriod;
        old.failoverBridgeUuid = newState.failoverBridgeUuid;
        old.failoverTime = newState.failoverTime;
        old.autoHedgeEnabled = newState.autoHedgeEnabled;
        old.rabbitPersistenceEnabled = newState.rabbitPersistenceEnabled;
        old.sendEmailToAdmin = newState.sendEmailToAdmin;
        old.sendEmailToUser = newState.sendEmailToUser;
        return old;
    }

    static copyBridgeSimpleStatus(old: BridgeSimpleStatus): BridgeSimpleStatus {
        return {
            ...old,
            hedgeMonitorRefreshPeriod: old.hedgeMonitorRefreshPeriod != null ? old.hedgeMonitorRefreshPeriod : 300
        };
    }

    static copyBridgeSimpleStatusArray(old: BridgeSimpleStatus[]): BridgeSimpleStatus[] {
        return old.map(value => SimpleBridgeHelper.copyBridgeSimpleStatus(value));
    }
}
