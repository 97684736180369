import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NewUserModel} from '../new-user-model';

@Component({
    selector: 'app-new-user',
    templateUrl: './new-user.component.html',
    styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

    @Input()
    model: NewUserModel;

    @Output()
    submit = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
    }

}
