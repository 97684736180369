import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationsService} from '../../commons/notifications.service';
import {ConfirmationService} from 'primeng/api';
import {delay, map, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ABookRule, emptyABookRule} from './model/abook-rule';
import {ABookRuleService} from './a-book-rule.service';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {SymbolsChangeService} from '../services/symbols-change.service';
import {ActivatedRoute, NavigationStart, Router, RouterEvent} from '@angular/router';
import {ABookRuleDisplay} from './model/abook-rule-display';
import {ABookRules} from './model/abook-rules';
import {RulesRowReorder} from '../commons/abstract/rules-row-reorder.abstract';

@Component({
    selector: 'app-abook-rules',
    templateUrl: './abook-rules.component.html',
    styleUrls: ['./abook-rules.component.scss'],
    providers: [CallWrapperService]
})
export class ABookRulesComponent extends RulesRowReorder<ABookRules, ABookRule, ABookRuleDisplay> implements  OnInit, OnDestroy {

    rules: ABookRuleDisplay[];
    isLoading = true;
    selectedRule: ABookRule;
    newRule: ABookRule;
    displaySymbolChangeNotification = false;

    private _subscription: Subscription;
    private routerSub: Subscription;
    private activateRouteSub: Subscription;

    constructor(protected ruleService: ABookRuleService,
                private notifications: NotificationsService,
                private confirmationService: ConfirmationService,
                protected callWrapper: CallWrapperService,
                private symbolsChange: SymbolsChangeService,
                private activatedRoute: ActivatedRoute,
                private router: Router) {
        super(callWrapper, ruleService);
    }

    ngOnInit() {
        this.routerSub = this.router
            .events
            .subscribe((routerEvent: RouterEvent) => this.checkEvent(routerEvent));

        this._subscription = this.callWrapper.withStableUuid(
            uuid => this.ruleService.getRules(uuid).pipe(map(value => value.rules)),
            list => {
                this.isLoading = false;
                this.rules = this.mapToDisplay(list);

                this.applyAndUpdateAddedRule();
            });

        this._subscription = this.symbolsChange
            .tabChanged.subscribe(data => {
                if (data['tabChanged']) {
                    this.symbolsChange.setShowDialog(this.displaySymbolChangeNotification, 'abook');
                    this.displaySymbolChangeNotification = false;
                }
            });
    }

    ngOnDestroy(): void {
        this.routerSub.unsubscribe();
        this._subscription.unsubscribe();
        this.symbolsChange.setShowDialog(this.displaySymbolChangeNotification, 'abook');
        this.activateRouteSub.unsubscribe();
        this.router.navigate(
            ['.'],
            {relativeTo: this.activatedRoute, queryParams: {abookTab: 'false'}}
        );
    }

    checkEvent(routerEvent: RouterEvent): any {
        if (routerEvent instanceof NavigationStart) {
            this.symbolsChange.setShowDialog(this.displaySymbolChangeNotification, 'abook');
            this.displaySymbolChangeNotification = false;
        }
    }

    showNewRuleDialog() {
        this.newRule = emptyABookRule();
    }

    applyChanges() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.applyChanges(uuid).pipe(delay(500), tap(() => this.displaySymbolChangeNotification = false)),
            () => this.notifications.showSuccessMessage('Changes loaded!')
        );

    }

    refreshRules() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.refreshRules(uuid),
            rules => {
                this.rules = this.mapToDisplay(rules.rules);
                this.notifications.showSuccessMessage('Rules refreshed!');
            });
    }

    updateRule(rule: ABookRule) {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.update(uuid, rule),
            () => {
                this.updateSymbols();
                this.closeUpdateRuleDialog();
                this.displaySymbolChangeNotification = true;
                this.notifications.showSuccessMessage('Rule updated.', 'Please remember to apply the changes in the bridge!');
            });
    }

    deleteRule(rule: ABookRule) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want remove this rule?',
            accept: () => {
                this.callWrapper.withLoaderAndSingleUuid(
                    uuid => this.ruleService.delete(uuid, rule.uuid),
                    () => {
                        this.updateSymbols();
                        this.closeUpdateRuleDialog();
                        this.displaySymbolChangeNotification = true;
                        this.notifications.showSuccessMessage('Rule deleted.', 'Please remember to apply the changes in the bridge!');
                    }
                );
            }
        });
    }

    addNewRule(newRule: ABookRule) {
        this.callWrapper.withLoaderAndSingleUuid(uuid => this.ruleService.create(uuid, {
            ...newRule,
            weight: Math.max(...this.rules.map(o => o.weight)) + 1
        }), () => {
                this.updateSymbols();
                this.closeNewRuleDialog();
                this.displaySymbolChangeNotification = true;
                this.notifications.showSuccessMessage('New rule created.', 'Please remember to apply the changes in the bridge!');
            });
    }

    closeUpdateRuleDialog() {
        this.selectedRule = null;
    }

    closeNewRuleDialog() {
        this.newRule = null;
    }

    applyAndUpdateAddedRule() {
        this.activateRouteSub = this.activatedRoute.queryParams.subscribe(data => {
            if (data.abookTab !== 'true') {
                return;
            }
            const newHedgedRule = this.rules.find((rule) => rule.weight === -1);
            if (newHedgedRule) {
                this.updateRule({
                    ...newHedgedRule,
                    brokerName: newHedgedRule.brokerName.join(','),
                    lpName: newHedgedRule.lpName.join(','),
                    weight: Math.max(...this.rules.map((o) => o.weight)) + 1,
                });
                this.rules = this.updateOnReorder(this.rules);
            }
            this.applyChanges();
        });
    }

    private updateSymbols() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.getRules(uuid),
            results => this.rules = this.mapToDisplay(results.rules)
        );
    }

    private mapToDisplay(abookRules: ABookRule[]) {
        if (!abookRules) {
            return null;
        }

        return abookRules.map(rule => {
            return {
                uuid: rule.uuid,
                brokerName: rule.brokerName.split(','),
                instrument: rule.instrument,
                groupName: rule.groupName,
                account: rule.account,
                lpName: rule.lpName.split(','),
                weight: rule.weight
            };
        });
    }
}
