import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {LPSymbolsService} from '../../details-bridge/commons/lp-symbols.service';
import {BrokerSymbolsService} from '../../details-bridge/commons/broker-symbols.service';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {combineLatest, Subscription} from 'rxjs';
import {LPSession} from '../../details-bridge/commons/model/symbol/lp/l-p-session';
import {BrokerSession} from '../../details-bridge/commons/model/symbol/broker/broker-session';
import {BrokerSymbol} from '../../details-bridge/commons/model/symbol/broker/broker-symbol';
import {BridgeSettings, buildOrderBookSettings, SessionSettings} from '../../commons/dto/user-dto';
import {LPSymbol} from '../../details-bridge/commons/model/symbol/lp/lp-symbol';
import {environment} from '../../../environments/environment';
import {NotificationsService} from '../../commons/notifications.service';
import {UserSettingsService} from '../../commons/user-settings.service';
import {SelectedBridgeService} from '../selected-bridge.service';

@Component({
    selector: 'app-order-book-selector',
    templateUrl: './order-book-selector.component.html',
    styleUrls: ['./order-book-selector.component.scss']
})
export class OrderBookSelectorComponent implements OnInit, OnDestroy {
    @Input() isExposureTab: boolean;
    @Output()
    submit = new EventEmitter<BridgeSettings>();

    lpSessions: LPSession[];
    selectedLpSessions: { [key: string]: LPSymbol[]; };

    brokerSessions: BrokerSession[];
    selectedBrokerSessions: { [key: string]: BrokerSymbol[]; };

    uuid = '';
    settings: BridgeSettings;
    private _subscription: Subscription;

    constructor(private authService: AuthenticationService,
                private lpSymbolService: LPSymbolsService,
                private brokerSymbolService: BrokerSymbolsService,
                private callWrapper: CallWrapperService,
                private userSettings: UserSettingsService,
                private notifications: NotificationsService,
                private selectedBridgeService: SelectedBridgeService) {
    }

    ngOnInit() {
        this.initSymbols();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    saveChanges() {
        const lpSessionSettings: SessionSettings[] = Object.entries(this.selectedLpSessions)
            .map(v => {
                return {
                    sessionName: v[0],
                    obs: v[1].map(value => buildOrderBookSettings(value.symbolName))
                };
            });

        const brokerSessionSettings: SessionSettings[] = Object.entries(this.selectedBrokerSessions).map(v => {
            return {
                sessionName: v[0],
                obs: v[1].map(value => buildOrderBookSettings(value.symbolName))
            };
        });

        const settings: BridgeSettings = {
            uuid: this.uuid,
            lpObSettings: lpSessionSettings,
            brokerObSettings: brokerSessionSettings,
            lpNewMwSettings: this.settings ? this.settings.lpNewMwSettings : [],
            brokerNewMwSettings: this.settings ? this.settings.brokerNewMwSettings : [],
        };

        this.submit.emit(settings);
    }

    initSymbols() {
        this._subscription = this.selectedBridgeService.bridgeChange.subscribe(uuid => {
            this.uuid = uuid;
            combineLatest([this.brokerSymbolService.getSessions(this.uuid),
                this.lpSymbolService.getSessions(this.uuid),
                this.userSettings.getBridgeSettings(this.uuid)])
                .subscribe(data => {
                    this.brokerSessions = data[0];
                    this.lpSessions = data[1];
                    this.settings = data[2];

                    if (this.settings && !this.isExposureTab) {
                        this.setSelectedItems(this.settings);
                    } else {
                        this.setSessions();
                    }
                });
        });
    }

    private setSelectedItems(settings: BridgeSettings) {

        this.selectedLpSessions = {};
        settings.lpObSettings.forEach(lpSession => {
            const obs = new Set<string>(lpSession.obs.map(ob => ob.symbol));
            const lpSymbols = this.lpSessions.find(lp => lp.sessionName === lpSession.sessionName);
            if (lpSymbols) {
                this.selectedLpSessions[lpSession.sessionName] = lpSymbols.symbols.filter(symbol => obs.has(symbol.symbolName));
            }
        });

        this.selectedBrokerSessions = {};
        settings.brokerObSettings.forEach(brokerSession => {
            const obs = new Set<string>(brokerSession.obs.map(ob => ob.symbol));
            const brokerSymbols = this.brokerSessions.find(lp => lp.sessionName === brokerSession.sessionName);
            if (brokerSymbols) {
                this.selectedBrokerSessions[brokerSession.sessionName] = brokerSymbols.symbols.filter(symbol => obs.has(symbol.symbolName));
            }
        });
    }

    private setSessions() {
        this.selectedLpSessions = {};
        this.lpSessions.forEach(session => {
            this.selectedLpSessions[session.sessionName] = [];
        });

        this.selectedBrokerSessions = {};
        this.brokerSessions.forEach(session => {
            this.selectedBrokerSessions[session.sessionName] = [];
        });
    }

    selectItem(event) {
        const selected = (event.value as []);
        if (selected.length > environment.orderBookLimit) {
            selected.splice(selected.length - 1, 1);
            this.notifications.showWarningMessage('Limit', `Limit of displayed items has been reached. Maximum elements per session is ${environment.orderBookLimit}`);
        }
    }
}
