import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BridgeDetailedStatus} from './model/status/bridge-detailed-status';
import {tap} from 'rxjs/operators';
import {BRIDGE_STATUS_URL} from '../../utils/constants';

@Injectable({
    providedIn: 'root'
})
export class BridgeDetailedStatusService {

    allBridgeDetailedStatusMapSubject = new BehaviorSubject(new Map());

    private url = BRIDGE_STATUS_URL + '/detailed-status';
    private postUrl = this.url + '/refresh';

    constructor(private http: HttpClient) {
    }

    updateBridgeDetailedStatus() {
        this.getBridgeDetailedStatus()
            .subscribe((data: Map<string, BridgeDetailedStatus>) => {
                    this.allBridgeDetailedStatusMapSubject.next(data);
                }
            );
    }

    refreshBridgeDetailedStatus(uuid: string): Observable<BridgeDetailedStatus> {
        return this.http.post<BridgeDetailedStatus>(this.postUrl, uuid)
            .pipe(
                tap((data) => {
                        const localMap = this.allBridgeDetailedStatusMapSubject.getValue();
                        localMap[uuid] = data;
                        this.allBridgeDetailedStatusMapSubject.next(localMap);
                    }
                )
            );
    }

    private getBridgeDetailedStatus(): Observable<Map<string, BridgeDetailedStatus>> {
        return this.http.get<Map<string, BridgeDetailedStatus>>(this.url);
    }

    getBridgeDetails(uuid: string) {
        return this.http.get<BridgeDetailedStatus>(`${this.url}/${uuid}`);
    }
}
