export interface ManualOverrideData {
  bridgeUUID: string;
  ruleId: number;
  overrideType: OverrideType;
}

export enum OverrideType {
  ACTIVATION = 'ACTIVATION',
  DEACTIVATION = 'DEACTIVATION',
}
