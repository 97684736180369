import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {ErrorHandler, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APP_ROUTES_INIT, AppRoutingModule} from './app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faCircle,
    faEdit,
    faFileDownload,
    faFileUpload,
    faHome,
    faPlus,
    faPowerOff,
    faSync,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import {TableModule} from 'primeng/table';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {ToastModule} from 'primeng/toast';
import {CalendarModule} from 'primeng/calendar';
import {
    CardModule, ChartModule,
    DataListModule,
    FileUploadModule,
    InputSwitchModule,
    InputTextModule,
    KeyFilterModule,
    ListboxModule,
    MessageModule, OverlayPanelModule,
    ProgressBarModule,
    RadioButtonModule, SpinnerModule,
    TriStateCheckboxModule,
} from 'primeng/primeng';
import {PanelModule} from 'primeng/panel';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService} from 'primeng/api';
import {DataViewModule} from 'primeng/dataview';
import {TabViewModule} from 'primeng/tabview';
import {DropdownModule} from 'primeng/dropdown';

import {DashboardComponent} from './all-bridges/components/dashboard/dashboard.component';
import {SideMenuComponent} from './app-holder/side-menu/side-menu.component';
import {AllBridgeViewComponent} from './all-bridges/components/all-bridge-view/all-bridge-view.component';
import {BridgeDetailsComponent} from './details-bridge/bridge-details.component';
import {BridgeInfoComponent} from './details-bridge/bridge-status/bridge-info/bridge-info.component';
import {LpSessionStatusComponent} from './details-bridge/bridge-status/lp-session-status/lp-session-status.component';
import {BrokerSessionStatusComponent} from './details-bridge/bridge-status/broker-session-status/broker-session-status.component';
import {CurrentLogsComponent} from './details-bridge/current-logs/current-logs.component';
import {BridgeStatusComponent} from './details-bridge/bridge-status/bridge-status.component';
import {BrokerSessionSymbolsComponent} from './details-bridge/broker-symbols/broker-session-symbols/broker-session-symbols.component';
import {LoginComponent} from './login/login.component';
import {NavbarComponent} from './app-holder/navbar/navbar.component';
import {DialogModule} from 'primeng/dialog';
import {XhrInterceptor} from './commons/security/xhr-interceptor.interceptor';
import {SearchLogsComponent} from './details-bridge/search-logs/search-logs.component';
import {LpSymbolsComponent} from './details-bridge/lp-symbols/lp-symbols.component';
import {BrokerSymbolsComponent} from './details-bridge/broker-symbols/broker-symbols.component';
import {LpSessionSymbolsComponent} from './details-bridge/lp-symbols/lp-session-symbols/lp-session-symbols.component';
import {MinValidatorDirective} from './commons/directives/min-validator.directive';
import {MaxValidatorDirective} from './commons/directives/max-validator.directive';
import {AppHolderComponent} from './app-holder/app-holder/app-holder.component';
import {ErrorHandlerImpl} from './commons/error/error-handler-impl.service';
import {SetupComponent} from './setup/view/setup.component';
import {EqualValidator} from './commons/directives/equal-validator';
import {NewBrokerSymbolComponent} from './details-bridge/broker-symbols/broker-session-symbols/new-broker-symbol/new-broker-symbol.component';
import {UpdateBrokerSymbolComponent} from './details-bridge/broker-symbols/broker-session-symbols/update-broker-symbol/update-broker-symbol.component';
import {NewLpSymbolComponent} from './details-bridge/lp-symbols/lp-session-symbols/new-lp-symbol/new-lp-symbol.component';
import {UpdateLpSessionComponent} from './details-bridge/lp-symbols/lp-session-symbols/update-lp-session/update-lp-session.component';
import {UsersComponent} from './users/users.component';
import {NewUserComponent} from './users/new-user/new-user.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {ABookRulesComponent} from './details-bridge/abook-rules/abook-rules.component';
import {ABookRuleComponent} from './details-bridge/abook-rules/abook-rule/a-book-rule.component';
import {ReportsComponent} from './reports/reports.component';
import {CoverageRuleComponent} from './details-bridge/coverage-rules/coverage-rule/coverage-rule.component';
import {CoverageRulesComponent} from './details-bridge/coverage-rules/coverage-rules.component';
import {InjectableRxStompConfig, RxStompService, rxStompServiceFactory} from '@stomp/ng2-stompjs';
import {MarketDepthComponent} from './market-depth/market-depth.component';
import {OrderBookComponent} from './market-depth/order-book/order-book.component';
import {OrderBookPartComponent} from './market-depth/order-book/order-book-part/order-book-part.component';
import {GridsterModule} from 'angular-gridster2';
import {OrderBookSelectorComponent} from './market-depth/order-book-selector/order-book-selector.component';
import {ChangeUserPasswordComponent} from './change-user-password/change-user-password.component';
import {MinEqualValidatorDirective} from './commons/directives/min-equal-validator.directive';
import {AddSuffixPipe} from './pipes/add-suffix.pipe';
import {MultiSelectModule} from 'primeng/multiselect';
import {BridgeStatisticsComponent} from './bridge-statistics/bridge-statistics.component';
import {RemoveZeroPipe} from './pipes/remove-zero.pipe';
import {BlankValidatorDirective} from './commons/directives/blank-validator.directive';
import {HedgeMonitorComponent} from './hedge-monitor/hedge-monitor.component';
import {TreeTableModule} from 'primeng/primeng';
import {RowHighlightDirective} from './commons/directives/row-highlight.directive';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {BmLogsComponent} from './bm-logs/bm-logs.component';
import {PriceImprovementRulesComponent} from './details-bridge/price-improvement-rules/price-improvement-rules.component';
import {NewPriceImprovementRuleComponent} from './details-bridge/price-improvement-rules/new-price-improvement-rule/new-price-improvement-rule.component';
import {MlHighlightDirective} from './commons/directives/ml-highlight.directive';
import { GuaranteedRulesComponent } from './details-bridge/guaranteed-rules/guaranteed-rules.component';
import { NewGuaranteedRuleComponent } from './details-bridge/guaranteed-rules/new-guaranteed-rule/new-guaranteed-rule.component';
import { MarketWatchComponent } from './market-watch/market-watch.component';
import { FilterIntervalComponent } from './market-watch/filter-interval/filter-interval.component';
import { LastUpdateRowHighlightDirective } from './commons/directives/last-update-row-highlight.directive';
import { ConvertSecondsToTimePatternPipe } from './pipes/convert-seconds-to-time-pattern.pipe';
import { MwInstrumentSelectorComponent } from './market-watch/mw-instrument-selector/mw-instrument-selector.component';
import { SpreadPipe } from './market-depth/pipe/spread.pipe';
import { BridgeManagerLogsComponent } from './bm-logs/bridge-manager-logs/bridge-manager-logs.component';
import { HedgeLogsComponent } from './details-bridge/hedge-logs/hedge-logs.component';
import { AutoHedgeLogsComponent } from './details-bridge/auto-hedge-logs/auto-hedge-logs.component';
import { DelayExecutionRulesComponent } from './details-bridge/delay-execution-rules/delay-execution-rules.component';
import { NewDelayExecutionRuleComponent } from './details-bridge/delay-execution-rules/new-delay-execution-rule/new-delay-execution-rule.component';
import { DetailedHedgingComponent } from './reports/detailed-hedging/detailed-hedging.component';
import { SingleTradesComponent } from './reports/single-trades/single-trades.component';
import { ClosedTradesComponent } from './reports/closed-trades/closed-trades.component';
import { TotalSummaryComponent } from './bridge-statistics/components/total-summary/total-summary.component';
import { DateBlockComponent } from './bridge-statistics/components/date-block/date-block.component';
import { ChartsComponent } from './bridge-statistics/components/charts/charts.component';
import { TotalBlockComponent } from './bridge-statistics/components/total-block/total-block.component';
import { OpenPositionComponent } from './bridge-statistics/components/open-position/open-position.component';
import { DoughnutChartComponent } from './bridge-statistics/components/doughnut-chart/doughnut-chart.component';
import { ExpositionRulesComponent } from './details-bridge/exposition-rules/exposition-rules.component';
import { ExpositionRulesDialogComponent } from './details-bridge/exposition-rules/components/exposition-rules-dialog/exposition-rules-dialog.component';
import { RulesSchedulerComponent } from './details-bridge/rules-scheduler/rules-scheduler.component';
import { RulesSchedulerDialogComponent} from './details-bridge/rules-scheduler/components/rules-scheduler-dialog/rules-scheduler-dialog.component';
import { ClippedTableColumnComponent } from './commons/components/clipped-table-column/clipped-table-column.component';
import { BookProfitHighlightDirective } from './commons/directives/book-profit-highlight.directive';
import { PrepareDatePipe } from './details-bridge/rules-scheduler/pipes/prepare-date.pipe';
import { CalculatorComponent } from './details-bridge/lp-symbols/lp-session-symbols/calculator/calculator.component';
import { ExposureComponent } from './exposure/exposure.component';
import { VolumeProfitChartsComponent } from './exposure/components/volume-profit-charts/volume-profit-charts.component';
import { HedgeABookDialogComponent } from './exposure/components/hedge-a-book-dialog/hedge-a-book-dialog.component';
import { BooksTablesComponent } from './exposure/components/books-tables/books-tables.component';
import { ExposureRulesTableComponent } from './exposure/components/exposure-rules-table/exposure-rules-table.component';
import { TicksMonitoringDialogComponent } from './details-bridge/broker-symbols/broker-session-symbols/ticks-monitoring-dialog/ticks-monitoring-dialog.component';
import { TicksHistoryComponent } from './reports/ticks-history/ticks-history.component';

library.add(faPowerOff, faHome, faCircle, faSync, faTrashAlt, faPlus, faFileUpload, faFileDownload, faEdit);

export const stompConfig: InjectableRxStompConfig = {
    brokerURL:  `${location.protocol === 'https:' ? 'wss://' : 'ws://'}${location.host}${environment.wsPath}`,
    connectHeaders: {
        login: 'guest',
        passcode: 'guest'
    },
    heartbeatIncoming: 0,
    heartbeatOutgoing: 20000,
    reconnectDelay: 200,
    debug: (msg: string): void => {
        console.log(new Date(), msg);
    }
};

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        SideMenuComponent,
        AllBridgeViewComponent,
        BridgeDetailsComponent,
        BridgeInfoComponent,
        LpSessionStatusComponent,
        BrokerSessionStatusComponent,
        LoginComponent,
        NavbarComponent,
        BridgeStatusComponent,
        BrokerSessionSymbolsComponent,
        CurrentLogsComponent,
        SearchLogsComponent,
        LpSymbolsComponent,
        BrokerSymbolsComponent,
        LpSessionSymbolsComponent,
        MinValidatorDirective,
        MaxValidatorDirective,
        MinEqualValidatorDirective,
        AppHolderComponent,
        SetupComponent,
        EqualValidator,
        BlankValidatorDirective,
        NewBrokerSymbolComponent,
        UpdateBrokerSymbolComponent,
        TicksMonitoringDialogComponent,
        NewLpSymbolComponent,
        UpdateLpSessionComponent,
        UsersComponent,
        NewUserComponent,
        ABookRulesComponent,
        ABookRuleComponent,
        ReportsComponent,
        DetailedHedgingComponent,
        SingleTradesComponent,
        ClosedTradesComponent,
        TicksHistoryComponent,
        CoverageRuleComponent,
        CoverageRulesComponent,
        MarketDepthComponent,
        OrderBookComponent,
        OrderBookPartComponent,
        OrderBookSelectorComponent,
        ChangeUserPasswordComponent,
        AddSuffixPipe,
        BridgeStatisticsComponent,
        RemoveZeroPipe,
        HedgeMonitorComponent,
        RowHighlightDirective,
        AdminDashboardComponent,
        BmLogsComponent,
        PriceImprovementRulesComponent,
        NewPriceImprovementRuleComponent,
        MlHighlightDirective,
        BookProfitHighlightDirective,
        GuaranteedRulesComponent,
        NewGuaranteedRuleComponent,
        MarketWatchComponent,
        FilterIntervalComponent,
        LastUpdateRowHighlightDirective,
        ConvertSecondsToTimePatternPipe,
        MwInstrumentSelectorComponent,
        SpreadPipe,
        BridgeManagerLogsComponent,
        HedgeLogsComponent,
        AutoHedgeLogsComponent,
        DelayExecutionRulesComponent,
        NewDelayExecutionRuleComponent,
        TotalSummaryComponent,
        DateBlockComponent,
        ChartsComponent,
        TotalBlockComponent,
        OpenPositionComponent,
        DoughnutChartComponent,
        ExpositionRulesComponent,
        ExpositionRulesDialogComponent,
        RulesSchedulerComponent,
        RulesSchedulerDialogComponent,
        ClippedTableColumnComponent,
        PrepareDatePipe,
        CalculatorComponent,
        ExposureComponent,
        VolumeProfitChartsComponent,
        HedgeABookDialogComponent,
        BooksTablesComponent,
        ExposureRulesTableComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        AppRoutingModule,
        RouterModule.forRoot(APP_ROUTES_INIT, {onSameUrlNavigation: 'reload'}),
        FontAwesomeModule,
        BrowserAnimationsModule,
        TableModule,
        PanelMenuModule,
        ButtonModule,
        TooltipModule,
        ToastModule,
        DataListModule,
        PanelModule,
        ConfirmDialogModule,
        FormsModule,
        ReactiveFormsModule,
        InputTextModule,
        CardModule,
        DataViewModule,
        TabViewModule,
        RadioButtonModule,
        DropdownModule,
        CalendarModule,
        DialogModule,
        MessageModule,
        ProgressBarModule,
        TriStateCheckboxModule,
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        GridsterModule,
        ListboxModule,
        MultiSelectModule,
        TreeTableModule,
        OverlayPanelModule,
        InputSwitchModule,
        SpinnerModule,
        ProgressSpinnerModule,
        FileUploadModule,
        KeyFilterModule,
        ChartModule
    ],
    providers: [
        MessageService,
        ConfirmationService,
        {provide: HTTP_INTERCEPTORS, useClass: XhrInterceptor, multi: true},
        {provide: ErrorHandler, useClass: ErrorHandlerImpl},
        {provide: InjectableRxStompConfig, useValue: stompConfig},
        {provide: RxStompService, useFactory: rxStompServiceFactory, deps: [InjectableRxStompConfig]}
    ],
    bootstrap: [AppComponent],
    entryComponents: [BridgeDetailsComponent]
})
export class AppModule {
}
