import {Attribute, Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';
import {BMValidators} from '../b-m-validators';

@Directive({
    selector: '[appBlankValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => BlankValidatorDirective), multi: true}
    ]
})
export class BlankValidatorDirective implements Validator {
    constructor(@Attribute('appBlankValidator') public validateMax: string) {
    }

    validate(input: AbstractControl): { [key: string]: any } {
        return BMValidators.blank(input);
    }
}
