import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BrokerSymbol} from '../../../commons/model/symbol/broker/broker-symbol';
import {BrokerSessionType} from '../../../commons/model/status/broker-session-type';

@Component({
    selector: 'app-new-broker-symbol',
    templateUrl: './new-broker-symbol.component.html',
    styleUrls: ['./new-broker-symbol.component.scss']
})
export class NewBrokerSymbolComponent {
    @Input() symbol: BrokerSymbol;
    @Input() isFOKLimitHedgingEnabled: boolean;
    @Input() brokerSessionType: BrokerSessionType;

    @Output() submit = new EventEmitter<void>();

    public brokerSessionTypeEnum = BrokerSessionType;

    constructor() { }

}
