export interface DelayExecutionRule {
    readonly uuid: string;
    readonly brokerName: string;
    readonly instrument: string;
    readonly groupName: string;
    readonly account: string;
    readonly delayFrom: number;
    readonly delayTo: number;
    readonly weight: number;
}

export function emptyDelayExecRule(): DelayExecutionRule {
    return {
        uuid: null,
        brokerName: null,
        instrument: null,
        groupName: null,
        account: null,
        delayFrom: 0,
        delayTo: 0,
        weight: null
    };
}
