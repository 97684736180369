import {Component, OnDestroy, OnInit} from '@angular/core';
import {CurrentLogsService} from './current-logs.service';
import {BridgeLogLevel} from '../commons/model/logs/bridge-log-level';
import {combineLatest, Observable, of, Subscription, timer} from 'rxjs';
import {LoaderService} from '../../commons/loader.service';
import {NotificationsService} from '../../commons/notifications.service';
import {environment} from '../../../environments/environment';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {ActivatedRoute} from '@angular/router';
import {ErrorManagerService} from '../../commons/error/error-manager.service';
import {TextDecoratorService} from '../commons/text-decorator.service';
import {CallWrapperService} from '../../commons/call-wrapper.service';

@Component({
    selector: 'app-current-logs',
    templateUrl: './current-logs.component.html',
    styleUrls: ['./current-logs.component.scss'],
    providers: [CallWrapperService]
})
export class CurrentLogsComponent implements OnInit, OnDestroy {

    FULL_FIX_TRACE = 'FULL_FIX_TRACE';
    DEBUG = 'DEBUG';

    BridgeLogLevel: typeof BridgeLogLevel = BridgeLogLevel;
    bridgeLogs: string[] = null;
    bridgeLogLevel: string;

    private _subscription: Subscription;
    private _logLevelSub: Subscription;

    constructor(private loader: LoaderService,
                private notification: NotificationsService,
                private activeRoute: ActivatedRoute,
                private bridgeLogsService: CurrentLogsService,
                private authService: AuthenticationService,
                private errorManager: ErrorManagerService,
                private callWrapper: CallWrapperService,
                private decorator: TextDecoratorService) {
    }

    ngOnInit(): void {
        this._subscription = this.createLoop().pipe(
            switchMap(uuid => this.bridgeLogsService.getCurrentLogs(uuid).pipe(
                map(data => data.logs),
                catchError(err => {
                    this.errorManager.handle(err);
                    return of([]);
                }))),
        ).subscribe(bridgeLogs => this.setLogs(bridgeLogs));

        this._logLevelSub = this.createLoop().pipe(
            switchMap(uuid => this.bridgeLogsService.getBridgeLogLevel(uuid)),
            catchError(() => of(null))
        ).subscribe(level => this.bridgeLogLevel = level);

    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
        this._logLevelSub.unsubscribe();
    }

    logLevelChanged(bridgeLogLevel: BridgeLogLevel) {
        if (bridgeLogLevel === BridgeLogLevel.TRACE || bridgeLogLevel === BridgeLogLevel.FULL_FIX_TRACE) {
            this.changeLogLevel(bridgeLogLevel, 'Logs level will be changed back within 30 seconds');
            setTimeout(() => this.callWrapper.withLoaderAndSingleUuid(uuid => this.bridgeLogsService.getBridgeLogLevel(uuid), () => {}), 30000);
        } else {
            this.changeLogLevel(bridgeLogLevel);
        }
    }

    refresh() {
        this.callWrapper.withLoaderAndSingleUuid(uuid => this.bridgeLogsService.getCurrentLogs(uuid),
            data => {
                this.setLogs(data.logs);
                this.notification.showSuccessMessage('Logs refreshed');
            });
    }

    private changeLogLevel(bridgeLogLevel: BridgeLogLevel, details: string = ''): void {
        this.callWrapper.withLoaderAndSingleUuid(uuid => this.bridgeLogsService.changeLogLevel(bridgeLogLevel, uuid),
            () => this.notification.showSuccessMessage('Log level changed', details));
    }

    private createLoop(): Observable<string> {
        return combineLatest([
            timer(0, environment.logsRefreshInterval),
            this.activeRoute.params,
            this.authService.authState]
        ).pipe(
            filter(data => data[2]),
            map(value => value[1]['uuid'] as string)
        );
    }

    private setLogs(logs: string[]) {
        this.bridgeLogs = this.decorator.decorateLogs(logs);
    }
}
