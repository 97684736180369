import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LPSymbolsService} from '../../commons/lp-symbols.service';
import {LPSymbol} from '../../commons/model/symbol/lp/lp-symbol';
import * as _ from 'lodash';
import {distinctUntilChanged, finalize, map, switchMap, take} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import {NotificationsService} from '../../../commons/notifications.service';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {LpSymbolWithPrompt} from './dto/lp-symbol-with.prompt';
import {BrokerSymbolsService} from '../../commons/broker-symbols.service';
import {generatePrompt} from './prompt-generator';
import {LPSession} from '../../commons/model/symbol/lp/l-p-session';
import {LpHttpService} from '../services';
import {CallWrapperService} from '../../../commons/call-wrapper.service';
import {FileUpload} from 'primeng/fileupload';
import {BridgeDetailsPublisherService} from '../../commons/bridge-details-publisher.service';
import {UserRole} from '../../../commons/dto/user-role';
import {AuthenticationService} from '../../../commons/security/authentication.service';
import {BridgeValidHelper} from '../../helper';
import {Calculation, MultiplierCalculator} from '../interfaces/multiplier-calculator';
import {saveAs} from 'file-saver';
import {Transmit} from '../interfaces';
import {Params} from '@angular/router';
import {LazyLoadSymbols} from '../../commons/abstract/lazy-load-symbols.abstract';

@Component({
    selector: 'app-lp-session-symbols',
    templateUrl: './lp-session-symbols.component.html',
    styleUrls: ['./lp-session-symbols.component.scss'],
    providers: [CallWrapperService, LpHttpService, BridgeValidHelper]
})
export class LpSessionSymbolsComponent extends LazyLoadSymbols<LPSymbol> implements OnInit, OnDestroy {
    @Input() session: LPSession;
    @Input() bridgeUuid: string;

    public symbolKeys = [
        {field: 'symbolName', header: 'Symbol', width: '25%', type: 'text'},
        {field: 'symbolAlias', header: 'Alias', width: '25%', type: 'text'},
        {field: 'bidEnabled', header: 'Bid', width: '20%', type: 'checkbox'},
        {field: 'askEnabled', header: 'Ask', width: '20%', type: 'checkbox'},
        {field: 'multiplier', header: 'Multiplier', width: '10%', type: 'multiplier'}
    ];

    public filterEnabled: SelectItem[] = [
        {label: 'All', value: null},
        {label: 'Enabled', value: true},
        {label: 'Disabled', value: false},
    ];

    public displayCalculatorDialog: boolean;
    public isUserValid: boolean;
    public isFileUploadEnabled: boolean;
    public bridgeVersion: string;
    public multiplierData: MultiplierCalculator | null;
    public brokerDecimalPlaces: number | null;
    public displayAddDialog = false;
    public displayUpdateDialog = false;
    public newSymbol: LPSymbol;
    public selectedSymbol: LPSymbol;
    public isLoading = false;
    public isRefreshButtonLoading = false;

    private _subscription: Subscription;
    private _getUsersSub: Subscription;
    private _symbolsSub: Subscription;

    constructor(
        private notifications: NotificationsService,
        private lpSymbolService: LPSymbolsService,
        private brokerSymbolService: BrokerSymbolsService,
        private confirmationService: ConfirmationService,
        private bridgeDetailsPublisher: BridgeDetailsPublisherService,
        private authenticationService: AuthenticationService,
        private callWrapper: CallWrapperService,
        private lpHttp: LpHttpService,
        private bridgeValid: BridgeValidHelper
    ) {
        super();
    }

    public ngOnInit(): void {
        this._symbolsSub = this.getSymbols()
            .subscribe(symbols => {
                this.slicedSymbols = symbols;
                this.loadLazyData(this.lazyLoadEvent);
            });

        this._getUsersSub = this.authenticationService.userInfo.subscribe(userInfo => {
            this.isUserValid = userInfo.role === UserRole.ADMIN || userInfo.uploadCSVEnabled;
        });

        this._subscription = this.bridgeDetailsPublisher
            .bridgeInfo
            .pipe(
                distinctUntilChanged((x, y) => _.isEqual(x.brokerSessions, y.brokerSessions)),
            ).subscribe(bridgeDetails => {
                this.bridgeVersion = bridgeDetails.version.split('-')[0];
                this.isFileUploadEnabled = this.bridgeValid.isBridgeVersionAbove(this.bridgeVersion, '6.2');
            });
    }

    public getLpSymbolsCsv(): void {
        this.lpHttp.getLpSymbolsCsv(this.bridgeUuid, this.session.sessionName);
    }

    public uploadLpSymbolCsv(event: FileUpload, fileUpload: FileUpload): void {
        this.confirmationService.confirm({
            icon: 'pi pi-exclamation-triangle',
            header: 'Upload confirmation',
            message: 'Uploading symbols via a file replaces all symbols that are currently in the broker symbols configuration. Please make sure that the file you have prepared is correct.',
            accept: () => {
                const formData: FormData = new FormData();
                formData.append('file', event.files[0]);
                this.lpHttp.uploadLpSymbolCsv(formData, this.bridgeUuid, this.session.sessionName)
                    .pipe(finalize(() => fileUpload.clear()))
                    .subscribe(() => {
                        this.notifications.showSuccessMessage('LP Symbol Upload', 'LP symbol uploaded successfully');
                        this.reloadSymbols();
                    });
            },
            reject: () => {
                fileUpload.clear();
            }
        });
    }

    public cancelCalculator(): void {
        this.multiplierData = null;
        this.displayCalculatorDialog = false;
    }

    public cancelUpdate(): void {
        this.selectedSymbol = null;
        this.displayUpdateDialog = false;
    }

    public cancelAdd(): void {
        this.newSymbol = null;
        this.displayAddDialog = false;
    }

    public showUpdateSymbolDialog(event): void {
        this.displayUpdateDialog = true;
        this.selectedSymbol = _.cloneDeep(event.data);
    }

    public onCalculationClick(calculation: Calculation): void {
        this.lpHttp
            .calculateMultiplier(calculation, this._getRequestParameters())
            .pipe(take(1))
            .subscribe((calculated) => {
                this.multiplierData.multiplierCalculations.splice(this._getPosition(calculated), 1 , calculated);
                this.multiplierData.multiplierCalculations = [...this.multiplierData.multiplierCalculations];
            });
    }

    public onDownloadMultiplierClick(): void {
        this.lpHttp
            .getMultiplierCsv(this.multiplierData, this._getRequestParameters())
            .pipe(take(1))
            .subscribe(response => {
                saveAs(response, `calculator_${this.bridgeUuid}_session_${this.session.sessionName}.csv`);
        });
    }

    public onUploadMultiplierCsvClick({event, fileUpload}: Transmit): void {
        const formData: FormData = new FormData();
        formData.append('file', event.files[0]);
        this.lpHttp.uploadMultiplierCsv(formData, this._getRequestParameters())
            .pipe(finalize(() => fileUpload.clear()))
            .subscribe((multiplierData) => {
                this.multiplierData = multiplierData;
                this.notifications.showSuccessMessage('LP Symbol calculated', 'LP symbol calculated successfully');
            });
    }

    public onCalculatorApplyClick(): void {
        this.lpHttp.confirmMultiplier(this.multiplierData).subscribe(() => {
            this.refreshSymbols();
            this.multiplierData = null;
            this.displayCalculatorDialog = false;
        });
    }

    public updateSymbol(): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to update this symbol? Please remember that changes will be applied in the bridge immediately.',
            accept: () => {
                this.callWrapper.withStartingLoader(this.lpSymbolService.updateSymbol(
                        this.bridgeUuid,
                        this.selectedSymbol
                    ), () => {
                    this.reloadSymbols();
                    this.notifications.showSuccessMessage('Symbol updated!', 'Changes applied in the bridge!');
                });

                this.selectedSymbol = null;
                this.displayUpdateDialog = false;
            }
        });

    }

    public deleteSymbol(): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want remove this symbol? Please remember that changes will be applied in the bridge immediately.',
            accept: () => {
                this.callWrapper.withStartingLoader(this.lpSymbolService.deleteSymbol(
                    this.bridgeUuid,
                    this.selectedSymbol.id
                ), () => {
                    this.reloadSymbols();
                    this.notifications.showSuccessMessage(
                        'Symbol removed!',
                        'Changes applied in the bridge!'
                    );
                });
                this.selectedSymbol = null;
                this.displayUpdateDialog = false;
            }
        });
    }

    public addNewSymbol(): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want add this symbol? Please remember that changes will be applied in the bridge immediately.',
            accept: () => {
                this.callWrapper.withStartingLoader(this.lpSymbolService.addNewSymbol(
                    this.bridgeUuid, {
                        sessionName: this.session.sessionName,
                        symbol: this.newSymbol
                    }), () => {
                    this.reloadSymbols();
                    this.notifications.showSuccessMessage(
                        'Symbol saved!',
                        'Changes applied in the bridge!'
                    );
                });

                this.newSymbol = null;
                this.displayAddDialog = false;
            }
        });

    }

    trackByFn(index: number, item: LPSymbol): number {
        return item.id;
    }

    public ngOnDestroy(): void {
        this._symbolsSub.unsubscribe();
        this._subscription.unsubscribe();
        this._getUsersSub.unsubscribe();
    }

    private _getRequestParameters(): Params {
        return {
            bridgeUUID: this.bridgeUuid,
            lpSessionName: this.session.sessionName
        };
    }

    private showCalculatorDialogClick(): void {
        this.displayCalculatorDialog = true;
        this._getMultiplierData();
    }

    private showNewSymbolDialog(): void {
        this.displayAddDialog = true;
        this.newSymbol = new LPSymbol();
        this.newSymbol.askEnabled = true;
        this.newSymbol.bidEnabled = true;
        this.newSymbol.multiplier = '1';
    }

    private _getPosition(calculated: Calculation): number {
        return this.multiplierData.multiplierCalculations
            .findIndex(elem => elem.brokerSymbolName === calculated.brokerSymbolName);
    }

    private getSymbols(): Observable<LpSymbolWithPrompt[]> {
        return this.brokerSymbolService.getDecimalPlaces(this.bridgeUuid).pipe(
            map(decimalPlaces => {
                this.brokerDecimalPlaces = decimalPlaces;
                return this.session.symbols.map(symbol => this.mapToObjectWithPrompt(symbol, this.brokerDecimalPlaces, this.session.qtyDecimalPlaces));
            })
        );
    }

    private _getMultiplierData(): void {
        this.lpHttp.getMultiplierData(this._getRequestParameters())
            .subscribe((multiplierData) => this.multiplierData = multiplierData);
    }

    private reloadSymbols(): void {
        this.callWrapper.withEndingLoader(
            this.lpSymbolService.getSession(this.bridgeUuid, this.session.sessionName).pipe(
                switchMap(session => {
                    this.session = session;
                    return this.getSymbols();
                })), data => {
                this.slicedSymbols = data;
                this.loadLazyData(this.lazyLoadEvent);
                this.notifications.showSuccessMessage('Symbols refreshed!');
            });
    }

    private refreshSymbols(): void {
        this.isRefreshButtonLoading = true;
        this.callWrapper.withLoader(
            this.lpSymbolService.refreshSession(this.bridgeUuid, this.session.sessionName),
            session => {
                this.session = session;
                this.slicedSymbols = session.symbols.map(symbol => this.mapToObjectWithPrompt(symbol, this.brokerDecimalPlaces, session.qtyDecimalPlaces));
                this.loadLazyData(this.lazyLoadEvent);
                this.isRefreshButtonLoading = false;
                this.notifications.showSuccessMessage('Symbols refreshed!');
            });
    }

    private restartSession(): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want restart session?',
            accept: () => {
                this.callWrapper.withLoader(
                    this.lpSymbolService.restartSession(this.bridgeUuid, this.session.sessionName),
                    () =>
                        this.notifications
                            .showSuccessMessage('Session is restarting. Check recent logs')
                );
            }
        });

    }

    private mapToObjectWithPrompt(
        symbol: LPSymbol,
        brokerDecimalPlaces: number | null,
        lpDecimalPlaces: number
    ): LpSymbolWithPrompt {
        let prompt = '';
        if (brokerDecimalPlaces) {
            prompt = generatePrompt(symbol.multiplier, brokerDecimalPlaces, lpDecimalPlaces, '\n');
        }
        return {
            id: symbol.id,
            symbolAlias: symbol.symbolAlias,
            symbolName: symbol.symbolName,
            askEnabled: symbol.askEnabled,
            bidEnabled: symbol.bidEnabled,
            multiplier: symbol.multiplier,
            prompt
        };
    }
}
