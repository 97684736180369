import {Injectable} from '@angular/core';
import {ManualHedgeRequest} from '../dto/manual-hedge-request';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ManualHedgeRejected} from '../dto/manual-hedge-rejected.dto';

@Injectable({
    providedIn: 'root'
})
export class ManualHedgeService {
    private url = environment.hostUrl + '/hedge';

    constructor(private http: HttpClient) {
    }

    executeManualHedge(manualHedgeReq: ManualHedgeRequest): Observable<ManualHedgeRejected[]> {
        return this.http.post<ManualHedgeRejected[]>(`${this.url}/manual-hedge`, manualHedgeReq);
    }
}
