export class BridgeValidHelper {
    /**
     * Compares first 2 numbers between versions.
     * @param version Bridge version to compare
     * @param desiredVersion Desired version
     * @returns Is bridge version above desired version
     */
    public isBridgeVersionAbove(version: string, desiredVersion: string): boolean {
        const splitVersion = version.split('.');
        const splitDesiredVersion = desiredVersion.split('.');
        return +splitVersion[0] > +splitDesiredVersion[0] || +splitVersion[0] === +splitDesiredVersion[0] && +splitVersion[1] >= +splitDesiredVersion[1];
    }
}
