import {Book} from 'src/app/exposure/interfaces/book.interface';
import {LPSession} from '../../commons/model/symbol/lp/l-p-session';

export interface ABookRule {
    readonly uuid: string;
    readonly brokerName: string;
    readonly instrument: string;
    readonly groupName: string;
    readonly account: string;
    readonly lpName: string;
    readonly weight: number;
}


export function emptyABookRule(): ABookRule {
    return {
        uuid: null,
        lpName: null,
        instrument: null,
        groupName: null,
        brokerName: null,
        account: null,
        weight: null
    };
}

export function toAbookRule(bbook: Book, lp: LPSession, groupName: string, account: string) {
    return {
        uuid: bbook.uuid,
        lpName: lp.sessionName,
        instrument: bbook.symbolName,
        groupName,
        brokerName: bbook.brokerName,
        account,
        weight: -1
    };
}
