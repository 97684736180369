import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[appMlHighlight]'
})
export class MlHighlightDirective {

    @Input()
    isAbook: boolean;

    @Input()
    set ml(value: string) {
        const val = +value;
        if (val) {
            if (val < 100) {
                this.el.nativeElement.style.color = 'red';
            } else if (val >= 100 && val < 150) {
                this.el.nativeElement.style.color = '#d1a906';
            } else {
                this.el.nativeElement.style.color = 'green';
            }
            if (val > 10000) {
                if (this.isAbook) {
                    this.el.nativeElement.children[0].children[0].innerText = '--';
                } else {
                    this.el.nativeElement.innerText = '--';
                }
            }
        }
    }

    constructor(private el: ElementRef) {
    }
}
