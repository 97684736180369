import {Component, Input, OnInit} from '@angular/core';
import {OrderBook} from '../../dto/order-book';
import {OrderBookRow} from '../../dto/order-book-row';

@Component({
    selector: 'app-order-book-part',
    templateUrl: './order-book-part.component.html',
    styleUrls: ['./order-book-part.component.scss']
})
export class OrderBookPartComponent implements OnInit {

    @Input()
    positions: OrderBookRow[];

    constructor() {
    }

    ngOnInit() {
    }

}
