import { Component } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import {ReportFiltersDetailed, ReportRecord, ReportsService, ReportSummary} from '../services/reports.service';
import {FilterParametersHelper, InitializationDataHelper} from '../helpers';
import {InitializationFilterService} from '../services/initialization-filter.service';

@Component({
  selector: 'app-detailed-hedging',
  templateUrl: './detailed-hedging.component.html',
  styleUrls: ['./detailed-hedging.component.scss'],
  providers: [InitializationDataHelper, FilterParametersHelper, InitializationFilterService]
})
export class DetailedHedgingComponent {
  public loading = false;
  public bridges: SelectItem[] = [];
  public filters: ReportFiltersDetailed = InitializationDataHelper.filters;
  public bookTypes: SelectItem[] = InitializationDataHelper.bookTypes;
  public statuses: SelectItem[] = InitializationDataHelper.statuses;
  public reportRecords: ReportRecord[] = [];
  public reportSummary: ReportSummary = {} as ReportSummary;

  constructor(
    private readonly reportsService: ReportsService,
    private filterParameters: FilterParametersHelper,
    private initializationFilter: InitializationFilterService
  ) {
    this.initializationFilters();
  }

  public search(): void {
    this.loading = true;
    this.reportsService.getReport('DETAILED_HEDGE', this.filterParameters.getQueryParameters(this.filters))
      .pipe(finalize(() => this.loading = false))
      .subscribe(report => {
        this.reportRecords = report.records || [];
        this.reportSummary = report.summary || {} as ReportSummary;
      });
  }

  public downLoadReportClick(): void {
    this.reportsService.getReportFile('DETAILED_HEDGE', this.filterParameters.getQueryParameters(this.filters));
  }

  private initializationFilters(): void {
    this.initializationFilter.getFilter().subscribe(list => {
      this.bridges = list.sort((a, b) => a.label.localeCompare(b.label));
      this.setFilters(list[0].value);
    });
  }

  private setFilters(uuid: string): void {
    const from = new Date(Date.now() - 86400000);
    from.setSeconds(0, 0);

    const to = new Date();
    to.setSeconds(0, 0);

    this.filters = {
      uuid: uuid,
      from: from,
      to: to,
      bookType: 'BOOK_BOTH',
      orderStatus: 'BOTH',
    };
  }
}
