import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {BridgeDetailedStatus} from './model/status/bridge-detailed-status';

@Injectable()
export class BridgeDetailsPublisherService {

    bridgeInfo = new ReplaySubject<BridgeDetailedStatus>(1);

    constructor() {
    }

    set(bridge: BridgeDetailedStatus) {
        this.bridgeInfo.next(bridge);
    }
}
