import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {BRIDGE_REGISTER_URL, BRIDGE_UPGRADE_URL} from '../../utils/constants';
import {
    BridgeRegisterModel,
    BuildNumbersFromJenkins,
    GetBridgeHosts,
    Branches,
    BranchesGatewayApi,
    GatewayApiVersion,
    JenkinsBuildStatus,
    BridgeCheckboxFillModel,
    JenkinsBuildStatusAll,
    GatewayApiVersionAll
} from '../dto/models';

@Injectable({
    providedIn: 'root'
})
export class AdminDashboardService {
    constructor(private http: HttpClient) {
    }

    getBridges(): Observable<BridgeRegisterModel[]> {
        return this.http.get<BridgeRegisterModel[]>(`${BRIDGE_REGISTER_URL}/all`);
    }

    triggerBridgeInstall(JenkisBuildRequestModel): Observable<Response> {
        const url = `${BRIDGE_UPGRADE_URL}/install/bridge`;
        return this.http.post<Response>(url, JenkisBuildRequestModel);
    }

    triggerJenkinsBuildMultiple(bridgeRegisterModelList: BridgeRegisterModel[]): Observable<Response> {
        const url = `${BRIDGE_UPGRADE_URL}/upgrade/multiple`;
        return this.http.post<Response>(url, bridgeRegisterModelList);
    }

    sendUpgradeValues(bridgeCheckboxFillModel: BridgeCheckboxFillModel): Observable<Response> {
        const url = `${BRIDGE_UPGRADE_URL}/upgrade/multiple/options`;
        return this.http.post<Response>(url, bridgeCheckboxFillModel);
    }

    getBuildStatus(uuid: string): Observable<JenkinsBuildStatus[]> {
        return this.http.get<JenkinsBuildStatus[]>(`${BRIDGE_UPGRADE_URL}/buildstatus/${uuid}`);
    }

    getAllBuildStatus(): Observable<JenkinsBuildStatusAll[]> {
        return this.http.get<JenkinsBuildStatusAll[]>(`${BRIDGE_UPGRADE_URL}/buildstatus/all`);
    }

    getBuildStatusInstall(uuid: string): Observable<JenkinsBuildStatus[]> {
        return this.http.get<JenkinsBuildStatus[]>(`${BRIDGE_UPGRADE_URL}/install/build/status/${uuid}`);
    }

    getBuildNumbers(branch: string): Observable<BuildNumbersFromJenkins> {
        return this.http.get<BuildNumbersFromJenkins>(
            `${BRIDGE_UPGRADE_URL}/buildnumbers/${branch}`
        );
    }

    getGatewayApiBuildNumbers(
        branch: string
    ): Observable<BuildNumbersFromJenkins> {
        return this.http.get<BuildNumbersFromJenkins>(
            `${BRIDGE_UPGRADE_URL}/gatewayapi/buildnumbers/${branch}`
        );
    }

    getBridgeHosts(): Observable<GetBridgeHosts> {
        return this.http.get<GetBridgeHosts>(
            `${BRIDGE_UPGRADE_URL}/getbridgehosts`
        );
    }

    getBuildNumberFromJenkins(uuid: string): Observable<String> {
        return this.http.get<String>(`${BRIDGE_UPGRADE_URL}/buildnumber/${uuid}`);
    }

    getBranches(): Observable<Branches> {
        return this.http.get<Branches>(`${BRIDGE_UPGRADE_URL}/getbranches`);
    }

    getBranchesGatewayAPI(): Observable<BranchesGatewayApi> {
        return this.http.get<BranchesGatewayApi>(
            `${BRIDGE_UPGRADE_URL}/getbranchesgatewayapi`
        );
    }

    deleteBridgeFromBMAndMoveToUnusedDirectory(uuid: String): Observable<Response> {
        const url = `${BRIDGE_UPGRADE_URL}/delete/bridge/admin/${uuid}`;
        return this.http.delete<Response>(url);
    }
}
