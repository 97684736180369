export enum Level {
    ALL,
    DEBUG,
    ERROR,
    INFO,
    FATAL,
    OFF,
    TRACE,
    WARN
}
