import {Component, Input} from '@angular/core';
import {DataChartsFormatter} from 'src/app/bridge-statistics/helpers';
import {ChartData, Dataset} from 'src/app/bridge-statistics/interfaces';
import {VOLUME_PROFIT_CHART_OPTIONS} from '../../helpers/volume-profit-chart.helper';
import {VolumeProfit} from '../../interfaces/volume-profit.interface';

interface VolumeProfitChartData {
  buyVolume: ChartData;
  buyVolumeUnits: ChartData;
  sellVolume: ChartData;
  sellVolumeUnits: ChartData;
  loss: ChartData;
  profit: ChartData;
}

@Component({
  selector: 'app-volume-profit-charts',
  templateUrl: './volume-profit-charts.component.html',
  styleUrls: ['./volume-profit-charts.component.scss'],
})
export class VolumeProfitChartsComponent {
  @Input() isShowUnits: boolean;
  @Input() set volumeProfitData(value: VolumeProfit) {
    if (!value) {
      this.chartData = null;
      return;
    }
    const { buyVolume, buyVolumeUnits, sellVolume, sellVolumeUnits, profit, loss } = value;
    const volumeBarColors = ['#14284f', '#11A4DB', '#41C5E7', '#336884', '#A6CEE3', '#1ABC9C'];
    const profitBarColors = ['#11823b'];
    const lossBarColors = ['#f30000'];
    this.chartData = {
      buyVolume: this.transformData(buyVolume, 'symbol', 'volume', volumeBarColors),
      buyVolumeUnits: this.transformData(buyVolumeUnits, 'symbol', 'volume', volumeBarColors),
      sellVolume: this.transformData(sellVolume, 'symbol', 'volume', volumeBarColors),
      sellVolumeUnits: this.transformData(sellVolumeUnits, 'symbol', 'volume', volumeBarColors),
      profit: this.transformData(profit, 'symbol', 'profit', profitBarColors),
      loss: this.transformData(loss, 'symbol', 'profit', lossBarColors),
    };
  }

  chartData: VolumeProfitChartData;

  options = VOLUME_PROFIT_CHART_OPTIONS;

  private transformData<T>(
    input: T[],
    xKey: keyof T,
    yKey: keyof T,
    backgroundColors: string[]
  ): ChartData {
    const chartData: Dataset[] = input.reduce((data, item, index) => {
      // Condition to avoid zero value bars in chart
      if (item[yKey]) {
        data.push({
          label: item[xKey] as unknown as string,
          data: [item[yKey] as unknown as number],
          backgroundColor: backgroundColors[index % backgroundColors.length]
        });
      }
      return data;
    }, []);

    return {
      labels: [],
      datasets: DataChartsFormatter.getChartConfigurationData(chartData, false),
    };
  }
}
