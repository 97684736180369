import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';

@Injectable()
export class SelectedBridgeService {

    bridgeChange = new ReplaySubject<string>(1);
    uuid: string | null;

    constructor() {
    }

    sendUuid(uuid: string) {
        this.uuid = uuid;
        this.bridgeChange.next(uuid);
    }
}
