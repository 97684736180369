import {Injectable} from '@angular/core';
import {MessageService} from 'primeng/api';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private messageService: MessageService) {
    }

    showConnectionErrorMessage(err: HttpErrorResponse | string) {
        let message = 'Connection problems';
        if (err instanceof HttpErrorResponse && err.status !== 0) {
            message = err.message; // TODO replace on prod mode?
        } else if (typeof err === 'string') {
            message = err;
        }

        this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: message,
            life: 5000
        });
    }

    showItemExistsErrorMessage(err: HttpErrorResponse | string) {
        let message = 'Element already exists';
        if (err instanceof HttpErrorResponse && err.status !== 0) {
            message = err.message;
        } else if (typeof err === 'string') {
            message = err;
        }

        this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: message,
            life: 5000
        });
    }

    showSessionExpiredMessage() {
        this.messageService.add({
            severity: 'error',
            summary: 'Session expired',
            detail: 'You need login again',
            life: 5000
        });
    }

    showSuccessMessage(title: string, details: string = null) {
        this.messageService.add({
            severity: 'success',
            summary: title,
            detail: details,
            life: 5000
        });
    }

    showWarningMessage(title: string, details: string = null) {
        this.messageService.add({
            severity: 'warn',
            summary: title,
            detail: details,
            life: 5000
        });
    }

    showInfoMessage(message: string) {
        this.messageService.add({
            severity: 'info',
            summary: message,
            life: 5000
        });
    }
}
