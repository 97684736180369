import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ABookRule} from '../model/abook-rule';
import {Subscription, zip} from 'rxjs';
import {CallWrapperService} from 'src/app/commons/call-wrapper.service';
import {BrokerSymbolsService} from '../../commons/broker-symbols.service';
import {LPSymbolsService} from '../../commons/lp-symbols.service';
import {SelectItem} from 'primeng/components/common/api';
import {ABookRuleDisplay} from '../model/abook-rule-display';
import {BMValidators} from '../../../commons/b-m-validators';

@Component({
    selector: 'app-abook-rule',
    templateUrl: './a-book-rule.component.html',
    styleUrls: ['./a-book-rule.component.scss'],
    providers: [CallWrapperService]
})
export class ABookRuleComponent implements OnInit, OnDestroy {

    @Input()
    set value(newState: ABookRuleDisplay) {
        this._value = newState;
        if (newState) {
            this.form.patchValue(this._value);
        } else {
            this.form.reset();
        }
    }

    get value() {
        return this._value;
    }

    @Output()
    update = new EventEmitter<ABookRule>();

    @Output()
    delete = new EventEmitter<ABookRule>();

    brokerName = new FormControl('', [Validators.required, Validators.maxLength(50)]);
    instrument = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
    groupName = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
    account = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
    lpName = new FormControl('', [Validators.required, Validators.maxLength(50)]);

    @Output()
    form = new FormGroup({
        uuid: new FormControl(),
        brokerName: this.brokerName,
        instrument: this.instrument,
        groupName: this.groupName,
        account: this.account,
        lpName: this.lpName,
    });

    brokerItems: SelectItem[] = [];
    lpItems: SelectItem[] = [];

    private _value: ABookRuleDisplay;
    private _subscription: Subscription;

    constructor(private callWrapper: CallWrapperService,
                private brokerSymbolService: BrokerSymbolsService,
                private lpSymbolService: LPSymbolsService) {
    }

    ngOnInit() {
        this._subscription = this.callWrapper.withStableUuid(uuid =>
                zip(this.brokerSymbolService.getBrokerSessionsNames(uuid), this.lpSymbolService.getLpSessionsNames(uuid)),
            list => {
                this.brokerItems = list[0].map(el => {
                    return {label: el, value: el};
                });
                this.lpItems = list[1].map(el => {
                    return {label: el, value: el};
                });
            }
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    send() {
        this.update.emit(this.convertToABookRule(this.form.value) as ABookRule);
    }

    deleteItem() {
        this.delete.emit(this.form.value as ABookRule);
    }

    private convertToABookRule(abookDisplay: ABookRuleDisplay) {
        return {
            account: abookDisplay.account,
            brokerName: abookDisplay.brokerName.join(','),
            instrument: abookDisplay.instrument,
            groupName: abookDisplay.groupName,
            uuid: abookDisplay.uuid,
            lpName: abookDisplay.lpName.join(',')
        };
    }
}
