import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {OrderBook} from '../dto/order-book';
import {OrderBookType} from '../dto/order-book-type';
import {AddSuffixPipe} from 'src/app/pipes/add-suffix.pipe';

@Component({
    selector: 'app-order-book',
    templateUrl: './order-book.component.html',
    styleUrls: ['./order-book.component.scss'],
    providers: [AddSuffixPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class OrderBookComponent {

    OrderBookType = OrderBookType;

    @Input()
    orderBook: OrderBook;

    @Output()
    deleteItem = new EventEmitter<OrderBook>();
}
