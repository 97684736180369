import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {NotificationsService} from '../../commons/notifications.service';
import {ConfirmationService} from 'primeng/api';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {SymbolsChangeService} from '../services/symbols-change.service';
import { Router } from '@angular/router';
import {ExpositionDataHttpService} from './services';
import {map, takeUntil} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConditionTypeEnum, ReceiversEnum, RuleTypeEnum} from './enums';
import {ExpositionRule} from './interfaces';
import {BridgeDetailsPublisherService} from '../commons/bridge-details-publisher.service';
import {UserDto} from '../../commons/dto/user-dto';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {UserRole} from '../../commons/dto/user-role';
import {CONDITION_TYPE, RECEIVERS_LIST, RULE_TYPE} from './helpers/filter-data';
import {registerLocaleData} from '@angular/common';
import {FormStatusEnum} from '../../commons/enums';
import fr from '@angular/common/locales/fr';

@Component({
  selector: 'app-exposition-rules',
  templateUrl: './exposition-rules.component.html',
  styleUrls: ['./exposition-rules.component.scss'],
  providers: [ExpositionDataHttpService]
})
export class ExpositionRulesComponent implements OnInit, OnDestroy {
    public expositionRule: ExpositionRule[];
    public isLoading = true;
    public expositionForm: FormGroup;
    public formStatus: FormStatusEnum | null;
    public bridgeName: string;
    public currentUser: UserDto;
    public ruleTypeList = RULE_TYPE;
    public conditionTypeList = CONDITION_TYPE;
    public receiversList = RECEIVERS_LIST;
    public userRoleEnum = UserRole;
    public formStatusEnum = FormStatusEnum;

    private _selectedExpositionRule: ExpositionRule;
    private readonly _completeSubject = new Subject<void>();

    constructor(
        private expositionDataHttp: ExpositionDataHttpService,
        private notifications: NotificationsService,
        private confirmationService: ConfirmationService,
        private callWrapper: CallWrapperService,
        private symbolsChange: SymbolsChangeService,
        private bridgeDetailsPublisher: BridgeDetailsPublisherService,
        private authService: AuthenticationService,
        private router: Router,
        private fb: FormBuilder) {}

    public ngOnInit(): void {
        registerLocaleData( fr );
        this._initialization();
    }

    public get selectedExpositionRule(): ExpositionRule {
        return this._selectedExpositionRule;
    }

    public set selectedExpositionRule(selectedRule: ExpositionRule) {
        this._selectedExpositionRule = selectedRule;
        this.expositionForm = this._createExpositionForm(selectedRule);
        this.formStatus = FormStatusEnum.edit;
    }

    public showNewRuleDialog(): void {
        this.expositionForm = this._createExpositionForm();
        this.formStatus = FormStatusEnum.create;
    }

    public addNewRule(expositionData: ExpositionRule) {
        this.callWrapper.withLoaderAndSingleUuid(uuid =>
                this.expositionDataHttp.createExposition(uuid, this.bridgeName, expositionData),
            (exposition) => {
                this.expositionRule = exposition.rules;
                this.closeNewRuleDialog();
                this.notifications.showSuccessMessage('New Exposition rule created.');
            });
    }

    public editRule(rule: ExpositionRule) {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.expositionDataHttp.updateExposition(uuid, this.bridgeName, rule),
            (exposition) => {
                this.expositionRule = exposition.rules;
                this.closeUpdateRuleDialog();
                this.notifications.showSuccessMessage('Exposition rule updated.');
            });
    }

    public deleteRule(rule: ExpositionRule) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want remove this exposition rule?',
            accept: () => {
                this.callWrapper.withLoaderAndSingleUuid(
                    uuid => this.expositionDataHttp.deleteExposition(uuid, this.bridgeName, rule.ruleUUID),
                    (exposition) => {
                        this.expositionRule = exposition.rules;
                        this.closeUpdateRuleDialog();
                        this.notifications.showSuccessMessage('Exposition rule deleted.');
                    }
                );
            }
        });
    }

    public refreshRules() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.expositionDataHttp.getExposition(uuid),
            expositionRules => {
                this.currentUser.role === UserRole.USER
                    ? this.expositionRule = expositionRules.rules.filter(exposition => exposition.receivers !== ReceiversEnum.admin)
                    : this.expositionRule = expositionRules.rules;
                this.notifications.showSuccessMessage('Exposition rules refreshed!');
            });
    }

    public closeNewRuleDialog() {
        this.formStatus = null;
        this.expositionForm = null;
    }

    public closeUpdateRuleDialog() {
        this.formStatus = null;
        this._selectedExpositionRule = null;
        this.expositionForm = null;
    }

    public ngOnDestroy(): void {
        this._completeSubject.next();
        this._completeSubject.complete();
    }

    private _createExpositionForm(selectedRule?: ExpositionRule): FormGroup {
        return this.fb.group({
            ruleUUID: [selectedRule ? selectedRule.ruleUUID : ''],
            brokerName: [selectedRule ? selectedRule.brokerName.split(',') : '', Validators.required],
            instrument: [selectedRule ? selectedRule.instrument : '', Validators.required],
            groupName: [selectedRule ? selectedRule.groupName : '', Validators.required],
            account: [selectedRule ? selectedRule.account : '', Validators.required],
            ruleType: [{value: selectedRule ? selectedRule.ruleType : RuleTypeEnum.volumeInLots}, Validators.required],
            conditionType: [{value: selectedRule ? selectedRule.conditionType : ConditionTypeEnum.above}, Validators.required],
            conditionValue: [selectedRule ? selectedRule.conditionValue : 0, [Validators.required]],
            receivers: [{value: selectedRule ? selectedRule.receivers : ReceiversEnum.owner}, Validators.required],
        });
    }

    private _initialization(): void {
        this.authService.userInfo
            .pipe(takeUntil(this._completeSubject))
            .subscribe(data => this.currentUser = data);

        this.callWrapper.withStableUuid(
            uuid => this.expositionDataHttp.getExposition(uuid).pipe(
                map(exposition => exposition.rules),
                takeUntil(this._completeSubject)),
            list => {
                this.currentUser.role === UserRole.USER
                    ? this.expositionRule = list.filter(exposition => exposition.receivers !== ReceiversEnum.admin)
                    : this.expositionRule = list;
                this.isLoading = false;
            });

        this.bridgeDetailsPublisher.bridgeInfo
            .pipe(takeUntil(this._completeSubject))
            .subscribe(bridgeInfo => this.bridgeName = bridgeInfo.name);
    }
}
