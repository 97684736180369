import {ChartData, Dataset} from '../interfaces';

export class DataChartsFormatter {
    public static getDoughnutChartData(data: ChartData[]) {
        return data.map(item => {
            return {
                labels: item.labels,
                datasets: [{
                    data: item.data,
                    backgroundColor: ['#14284f', '#11A4DB', '#41C5E7', '#336884', '#A6CEE3', '#1ABC9C'],
                    hoverBackgroundColor: ['#14284f', '#11A4DB', '#41c5e7', '#336884', '#A6CEE3', '#1abc9c']
                }]
            };
        });
    }

    public static getChartConfigurationData(dataset: Dataset[], isLineChart: boolean): Dataset[] {
        if (isLineChart) {
            return dataset.map((data, index) => {
                if (index % 2 === 0) {
                    return {
                        ...data,
                        fill: false,
                        borderColor: '#11A4DB'
                    };
                } else {
                    return {
                        ...data,
                        fill: false,
                        borderColor: '#A6CEE3'
                    };
                }

            });
        } else {
            return dataset.map((data, index) => {
                if (index % 2 === 0) {
                    return {
                        ...data,
                        backgroundColor: data.backgroundColor || '#11A4DB',
                    };
                } else {
                    return {
                        ...data,
                        backgroundColor: data.backgroundColor || '#A6CEE3',
                    };
                }

            });
        }
    }
}
