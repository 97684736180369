import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, timer, combineLatest} from 'rxjs';
import {environment} from '../../../environments/environment';
import {filter, switchMap, take} from 'rxjs/operators';
import {BridgeSimpleStatusService} from '../../all-bridges/services/bridge-simple-status.service';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {Router} from '@angular/router';
import {BridgesPublisherService} from '../../all-bridges/services/bridges-publisher.service';
import {NotificationsService} from '../../commons/notifications.service';
import { BridgeStatusesService } from 'src/app/admin-dashboard/services/bridge-statuses.service';

@Component({
    selector: 'app-holder',
    templateUrl: './app-holder.component.html',
    styleUrls: ['./app-holder.component.scss']
})
export class AppHolderComponent implements OnInit, OnDestroy {

    private _subscription: Subscription;

    constructor(public bridgeSimpleStatusService: BridgeSimpleStatusService,
                private authenticationService: AuthenticationService,
                private statusPublisher: BridgesPublisherService,
                private bridgeStatuses: BridgeStatusesService,
                private router: Router,
                private notifications: NotificationsService) {

        this.authenticationService.authState
            .pipe(filter(isLogged => !isLogged))
            .subscribe(() => this.router.navigateByUrl('/login'));

        this.authenticationService.authState.pipe(
            filter(isLogged => isLogged),
            switchMap(() => this.bridgeSimpleStatusService.getAllBridgesSimpleStatus())
        ).subscribe(list => {
            this.statusPublisher.set(list);
        }, () => this.handleConnectionLost());

        this._subscription = this.authenticationService.authState
            .pipe(
                filter(isLogged => isLogged),
                switchMap(() =>
                    combineLatest([
                        this.bridgeStatuses.watchBridgeStatuses(),
                        this.statusPublisher.bridgesInfo
                    ])
                )
            ).subscribe(([updatedStatus, bridges]) => {
                const updatedBridgeList = [...bridges];
                const updatedBridgeIndex = bridges.findIndex(bridge => bridge.uuid === updatedStatus.uuid);
                updatedBridgeList[updatedBridgeIndex] = updatedStatus;
                this.statusPublisher.set(updatedBridgeList);
            }, () => this.handleConnectionLost());
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private handleConnectionLost() {
        this.notifications.showConnectionErrorMessage('Lost connection with server, try login again');
        this.router.navigateByUrl('/login');
    }
}
