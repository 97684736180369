import {SelectItem} from 'primeng/api';
import {TableField} from '../../../commons/interfaces/table-field';

export class UsersDataHelper {
    public static REPORT_OPTION: SelectItem[] = [
        {label: 'Hide', value: false},
        {label: 'Show', value: true},
    ];

    public static UPLOAD_CSV: SelectItem[] = [
        { label: 'Enabled', value: true },
        { label: 'Disabled', value: false }
    ];

    public static TABLE_COLUMNS: TableField[] = [
        {field: 'name', header: 'Name'},
    ];
}
