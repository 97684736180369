import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HedgeMonitorDto} from '../dto/hedge-monitor-dto';

@Injectable({
    providedIn: 'root'
})
export class HedgeMonitorService {

    private url = environment.hostUrl + '/hedge-monitor';

    constructor(private http: HttpClient) {
    }

    getDataForHedgeMonitor(uuids: string[]): Observable<HedgeMonitorDto> {
        return this.http.post<HedgeMonitorDto>(this.url, uuids);
    }

    getDataForOneBridge(uuid: string): Observable<HedgeMonitorDto> {
        return this.http.get<HedgeMonitorDto>(`${this.url}/${uuid}`);
    }
}
