import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api';
import {Level} from '../commons/model/logs/level';
import {BridgeLogsSettings} from '../commons/model/logs/bridge-logs-settings';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../commons/loader.service';
import {NotificationsService} from '../../commons/notifications.service';
import {ActivatedRoute} from '@angular/router';
import {CurrentLogsService} from '../current-logs/current-logs.service';
import {SearchLogsService} from '../search-logs/search-logs.service';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {ErrorManagerService} from '../../commons/error/error-manager.service';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {TextDecoratorService} from '../commons/text-decorator.service';
import {map, switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-auto-hedge-logs',
    templateUrl: './auto-hedge-logs.component.html',
    styleUrls: ['./auto-hedge-logs.component.scss']
})
export class AutoHedgeLogsComponent implements OnInit, OnDestroy {

    bridgeLogLevels: SelectItem[] = [
        {label: 'All', value: Level[Level.ALL]},
        {label: 'Trace', value: Level[Level.TRACE]},
        {label: 'Debug', value: Level[Level.DEBUG]},
        {label: 'Info', value: Level[Level.INFO]},
        {label: 'Warn', value: Level[Level.WARN]},
        {label: 'Error', value: Level[Level.ERROR]},
        {label: 'Fatal', value: Level[Level.FATAL]}
    ];

    @ViewChild('logs', {static: false}) logsTable: any;
    today = new Date();
    searchSettings: BridgeLogsSettings;
    bridgeLogs: string[] = null;
    uuid: string;
    private _subscription: Subscription;

    constructor(
        private loader: LoaderService,
        private notification: NotificationsService,
        private activeRoute: ActivatedRoute,
        private bridgeLogsService: CurrentLogsService,
        private bridgeSearchService: SearchLogsService,
        private authService: AuthenticationService,
        private errorManager: ErrorManagerService,
        private callWrapper: CallWrapperService,
        private decorator: TextDecoratorService,
        private loadingService: LoaderService) {
        this.setEmptyFilters();
    }

    ngOnInit(): void {
        this._subscription = this.activeRoute.params.pipe(map(params => this.uuid = params['uuid']),
            switchMap(uuid => this.bridgeLogsService.getCurrentAutoHedgeLogs(uuid)))
            .subscribe(bridgeLogs => this.setLogs(bridgeLogs.bmLogs));
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    search(): void {
        this.loadingService.loading = true;
        this.bridgeLogs = null;
        this.bridgeSearchService.searchAutoHedgeLogs(this.searchSettings, 0, this.uuid).subscribe(
            bridgeLogs => {
                this.loadingService.loading = false;
                if (bridgeLogs) {
                    this.setLogs(bridgeLogs.bmLogs);
                } else {
                    this.bridgeLogs = [];
                }
                this.logsTable.first = 0;
            },
            err =>
                this.errorManager.handle(err, {
                    onDone: () => {
                        this.loadingService.loading = false;
                        this.bridgeLogs = [];
                    }
                })
        );
    }

    private setLogs(logs: string[]): void {
        this.bridgeLogs = this.decorator.decorateLogs(logs);
    }

    private setEmptyFilters(): void {
        const to = new Date();
        to.setSeconds(0, 0);

        const from = new Date(Date.now() - 86400000);
        from.setSeconds(0, 0);
        this.searchSettings = {
            level: this.bridgeLogLevels[0].value.name,
            orderId: '',
            from: from,
            to: to,
            text: ''
        };
    }

    loadData(event: any): void {
        this.loadingService.loading = true;
        this.bridgeLogs = null;
        this.bridgeSearchService
            .searchAutoHedgeLogs(this.searchSettings, event.first, this.uuid)
            .subscribe(
                bridgeLogs => {
                    this.loadingService.loading = false;
                    if (bridgeLogs) {
                        this.setLogs(bridgeLogs.bmLogs);
                    } else {
                        this.bridgeLogs = [];
                    }
                },
                err =>
                    this.errorManager.handle(err, {
                        onDone: () => {
                            this.loadingService.loading = false;
                            this.bridgeLogs = [];
                        }
                    })
            );
    }
}
