import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-hedge-a-book-dialog',
  templateUrl: './hedge-a-book-dialog.component.html',
  styleUrls: ['./hedge-a-book-dialog.component.scss'],
})
export class HedgeABookDialogComponent {
  @Input() symbolName: string;
  @Input() selectedAccountsToHedge: string;
  @Input() selectedBridgeToHedge: string;

  @Output() submit = new EventEmitter();
  @Output() cancel = new EventEmitter();

  confirm(): void {
    this.submit.emit();
  }

  reject(): void {
    this.cancel.emit();
  }
}
