import { Component, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from '../commons/security/authentication.service';
import { interval, Subscription, zip } from 'rxjs';
import { Router } from '@angular/router';
import { AdminDashboardService } from './services/admin-dashboard.service';
import { environment } from 'src/environments/environment';
import {
    BranchesGatewayApi,
    BridgeCheckboxFillModel,
    BridgeRegisterModel,
    BuildNumberFromJenkins,
    GetBridgeHostModel,
    GetBridgeHosts,
    JenkisBuildRequestModel,
} from './dto/models';
import { filter } from 'rxjs/operators';
import { UserRole } from '../commons/dto/user-role';
import { BridgesPublisherService } from '../all-bridges/services/bridges-publisher.service';
import { State } from '../commons/dto/state';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnDestroy {
    private _retrieveBuildNumberFromELK: Subscription;
    private _getUsersSub: Subscription;
    private refresherSub: Subscription;
    private _buildNumbersSub: Subscription;
    private _getBuildStatusSubBridgeInstall: Subscription;
    private _getBuildStatusSubGatewayApiInstall: Subscription;
    private _statusSub: Subscription;
    private allBridgesInfo: BridgeRegisterModel[] = [];
    private sortedBridgesInfo: BridgeRegisterModel[] = [];
    private respAllBridgesInfo: BridgeRegisterModel[];
    private applicationTypes: string[] = ['bridge', 'bridgeMT5'];
    private currentSelectedApplicationType = 'bridge';
    private initValue: BridgeCheckboxFillModel[] = [];
    private buildStatus: String[] = [];
    private initIPInstall: string;
    private buildNumbers: BuildNumberFromJenkins[][] = [];
    private buildNumbersGatewayApi: BuildNumberFromJenkins[][] = [];
    private chosenBuildNumber: string;
    private chosenBuildNumberGatewayApi: string;
    private installChosenBuildNumber: string;
    private bridgeHosts: GetBridgeHostModel[] = [];
    private refreshTime: number = environment.refreshTimeAdminDashboard;
    private gitlabUrl: string = environment.gitlab || 'http://gitlab';
    private jenkinsUrl: string = environment.jenkins || 'http://jenkins';
    private buildNumberFromJenkinsList: String[] = [];
    private updateWorkdir = '';
    private branches: string[];
    private branchesGatewayApi: BranchesGatewayApi;
    private chosenBranch: string;
    private chosenBranchesGatewayApi: string;
    private jenkisBuildRequestModel: JenkisBuildRequestModel;
    private jenkinsinstallbridge: string = environment.jenkinsinstallbridge;
    private jenkinsinstallgatewayapi: string = environment.jenkinsinstallgatewayapi;
    private bridgeSelectedToDelete: string;
    private confirmDelete = '';
    loading = true;
    allMultipleUpdateVar = false;
    installBridgeName = '';
    @ViewChild('sortByName', { static: false }) sortByName: any;
    @ViewChild('sortByIP', { static: false }) sortByIP: any;
    @ViewChild('dt', { static: false }) dt: any;

    constructor(
        private authenticationService: AuthenticationService,
        private adminDashboardService: AdminDashboardService,
        private router: Router,
        private statusPublisher: BridgesPublisherService
    ) {
        this.initJenkinsBuildRequestModel();
        this.initValue['multiple'] = {
            upgrade: false,
            onlyStart: false,
            onlyStop: false,
            bridge: false,
            gatewayAPI: false,
            buildnumber: null,
            branch: ''
        };

        this._getUsersSub = this.authenticationService.userInfo
            .pipe(filter(auth => auth.role === UserRole.USER))
            .subscribe(() => {
                this.router.navigate(['/app/dashboard']);
            });

        this.initAllBridgesInfo();

        this.adminDashboardService
            .getBridgeHosts()
            .subscribe((resp: GetBridgeHosts) => {
                this.bridgeHosts = resp.hosts;
                if (resp.hosts[0]) {
                    this.initIPInstall = resp.hosts[0].ip;
                }
            });

        this.refresherSub = interval(this.refreshTime).subscribe(() =>
            this.refreshData()
        );
    }

    ngOnDestroy() {
        this._getUsersSub.unsubscribe();
        this.refresherSub.unsubscribe();
        if (this._buildNumbersSub) {
            this._buildNumbersSub.unsubscribe();
        }
        if (this._statusSub) {
            this._statusSub.unsubscribe();
        }
        if (this._retrieveBuildNumberFromELK) {
            this._retrieveBuildNumberFromELK.unsubscribe();
        }
        if (this._getBuildStatusSubBridgeInstall) {
            this._getBuildStatusSubBridgeInstall.unsubscribe();
        }
        if (this._getBuildStatusSubGatewayApiInstall) {
            this._getBuildStatusSubGatewayApiInstall.unsubscribe();
        }
    }

    proceedUpgrade() {
        this.refresherSub.unsubscribe();
        let selectedApps: BridgeRegisterModel[] = [];
        this.allMultipleUpdateVar = false;
        for (const index of Object.keys(this.allBridgesInfo)) {
            if (this.allBridgesInfo[index].selected) {
                if (this.initValue['multiple'].gatewayAPI) {
                    this.allBridgesInfo[index].workingDir = this.allBridgesInfo[index].workingDir.replace('\\Bridge', '\\gatewayAPI');
                }
                selectedApps.push(this.allBridgesInfo[index]);
                this.buildStatus[this.allBridgesInfo[index].uuid] = 'INITIATING';
                this.allBridgesInfo[index].selected = false;
            }
        }
        this.initValue['multiple'].buildnumber = this.initValue['multiple'].bridge ? this.chosenBuildNumber : this.chosenBuildNumberGatewayApi;
        this.initValue['multiple'].branch = this.initValue['multiple'].bridge ? this.chosenBranch : this.chosenBranchesGatewayApi;
        this.adminDashboardService
            .sendUpgradeValues(this.initValue['multiple'])
            .subscribe();
        this.initValue['multiple'] = {
            upgrade: false,
            onlyStart: false,
            onlyStop: false,
            bridge: false,
            gatewayAPI: false
        };
        this.adminDashboardService
            .triggerJenkinsBuildMultiple(selectedApps)
            .subscribe(() => {
                this.refresherSub = interval(this.refreshTime).subscribe(() => this.refreshData());
            });
        selectedApps = null;
    }

    installBridge() {
        if (this._getBuildStatusSubBridgeInstall) {
            this._getBuildStatusSubBridgeInstall.unsubscribe();
        }
        if (this._getBuildStatusSubGatewayApiInstall) {
            this._getBuildStatusSubGatewayApiInstall.unsubscribe();
        }
        this.refresherSub.unsubscribe();
        if (this.currentSelectedApplicationType === 'bridgeMT5') {
            this.buildStatus['KNpxGG8BaMiKehPvTVzR'] = 'INITATING';
            this.updateWorkdir =
                this.prepareWorkdirString(
                    this.bridgeHosts.filter(item => item.ip === this.initIPInstall)[0]
                        .workDir,
                    this.installBridgeName
                ) + ' Bridge';
        }
        if (this.currentSelectedApplicationType === 'bridge') {
            this.buildStatus['KNpxGG8BaMiKehPvTVzR'] = 'INITATING'; // bridge install status
            this.updateWorkdir = this.prepareWorkdirString(
                this.bridgeHosts.filter(item => item.ip === this.initIPInstall)[0]
                    .workDir,
                this.installBridgeName
            );
        }
        this.jenkisBuildRequestModel.buildNumber = this.installChosenBuildNumber;

        if (this.currentSelectedApplicationType === 'bridge' || this.currentSelectedApplicationType === 'bridgeMT5') {
            this.jenkisBuildRequestModel.bridgeType = this.currentSelectedApplicationType;
            this.jenkisBuildRequestModel.host = this.initIPInstall;
            this.jenkisBuildRequestModel.workdir = this.updateWorkdir;
            this.jenkisBuildRequestModel.bridgeName = this.installBridgeName;
            this.jenkisBuildRequestModel.pid = '1';
            this.jenkisBuildRequestModel.port = '1';
            this.jenkisBuildRequestModel.onlyStart = false;
            this.jenkisBuildRequestModel.onlyStop = false;
            this.jenkisBuildRequestModel.onlyConfigs = false;
            this.jenkisBuildRequestModel.uuid = '1';
            this.jenkisBuildRequestModel.branch = 'master';
            this.adminDashboardService
                .triggerBridgeInstall(this.jenkisBuildRequestModel)
                .subscribe(() => {
                    this.refresherSub = interval(this.refreshTime).subscribe(() =>
                        this.refreshData()
                    );
                });
        }
    }

    initAllBridgesInfo() {
        this.allBridgesInfo = [];
        this._retrieveBuildNumberFromELK = this.retrieveBuildNumberFromELK('KNpxGG8BaMiKehPvTVzR');
        this._retrieveBuildNumberFromELK = this.retrieveBuildNumberFromELK('INpxGG8BaMiKehPvSlxC');
        zip(
            this.adminDashboardService.getBridges(),
            this.adminDashboardService.getBuildNumbers('master'),
            this.adminDashboardService.getGatewayApiBuildNumbers('master'),
            this.adminDashboardService.getBranches(),
            this.adminDashboardService.getBranchesGatewayAPI(),
            this.statusPublisher.bridgesInfo,
            this.adminDashboardService.getAllBuildStatus()
        ).subscribe(data => {
            this.buildStatus['KNpxGG8BaMiKehPvTVzR'] = data[6][data[6].findIndex(el => el.uuid === 'KNpxGG8BaMiKehPvTVzR')].buildstatus;
            this.buildStatus['INpxGG8BaMiKehPvSlxC'] = data[6][data[6].findIndex(el => el.uuid === 'INpxGG8BaMiKehPvSlxC')].buildstatus;
            this.bridgeSelectedToDelete = data[0][Object.keys(data[0])[0]].name;
            data[2].builds = data[2].builds.slice(0, 10);
            this.respAllBridgesInfo = data[0];
            this.buildNumbers[0] = data[1].builds;
            this.buildNumbers[1] = data[1].builds;
            this.chosenBuildNumber = data[1].builds[0].number;
            this.installChosenBuildNumber = data[1].builds[0].number;
            this.buildNumbersGatewayApi[0] = data[2].builds;
            this.buildNumbersGatewayApi[1] = data[2].builds;
            this.chosenBuildNumberGatewayApi = data[2].builds[0].number;
            this.branches = data[3].branches;
            this.chosenBranch = this.branches[0];
            const masterBranchIndex = data[4].jobs.findIndex(
                item => item.name === 'master'
            );
            data[4].jobs[masterBranchIndex].name = data[4].jobs[0].name;
            data[4].jobs[0].name = 'master';
            this.branchesGatewayApi = data[4];
            this.chosenBranchesGatewayApi = this.branchesGatewayApi.jobs[0].name;
            for (const key of Object.keys(this.respAllBridgesInfo)) {
                const tempStatus = data[6][data[6].findIndex(el => el.uuid === key)];
                if (tempStatus != null) {
                    this.buildStatus[key] = tempStatus.buildstatus;
                } else {
                    this.buildStatus[key] = '-';
                }
                this.respAllBridgesInfo[key].gatewayapiversion = 'none';
                this._retrieveBuildNumberFromELK = this.retrieveBuildNumberFromELK(key);
                this.respAllBridgesInfo[key].selected = false;
                const stateAliveInfoIndex = data[5].findIndex(
                    item => item.uuid === key
                );
                if (stateAliveInfoIndex !== -1) {
                    this.respAllBridgesInfo[key].aliveState =
                        data[5][stateAliveInfoIndex].aliveState;
                }
                this.allBridgesInfo.push(this.respAllBridgesInfo[key]);
                this.loading = false;
            }
        });
    }

    refreshData() {
        this._retrieveBuildNumberFromELK = this.retrieveBuildNumberFromELK('KNpxGG8BaMiKehPvTVzR');
        this._retrieveBuildNumberFromELK = this.retrieveBuildNumberFromELK('INpxGG8BaMiKehPvSlxC');
        if (this._statusSub) {
            this._statusSub.unsubscribe();
        }
        zip(
            this.adminDashboardService.getBridges(),
            this.statusPublisher.bridgesInfo,
            this.adminDashboardService.getAllBuildStatus()
        ).subscribe(data => {
            if (Object.keys(data[0]).length !== (this.allBridgesInfo.length + this.sortedBridgesInfo.length)) {
                this.loading = true;
                this.initAllBridgesInfo();
            } else {
                for (const key of Object.keys(data[0])) {
                    this.buildStatus['KNpxGG8BaMiKehPvTVzR'] = data[2][data[2].findIndex(el => el.uuid === 'KNpxGG8BaMiKehPvTVzR')].buildstatus;
                    this.buildStatus['INpxGG8BaMiKehPvSlxC'] = data[2][data[2].findIndex(el => el.uuid === 'INpxGG8BaMiKehPvSlxC')].buildstatus;
                    const tempStatus = data[2][data[2].findIndex(el => el.uuid === key)];
                    if (tempStatus != null) {
                        this.buildStatus[key] = tempStatus.buildstatus;
                    } else {
                        this.buildStatus[key] = '-';
                    }
                    data[0][key].gatewayapiversion = 'none';
                    this._retrieveBuildNumberFromELK = this.retrieveBuildNumberFromELK(key);
                    const index = this.allBridgesInfo.findIndex(
                        item => item.uuid === data[0][key].uuid
                    );
                    if (this.allBridgesInfo[index]) {
                        data[0][key].selected = this.allBridgesInfo[index].selected;
                    }
                    if (index === -1) {
                    } else {
                        const stateAliveInfoIndex = data[1].findIndex(
                            item => item.uuid === key
                        );
                        if (stateAliveInfoIndex !== -1) {
                            data[0][key].aliveState = data[1][stateAliveInfoIndex].aliveState;
                        } else {
                            data[0][key].aliveState = State.Error;
                        }
                        data[0][key].selected = this.allBridgesInfo[index].selected;
                        this.allBridgesInfo[index] = data[0][key];
                    }
                }
            }
        });
    }

    prepareWorkdirString(workdir: string, bridgeName: string): string {
        return (
            workdir.replace(new RegExp(/ /, 'g'), '[[]]') +
            '\\' +
            bridgeName.replace(new RegExp(/ /, 'g'), '[[]]')
        ).replace(new RegExp(/\\/, 'g'), ' ');
    }

    allMultipleUpdate() {
        this.allMultipleUpdateVar = !this.allMultipleUpdateVar;
        for (const key of Object.keys(this.allBridgesInfo)) {
            this.allBridgesInfo[key].selected = this.allMultipleUpdateVar;
        }
    }

    setUpdateAndConfigsUpload() {
        if (this.initValue['multiple'].onlyStart || this.initValue['multiple'].onlyStop) {
            this.initValue['multiple'].upgrade = false;
            document.getElementById('bridge-title__checkbox-showup-update').style.cursor = 'not-allowed';
            document.getElementById('bridge-title__checkbox-showup-update').style.pointerEvents = 'none';
        } else {
            document.getElementById('bridge-title__checkbox-showup-update').style.cursor = 'pointer';
            document.getElementById('bridge-title__checkbox-showup-update').style.pointerEvents = 'auto';
        }
    }

    enableBridgeTitleOptions() {
        for (const key of Object.keys(this.allBridgesInfo)) {
            if (this.allBridgesInfo[key].selected) {
                return true;
            }
        }
        return false;
    }

    sortByBridgeName() {
        for (let index = this.allBridgesInfo.length - 1; 0 <= index; --index) {
            if (!this.allBridgesInfo[index].name.toLowerCase().includes(this.sortByName.nativeElement.value.toLowerCase()) || !this.allBridgesInfo[index].hostIp.includes(this.sortByIP.nativeElement.value)) {
                this.sortedBridgesInfo.push(this.allBridgesInfo[index]);
                this.allBridgesInfo.splice(index, 1);
            }
        }
        this.dt.sortOrder = -this.dt.sortOrder;
        this.dt.sortOrder = -this.dt.sortOrder;

        for (let sindex = this.sortedBridgesInfo.length - 1; 0 <= sindex; --sindex) {
            if (this.sortedBridgesInfo[sindex].name.toLowerCase().includes(this.sortByName.nativeElement.value.toLowerCase()) && this.sortedBridgesInfo[sindex].hostIp.includes(this.sortByIP.nativeElement.value)) {
                this.allBridgesInfo.push(this.sortedBridgesInfo[sindex]);
                this.sortedBridgesInfo.splice(sindex, 1);
            }
        }
        this.dt.sortOrder = -this.dt.sortOrder;
        this.dt.sortOrder = -this.dt.sortOrder;
    }

    clearSortOptions() {
        this.sortByName.nativeElement.value = '';
        this.sortByIP.nativeElement.value = '';
        for (let sindex = this.sortedBridgesInfo.length - 1; 0 <= sindex; --sindex) {
            this.allBridgesInfo.push(this.sortedBridgesInfo[sindex]);
            this.sortedBridgesInfo.splice(sindex, 1);
        }
        this.dt.sortOrder = -this.dt.sortOrder;
        this.dt.sortOrder = -this.dt.sortOrder;
    }

    setGatewayApiAndBridgeToFalse() {
        if (!(this.initValue['multiple'].update || this.initValue['multiple'].onlyStart || this.initValue['multiple'].onlyStop)) {
            this.initValue['multiple'].bridge = false;
            this.initValue['multiple'].gatewayAPI = false;
        }
    }

    enableUpgradeCheckBoxes() {
        for (const key of Object.keys(this.allBridgesInfo)) {
            if (this.allBridgesInfo[key].selected) {
                if (!(this.allBridgesInfo[key].workingDir && this.allBridgesInfo[key].workingDir.includes('\\Bridge'))) {
                    this.initValue['multiple'].gatewayAPI = false;
                    return false;
                }
            }
        }
        return true;
    }

    selectedInstanceToUpdate(uuid: string) {
        const indexOfSelectedBridge = this.allBridgesInfo.findIndex(
            item => item.uuid === uuid
        );
        return this.allBridgesInfo[indexOfSelectedBridge].selected;
    }

    selectInstanceToUpdate(uuid: string) {
        const indexOfSelectedBridge = this.allBridgesInfo.findIndex(
            item => item.uuid === uuid
        );
        this.allBridgesInfo[indexOfSelectedBridge].selected = !this.allBridgesInfo[indexOfSelectedBridge].selected;
        this.allMultipleUpdateVar = this.allBridgesInfo.every((element) => element.selected === true);
    }

    changedBranch() {
        this.adminDashboardService.getBuildNumbers(this.chosenBranch).subscribe(buildNumbers => {
            this.buildNumbers[1] = buildNumbers.builds;
            this.chosenBuildNumber = buildNumbers.builds[0].number;
        });
    }

    changedBranchGatewayApi() {
        this.adminDashboardService.getGatewayApiBuildNumbers(this.chosenBranchesGatewayApi).subscribe(buildNumbers => {
            buildNumbers.builds = buildNumbers.builds.slice(0, 10);
            this.buildNumbersGatewayApi[1] = buildNumbers.builds;
            this.chosenBuildNumberGatewayApi = buildNumbers.builds[0].number;
        });
    }

    retrieveBuildNumberFromELK(key: string): Subscription {
        return this.adminDashboardService
            .getBuildNumberFromJenkins(key)
            .subscribe(resp => {
                if (resp == null) {
                    this.buildNumberFromJenkinsList[key] = '';
                } else {
                    this.buildNumberFromJenkinsList[key] = resp;
                }
            });
    }

    initJenkinsBuildRequestModel() {
        this.jenkisBuildRequestModel = {
            bridgeType: '',
            host: '',
            workdir: '',
            bridgeName: '',
            pid: '',
            port: '',
            onlyStart: false,
            onlyStop: false,
            onlyConfigs: false,
            uuid: '',
            buildNumber: '',
            branch: ''
        };
    }

    deleteBridge() {
        let uuid = this.allBridgesInfo[this.allBridgesInfo.findIndex(el => el.name === this.confirmDelete)].uuid;
        let index = this.allBridgesInfo.indexOf(this.allBridgesInfo[this.allBridgesInfo.findIndex(el => el.name === this.confirmDelete)], 0);
        this.allBridgesInfo.splice(index, 1);
        this.adminDashboardService.deleteBridgeFromBMAndMoveToUnusedDirectory(uuid)
            .subscribe(() => {
                this.confirmDelete = null;
                uuid = null;
                index = null;
            });
    }
}
