import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BridgeSettings, UserDto} from '../commons/dto/user-dto';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {NewUserModel} from './new-user-model';
import {BridgeSimpleStatus} from '../all-bridges/interfaces/bridge-simple-status';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) {}

    public modifyOrderBookSettings(settings: BridgeSettings): Observable<BridgeSettings> {
        return this.http.put<BridgeSettings>(`${environment.hostUrl}/user/settings/bridge`, settings);
    }

    public getAll(): Observable<UserDto[]> {
        return this.http.get<UserDto[]>(`${environment.hostUrl}/users`);
    }

    public new(request: NewUserModel): Observable<unknown> {
        return this.http.post(`${environment.hostUrl}/register`, request);
    }

    public updateUserSettings(userName: string, user: UserDto): Observable<unknown> {
        return this.http.post(`${environment.hostUrl}/user/${userName}`, user);
    }

    public remove(name: string): Observable<unknown> {
        return this.http.delete(`${environment.hostUrl}/users/${name}`);
    }

    public setSettings(request: BridgeSimpleStatus): Observable<unknown> {
        return this.http.put(`${environment.hostUrl}/change-settings/${request.uuid}`, {
            uuid: request.uuid,
            owner: request.owner,
            hedgeMonitorEnabled: request.hedgeMonitorEnabled,
            hedgeMonitorRefreshPeriod: request.hedgeMonitorRefreshPeriod,
            failoverSlave: request.failoverSlave,
            failoverBridgeUuid: request.failoverBridgeUuid,
            failoverTimeout: request.failoverTime,
            autoHedgeEnabled: request.autoHedgeEnabled,
            rabbitPersistenceEnabled: request.rabbitPersistenceEnabled,
            sendEmailToUser: request.sendEmailToUser,
            sendEmailToAdmin: request.sendEmailToAdmin,
            emailService: null
        });
    }

    public changePassword(user: UserDto): Observable<unknown> {
        return this.http.post(`${environment.hostUrl}/user/change-password`, user);
    }

    public changeFilterInterval(filterMap: { [key: string]: { [key: string]: number } }, name: string) {
        return this.http.post(`${environment.hostUrl}/user/change-filter-interval/${name}`, filterMap);
    }

    public getFilterInterval(name: String): Observable<{ [key: string]: { [key: string]: number } }> {
        return this.http.get<{ [key: string]: { [key: string]: number } }>(`${environment.hostUrl}/user-interval-info/${name}`);
    }
}
