import {SelectItem} from 'primeng/api';
import {ReportFiltersDetailed} from '../services/reports.service';

export class InitializationDataHelper {
  public static filters: ReportFiltersDetailed = {
    uuid: '',
    from: new Date(Date.now() - 86400000),
    to: new Date(),
    bookType: 'BOOK_BOTH',
    orderStatus: 'BOTH',
  };

  public static bookTypes: SelectItem[] = [
    { label: 'Both', value: 'BOOK_BOTH' },
    { label: 'A-Book', value: 'ABOOK' },
    { label: 'B-Book', value: 'BBOOK' },
  ];

  public static statuses: SelectItem[] = [
    { label: 'Both', value: 'BOTH' },
    { label: 'Error', value: 'ERROR' },
    { label: 'Success', value: 'SUCCESS' },
  ];
}
