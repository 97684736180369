import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {BridgeLogs} from '../commons/model/logs/bridge-logs';
import {Observable} from 'rxjs';
import {BridgeLogLevel} from '../commons/model/logs/bridge-log-level';
import {BridgeManagerLogs} from '../commons/model/logs/bridge-manager-logs';

@Injectable({
    providedIn: 'root'
})
export class CurrentLogsService {

    readonly currentBridgeLogsUrl = '/current-bridge-logs';

    constructor(private http: HttpClient) {
    }

    getCurrentLogs(uuid: string): Observable<BridgeLogs> {
        const url = environment.hostUrl + this.currentBridgeLogsUrl + '/' + uuid;
        return this.http.get<BridgeLogs>(url);
    }

    getBridgeLogLevel(uuid: string): Observable<BridgeLogLevel> {
        return this.http.get<BridgeLogLevel>(`${environment.hostUrl}${this.currentBridgeLogsUrl}/${uuid}/log-level`);
    }

    changeLogLevel(logLevel: BridgeLogLevel, uuid: string): Observable<any> {
        const url = environment.hostUrl + this.currentBridgeLogsUrl + '/change-log-level/' + uuid;
        return this.http.post(url, logLevel);
    }

    getCurrentBMLogs(): Observable<BridgeManagerLogs> {
        const url = environment.hostUrl + this.currentBridgeLogsUrl + '/bm-logs';
        return this.http.get<BridgeManagerLogs>(url);
    }

    getCurrentAutoHedgeLogs(uuid: string): Observable<BridgeManagerLogs> {
        const url = environment.hostUrl + this.currentBridgeLogsUrl + '/auto-hedge/' + uuid;
        return this.http.get<BridgeManagerLogs>(url);
    }

    getBridgeHedgeLogs(uuid: string): Observable<BridgeLogs> {
        return this.http.get<BridgeLogs>(`${environment.hostUrl}${this.currentBridgeLogsUrl}/hedging/${uuid}`);
    }
}
