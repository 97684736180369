import { Component, Input, OnChanges } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { TicksHttpService } from '../../services';
import { SymbolTickMonitoring } from 'src/app/details-bridge/commons/interfaces/symbol-tick-monitoring.interface';
import { BrokerSession } from 'src/app/details-bridge/commons/model/status/broker-session';

@Component({
  selector: 'app-ticks-monitoring-dialog',
  templateUrl: './ticks-monitoring-dialog.component.html',
  styleUrls: ['./ticks-monitoring-dialog.component.scss'],
})
export class TicksMonitoringDialogComponent implements OnChanges {
  @Input() bridgeUuid: string;
  @Input() session: BrokerSession;

  public data$: Observable<SymbolTickMonitoring[]>;

  constructor(private ticksHttp: TicksHttpService) {}

  ngOnChanges(): void {
    this.data$ = this.ticksHttp.getSymbolTickMonitoring(
      this.bridgeUuid,
      this.session.sessionName
    );
  }

  handleSymbolMonitoringChange(
    event: Event,
    symbolId: number,
    isEnabled: boolean
  ) {
    if (isEnabled) {
      this.ticksHttp
        .enableSymbolTickMonitoring(this.bridgeUuid, `${symbolId}`)
        .pipe(
          catchError((err) => {
            event.preventDefault();
            (event.target as any).checked = false;
            return throwError(err);
          }),
          take(1)
        )
        .subscribe();
      return;
    }
    this.ticksHttp
      .disableSymbolTickMonitoring(this.bridgeUuid, `${symbolId}`)
      .pipe(
        catchError((err) => {
          event.preventDefault();
          (event.target as any).checked = true;
          return throwError(err);
        }),
        take(1)
      )
      .subscribe();
  }
}
