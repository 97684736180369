import {ConfirmationService, SelectItem} from 'primeng/primeng';
import {OrderBookService} from '../market-depth/order-book.service';
import {LoaderService} from '../commons/loader.service';
import {CallWrapperService} from '../commons/call-wrapper.service';
import {AuthenticationService} from '../commons/security/authentication.service';
import {UserSettingsService} from '../commons/user-settings.service';
import {WSConnectionInfoService} from '../commons/wsconnection-info.service';
import {NotificationsService} from '../commons/notifications.service';
import {BridgesPublisherService} from '../all-bridges/services/bridges-publisher.service';
import {SelectedBridgeService} from '../market-depth/selected-bridge.service';

import {BridgeSimpleStatus} from '../all-bridges/interfaces/bridge-simple-status';
import {BridgeSettings} from '../commons/dto/user-dto';
import {MarketWatchRow} from './model/market-watch-row';
import {MarketWatchRowDisplay} from './model/market-watch-row-display';
import {genMWRowKey} from '../utils/utils';
import {UserService} from '../users/user.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription, zip} from 'rxjs';
import {switchMap, take, tap} from 'rxjs/operators';

@Component({
    selector: 'app-market-watch',
    templateUrl: './market-watch.component.html',
    styleUrls: ['./market-watch.component.scss'],
    providers: [CallWrapperService, SelectedBridgeService]
})
export class MarketWatchComponent implements OnInit, OnDestroy {

    showOrderBookSelector = false;
    bridges: SelectItem[] = [];
    positions: MarketWatchRowDisplay[] = [];
    originalPositions: MarketWatchRow[] = [];
    showEditInstrument = false;
    symbolAndSessionInfo: any[] = [];
    filterMap: { [key: string]: { [key: string]: number } } = {};
    loading: boolean;
    noSymbolsSelected: boolean;
    userName: string;

    private authSub: Subscription = new Subscription();
    private subscription: Subscription = new Subscription();
    private timerSub: Subscription = new Subscription();

    constructor(private loader: LoaderService,
                private callWrapper: CallWrapperService,
                private authenticationService: AuthenticationService,
                private userSettingsService: UserSettingsService,
                private wsConnInfo: WSConnectionInfoService,
                private notifications: NotificationsService,
                private confirmationService: ConfirmationService,
                private statusPublisher: BridgesPublisherService,
                private selectedBridgeService: SelectedBridgeService,
                private orderBookService: OrderBookService,
                private userService: UserService
    ) {
    }

    ngOnInit() {
        this.timerSub.add(interval(1000).subscribe(val => {
            this.positions.forEach(el => el.lastUpdateInMillis++);
            this.sort();
        }));

        this.authSub.add(
            zip(this.authenticationService.userInfo, this.statusPublisher.bridgesInfo).pipe(
                take(1),
                tap(data => this.userName = data[0].name),
                tap(data => this.bridges = data[1].map(el => this.mapToSelectItem(el))
                    .sort((a, b) => a.label.localeCompare(b.label))),
                switchMap(data => this.userService.getFilterInterval(data[0].name))
            ).subscribe(filterInterval => {
                this.filterMap = filterInterval;
                if (this.bridges.length > 0) {
                    this.selectedBridgeService.sendUuid(this.bridges[0].value);
                }
                this.subToOrders();
            }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.authSub.unsubscribe();
        this.timerSub.unsubscribe();
    }

    setNewSymbols(bridgeSettings: BridgeSettings) {
        this.callWrapper.withLoader(this.userSettingsService.setBridgeSettings(bridgeSettings),
            () => {
                this.showOrderBookSelector = false;
                this.notifications.showSuccessMessage('Market watch configuration submitted');
                this.noSymbolsSelected = this.checkIsSymbolNotSelected(bridgeSettings);
                this.showLoader();
            });
    }

    displaySymbols4ChosenBridge(event) {
        this.selectedBridgeService.sendUuid(event.value);
        this.showLoader();
    }

    showEditInstrumentDialog() {
        this.showEditInstrument = true;
        this.symbolAndSessionInfo = this.mapToFilterIntervalSymbolInfo(this.positions);
    }

    updateFilterMap(event) {
        this.callWrapper.withLoader(this.userService.changeFilterInterval(event, this.userName),
            () => {
                this.filterMap[this.selectedBridgeService.uuid] = event[this.selectedBridgeService.uuid];
                this.filterMap = {...this.filterMap};
                this.showEditInstrument = false;
                this.notifications.showSuccessMessage('Market watch filter interval submitted');
                this.showLoader();
            });
    }

    private subToOrders() {
        this.subscription.add(this.wsConnInfo.onConnected()
            .pipe(
                switchMap(() => this.selectedBridgeService.bridgeChange),
                switchMap(data => this.userSettingsService.getBridgeSettings(data)),
                switchMap(settings => {
                    this.positions = [];
                    this.noSymbolsSelected = this.checkIsSymbolNotSelected(settings);
                    this.showLoader();
                    return this.createRequest(settings, this.selectedBridgeService.uuid);
                })
            ).subscribe(list => {
                if (this.originalPositions.length === 0) {
                    this.originalPositions = list;
                }
                if (!this.noSymbolsSelected) {
                    this.showLoader();
                }
                this.updateList(list);
            }));
    }

    private createRequest(symbols: BridgeSettings, uuid: string) {
        return this.orderBookService.watchToNewMarketWatchRow({
            symbols: symbols,
            uuid: uuid,
            count: 1000,
            mwDepth: false
        });
    }

    private mapToSelectItem(el: BridgeSimpleStatus): SelectItem {
        return {label: el.name, value: el.uuid};
    }

    private updateList(list: MarketWatchRow[]) {
        list.forEach(item => {
            this.updateRow(item);
        });
    }

    private mapRowToDisplay(item: MarketWatchRow): MarketWatchRowDisplay {
        item.session = item.session !== '' ? item.session : item.symbol;
        return {
            session: item.session,
            symbol: item.symbol,
            bid: item.bid,
            ask: item.ask,
            lastUpdateInMillis: item.lastUpdateInMillis,
            key: genMWRowKey(item),
            isUpdated: false
        };
    }

    private updateRow(item: MarketWatchRow): void {
        const originalIdx = this.originalPositions.findIndex(el => el.symbol === item.symbol);
        const newItem = this.mapRowToDisplay(item);
        const idx = this.positions.findIndex(el => el.symbol === item.symbol);

        if (!this.filterMap[this.selectedBridgeService.uuid]) {
            this.prepareTable(newItem);
        }
        if (originalIdx !== -1) {
            if (item.lastUpdateInMillis > 0) {
                this.originalPositions[originalIdx].lastUpdateInMillis = item.lastUpdateInMillis;
            }
        } else {
            this.originalPositions.push(item);
        }

        newItem.lastUpdateInMillis = 0;
        if (idx !== -1) {
            newItem.isUpdated = !(newItem.ask === '0' && newItem.bid === '0');
            this.positions[idx] = newItem;
        } else {
            this.filterMap = this.initIntervalMap(this.filterMap, newItem);
            this.positions.push(newItem);
        }
    }

    private mapToFilterIntervalSymbolInfo(list: MarketWatchRowDisplay[]): any[] {
        return list.map(el => {
            return {
                symbol: el.symbol,
                session: el.session,
                key: el.key,
            };
        });
    }

    private sort(): void {
        const positionsByFilterIntervalMap: { [key: string]: MarketWatchRowDisplay[] } = this.getPositionsByFilterIntervalMap();
        const sorted2DArray = Object.values(positionsByFilterIntervalMap).map(el => {
            return el.sort((left, right) => {
                if (left.lastUpdateInMillis < right.lastUpdateInMillis) {
                    return 1;
                }
                if (left.lastUpdateInMillis > right.lastUpdateInMillis) {
                    return -1;
                }
                return 0;
            });
        });
        let result = [];
        sorted2DArray.forEach(element => result = result.concat(element));
        this.positions = result;
    }

    private getPositionsByFilterIntervalMap(): { [key: string]: MarketWatchRowDisplay[] } {
        const positionsByFilterIntervalMap: { [key: string]: MarketWatchRowDisplay[] } = {};
        this.positions.forEach(el => {
            const filterInterval = this.filterMap[this.selectedBridgeService.uuid][genMWRowKey(el)];
            let key;
            if (el.lastUpdateInMillis < filterInterval) {
                key = -1;
            } else if (el.lastUpdateInMillis > filterInterval) {
                key = 1;
            } else {
                key = 0;
            }
            if (!positionsByFilterIntervalMap[key]) {
                positionsByFilterIntervalMap[key] = [];
            }
            positionsByFilterIntervalMap[key].push(el);
        });
        return positionsByFilterIntervalMap;
    }

    private prepareTable(item: MarketWatchRowDisplay) {
        const symbolMap: { [key: string]: number } = {};
        const key = genMWRowKey(item);
        symbolMap[key] = 5;
        this.filterMap[this.selectedBridgeService.uuid] = symbolMap;
    }

    private initIntervalMap(filterInterval: { [p: string]: { [p: string]: number } }, item: MarketWatchRowDisplay | MarketWatchRow) {
        const uuid = this.bridges.length > 1 ? this.selectedBridgeService.uuid : this.bridges[0].value;
        const symbolMap: { [key: string]: number } = {};
        const key = genMWRowKey(item);
        if (!filterInterval[uuid] || !filterInterval[uuid][key]) {
            symbolMap[key] = 5;
        } else {
            symbolMap[key] = filterInterval[uuid][key];
        }
        filterInterval[uuid][key] = symbolMap[key];
        return filterInterval;
    }

    private showLoader(): void {
        this.loading = this.positions.length === 0 && !this.noSymbolsSelected;
    }

    private checkIsSymbolNotSelected(settings: BridgeSettings): boolean {
        if (settings) {
            const noBrokerSymbolsSelected = settings.brokerNewMwSettings.map(el => el.obs.length === 0);
            const noLpSymbolsSelected = settings.lpNewMwSettings.map(el => el.obs.length === 0);
            const noSymbolsBroker = noBrokerSymbolsSelected.find(el => el === false);
            const noSymbolsLp = noLpSymbolsSelected.find(el => el === false);
            return noSymbolsBroker === undefined && noSymbolsLp === undefined;
        } else {
            return true;
        }
    }
}
