import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TextDecoratorService {

    constructor() {
    }

    decorateFragment(lines: string[], snippet: string, styleClass: string): string[] {
        const pattern = new RegExp(snippet, 'gi');
        return lines.map(function (line) {
            const regExpMatchArray = line.match(pattern);
            if (regExpMatchArray) {
                regExpMatchArray.forEach(matchedFragment => {
                    line = line.replace(matchedFragment, `<span class="${styleClass}">${matchedFragment}</span>`);
                });
            }
            return line;
        });
    }

    decorateLine(lines: string[], snippet: string, styleClass: string): string[] {
        return lines.map(line => {
            const pattern = new RegExp(snippet, 'g');
            if (pattern.test(line)) {
                return `<span class="${styleClass}">${line}</span>`;
            } else {
                return line;
            }
        });
    }

    decorateLogs(bridgeLogs: string[]): string[] {
        let decoratedLogs = this.decorateLine(bridgeLogs, ' ERROR ', 'error-log');
        decoratedLogs = this.decorateLine(decoratedLogs, ' FATAL ', 'fatal-log');
        decoratedLogs = this.decorateLine(decoratedLogs, ' WARN ', 'warn-log');
        decoratedLogs = this.decorateLine(decoratedLogs, ' DEBUG ', 'debug-log');

        return decoratedLogs;
    }
}
