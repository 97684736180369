import {Attribute, Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
    selector: '[appMaxValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxValidatorDirective), multi: true}
    ]
})
export class MaxValidatorDirective implements Validator {
    constructor(@Attribute('appMaxValidator') public validateMax: string) {
    }

    validate(input: AbstractControl): { [key: string]: any } {
        const value = input.value as number;
        if (value > +this.validateMax) {
            return {
                validateMax: true
            };
        }

        return null;

    }
}
