import {Attribute, Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
    selector: '[appMinEqualValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => MinEqualValidatorDirective), multi: true}
    ]
})
export class MinEqualValidatorDirective implements Validator {

    constructor(@Attribute('appMinEqualValidator') public validateMinEqual: string) {
    }

    validate(input: AbstractControl): { [key: string]: any } {
        const value = input.value as number;
        if (value <= +this.validateMinEqual) {
            return {
                validateMinEqual: true
            };
        }

        return null;

    }

}
