import { Component } from '@angular/core';
import { CallWrapperService } from '../commons/call-wrapper.service';

@Component({
  selector: 'app-bm-logs',
  templateUrl: './bm-logs.component.html',
  styleUrls: ['./bm-logs.component.scss'],
  providers: [CallWrapperService]
})
export class BmLogsComponent {}
