import {Component, OnDestroy, OnInit} from '@angular/core';
import {LPSymbolsService} from '../commons/lp-symbols.service';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../../commons/notifications.service';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {LPSession} from '../commons/model/symbol/lp/l-p-session';

@Component({
    selector: 'app-lp-symbols',
    templateUrl: './lp-symbols.component.html',
    styleUrls: ['./lp-symbols.component.scss'],
    providers: [CallWrapperService]
})
export class LpSymbolsComponent implements OnInit, OnDestroy {

    sessions: LPSession[] = null;
    uuid: string;
    private _subscription: Subscription;


    constructor(private lpSymbolService: LPSymbolsService,
                private notifications: NotificationsService,
                private callWrapper: CallWrapperService) {
    }

    ngOnInit(): void {
        this._subscription = this.callWrapper.withStableUuid(
            uuid => {
                this.uuid = uuid;
                return this.lpSymbolService.getSessions(this.uuid);
            },
            list => this.sessions = list);
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    sessionByName(index: number, item: LPSession): string {
        return item.sessionName;
    }

    private refreshSessions() {
        this.callWrapper.withLoader(this.lpSymbolService.refreshSessions(this.uuid),
            data => {
                this.sessions = data;
                this.notifications.showSuccessMessage('Symbols refreshed');
            });
    }
}
