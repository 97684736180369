import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SetupService {

    constructor(private http: HttpClient) {
    }

    private _url = `${environment.hostUrl}/setup`;

    isAdminExist(): Observable<boolean> {
        return this.http.get(this._url).pipe(
            map(() => true),
            catchError(err => {
                if (err.status === 404) {
                    return of(false);
                } else {
                    throw err;
                }
            })
        );
    }

    setConfiguration(request: { password: string }): Observable<void> {
        return this.http.post(this._url, request).pipe(map(() => {
        }));
    }
}
