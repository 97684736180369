import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {PriceImprovementRuleDisplay} from './model/price-improvement-rule-display';
import {emptyPriceRule, PriceImprovementRule} from './model/price-improvement-rule';
import {NotificationsService} from '../../commons/notifications.service';
import {ConfirmationService} from 'primeng/api';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {SymbolsChangeService} from '../services/symbols-change.service';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {delay, map} from 'rxjs/operators';
import {PriceImprovementService} from './price-improvement.service';
import {RulesRowReorder} from '../commons/abstract/rules-row-reorder.abstract';
import {PriceImprovementRules} from './model/price-improvement-rules';

@Component({
    selector: 'app-price-improvement-rules',
    templateUrl: './price-improvement-rules.component.html',
    styleUrls: ['./price-improvement-rules.component.scss']
})
export class PriceImprovementRulesComponent extends RulesRowReorder<PriceImprovementRules, PriceImprovementRule, PriceImprovementRuleDisplay> implements OnInit, OnDestroy {
    rules: PriceImprovementRuleDisplay[];
    isLoading = true;
    selectedRule: PriceImprovementRule;
    newRule: PriceImprovementRule;
    displaySymbolChangeNotification = false;

    private _subscription: Subscription;
    private _routerSub: Subscription;

    constructor(protected ruleService: PriceImprovementService,
                private notifications: NotificationsService,
                private confirmationService: ConfirmationService,
                protected callWrapper: CallWrapperService,
                private symbolsChange: SymbolsChangeService,
                private router: Router) {
      super(callWrapper, ruleService);
    }

    ngOnInit() {
        this._routerSub = this.router
            .events
            .subscribe((routerEvent: RouterEvent) => this.checkEvent(routerEvent));

        this._subscription = this.callWrapper.withStableUuid(
            uuid => this.ruleService.getRules(uuid).pipe(map(value => value.rules)),
            list => {
                this.isLoading = false;
                this.rules = this.mapToDisplay(list);
            });

        this._subscription = this.symbolsChange
            .tabChanged.subscribe(data => {
                if (data['tabChanged']) {
                    this.symbolsChange.setShowDialog(this.displaySymbolChangeNotification, 'price');
                    this.displaySymbolChangeNotification = false;
                }
            });
    }

    ngOnDestroy(): void {
        this._routerSub.unsubscribe();
        this._subscription.unsubscribe();
        this.symbolsChange.setShowDialog(this.displaySymbolChangeNotification, 'price');
    }

    checkEvent(routerEvent: RouterEvent): any {
        if (routerEvent instanceof NavigationStart) {
            this.symbolsChange.setShowDialog(this.displaySymbolChangeNotification, 'price');
            this.displaySymbolChangeNotification = false;
        }
    }

    showNewRuleDialog() {
        this.newRule = emptyPriceRule();
    }

    applyChanges() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.applyChanges(uuid).pipe(delay(500)),
            () => this.notifications.showSuccessMessage('Changes loaded!')
        );

        this.displaySymbolChangeNotification = false;
    }

    refreshRules() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.refreshRules(uuid),
            rules => {
                this.rules = this.mapToDisplay(rules.rules);
                this.notifications.showSuccessMessage('Price rules refreshed!');
            });
    }

    updateRule(rule: PriceImprovementRule) {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.update(uuid, rule),
            () => {
                this.updateSymbols();
                this.closeUpdateRuleDialog();
                this.displaySymbolChangeNotification = true;
                this.notifications.showSuccessMessage('Price rule updated.', 'Please remember to apply the changes in the bridge!');
            });
    }

    deleteRule(priceRule: PriceImprovementRule) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want remove this rule?',
            accept: () => {
                this.callWrapper.withLoaderAndSingleUuid(
                    uuid => this.ruleService.delete(uuid, priceRule.uuid),
                    () => {
                        this.updateSymbols();
                        this.closeUpdateRuleDialog();
                        this.displaySymbolChangeNotification = true;
                        this.notifications.showSuccessMessage('Price rule deleted.', 'Please remember to apply the changes in the bridge!');
                    }
                );
            }
        });
    }

    addNewRule(newRule: PriceImprovementRule) {
        this.callWrapper.withLoaderAndSingleUuid(uuid => this.ruleService.create(uuid, {
            ...newRule,
            weight: Math.max(...this.rules.map(o => o.weight)) + 1
        }), () => {
                this.updateSymbols();
                this.closeNewRuleDialog();
                this.displaySymbolChangeNotification = true;
                this.notifications.showSuccessMessage('New rule created.', 'Please remember to apply the changes in the bridge!');
            });
    }

    closeUpdateRuleDialog() {
        this.selectedRule = null;
    }

    closeNewRuleDialog() {
        this.newRule = null;
    }

    private updateSymbols() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.getRules(uuid),
            results => this.rules = this.mapToDisplay(results.rules)
        );
    }

    private mapToDisplay(priceImprovementRules: PriceImprovementRule[]) {
        if (!priceImprovementRules) {
            return null;
        }

        return priceImprovementRules.map(el2 => {
            return {
                uuid: el2.uuid,
                brokerName: el2.brokerName.split(','),
                instrument: el2.instrument,
                groupName: el2.groupName,
                account: el2.account,
                weight: el2.weight
            };
        });
    }
}
