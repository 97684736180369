import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthenticationService} from 'src/app/commons/security/authentication.service';
import {Subscription} from 'rxjs';
import {UserDto} from '../commons/dto/user-dto';
import {UserService} from '../users/user.service';
import {NotificationsService} from '../commons/notifications.service';

@Component({
    selector: 'app-change-user-password',
    templateUrl: './change-user-password.component.html',
    styleUrls: ['./change-user-password.component.scss']
})
export class ChangeUserPasswordComponent implements OnInit {

    private subscription: Subscription;

    @Input()
    currentUser: UserDto;

    @Output()
    submit = new EventEmitter<boolean>();

    constructor(private authService: AuthenticationService,
                private userService: UserService,
                private notificationService: NotificationsService) {

    }

    ngOnInit() {
        this.subscription = this.authService.userInfo.subscribe(
            data => {
                this.currentUser = data;
            });
    }

    changePassword() {
        this.userService.changePassword(this.currentUser).subscribe(() => {
                this.notificationService.showSuccessMessage('Password changed succesfully!');
                this.submit.emit(true);
            },
            err => this.notificationService.showConnectionErrorMessage(err + ' Could not change password!'),
        );
    }
}
