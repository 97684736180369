export enum State {
    New,
    Active,
    Inactive,
    Error,
    NotConfigured,
    FailoverActive,
    FailoverConflict,
    FailoverStarted
}
