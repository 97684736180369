import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment.prod';
import {BRIDGE_URL} from '../../utils/constants';
import {GuaranteedRules} from './model/guaranteed-rules';
import {GuaranteedRule} from './model/guaranteed-rule';
import {RuleService} from '../commons/interfaces/rule-service.interface';

@Injectable({
    providedIn: 'root'
})
export class GuaranteedService implements RuleService<GuaranteedRules, GuaranteedRule> {

    readonly PATH = '/rules/guaranteed-tpsl';

    constructor(private http: HttpClient) {
    }

    getRules(uuid: string): Observable<GuaranteedRules> {
        return this.http.get<GuaranteedRules>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}`);
    }

    applyChanges(uuid: string): Observable<void> {
        return this.http.post<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/reload`, null);
    }

    refreshRules(uuid: string): Observable<GuaranteedRules> {
        return this.http.post<GuaranteedRules>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/refresh`, null);
    }

    delete(uuid: string, id: string): Observable<void> {
        return this.http.delete<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/${id}`);
    }

    update(uuid: string, model: GuaranteedRule): Observable<void> {
        return this.http.put<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/${model.uuid}`, model);
    }

    create(uuid: string, model: GuaranteedRule): Observable<void> {
        return this.http.post<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}`, model);
    }
}
