import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {ChartDateRange} from '../interfaces';
import {DateLabels} from '../helpers/date-labels';

@Injectable()
export class DateStoreService {
  public dateLabels = DateLabels.getDateLabels();

  public readonly selectedBridge$: Observable<string>;
  public readonly revenueSummaryDate$: Observable<ChartDateRange>;
  public readonly totalSummaryDate$: Observable<ChartDateRange>;
  public readonly clientsDate$: Observable<ChartDateRange>;
  public readonly volumeDate$: Observable<ChartDateRange>;
  public readonly tradeDate$: Observable<ChartDateRange>;
  public readonly tableData$: Observable<ChartDateRange>;

  private readonly today = {date: {from: this.dateLabels[0].value, to: this.dateLabels[0].value}, isLineChart: false};

  private readonly _selectedBridgeSubject = new ReplaySubject<string>(1);
  private readonly _revenueSummaryDateSubject = new BehaviorSubject<ChartDateRange>(this.today);
  private readonly _totalSummaryDateSubject = new BehaviorSubject<ChartDateRange>(this.today);
  private readonly _clientsDateSubject = new BehaviorSubject<ChartDateRange>(this.today);
  private readonly _volumeDateSubject = new BehaviorSubject<ChartDateRange>(this.today);
  private readonly _tradeDateSubject = new BehaviorSubject<ChartDateRange>(this.today);
  private readonly _tableDateSubject = new BehaviorSubject<ChartDateRange>(this.today);

  constructor() {
    this.selectedBridge$ = this._selectedBridgeSubject.asObservable();
    this.revenueSummaryDate$ = this._revenueSummaryDateSubject.asObservable();
    this.totalSummaryDate$ = this._totalSummaryDateSubject.asObservable();
    this.clientsDate$ = this._clientsDateSubject.asObservable();
    this.volumeDate$ = this._volumeDateSubject.asObservable();
    this.tradeDate$ = this._tradeDateSubject.asObservable();
    this.tableData$ = this._tableDateSubject.asObservable();
  }

  public setSelectedBridge(bridgeName: string): void {
    this._selectedBridgeSubject.next(bridgeName);
  }

  public setRevenueSummaryDate(revenueSummaryDate: ChartDateRange): void {
    this._revenueSummaryDateSubject.next(revenueSummaryDate);
  }

  public setTotalSummaryDate(totalSummaryDate: ChartDateRange): void {
    this._totalSummaryDateSubject.next(totalSummaryDate);
  }

  public setClientsDate(clientsDate: ChartDateRange): void {
    this._clientsDateSubject.next(clientsDate);
  }

  public setVolumeDate(volumeDate: ChartDateRange): void {
    this._volumeDateSubject.next(volumeDate);
  }

  public setTradeDate(tradeDate: ChartDateRange): void {
    this._tradeDateSubject.next(tradeDate);
  }

  public setTableDate(tableDate: ChartDateRange): void {
    this._tableDateSubject.next(tableDate);
  }
}
