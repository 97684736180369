import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {NotificationsService} from '../notifications.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ErrorManagerService {
    constructor(private notifications: NotificationsService, private router: Router) {}

    handle(error: any, item?: {
        onDone?: () => void,
        httpErrorMessage?: string,
        badRequestMessage?: string,
        conflictMessage?: string,
        onOtherError?: (error: Error) => void
    }): void {
        if (error instanceof HttpErrorResponse) {
            let detailErrorMessage = null;
            if (error.error.detail) { detailErrorMessage = error.error.detail; }

            if (error.status === 401) {
                this.handleForbiddenRequest();
            } else if (error.status === 400) {
                let message = null;
                if (item && item.badRequestMessage) {
                    message = item.badRequestMessage;
                }
                this.notifications.showConnectionErrorMessage(detailErrorMessage || message);
            } else if (error.status === 409) {
                let message = null;
                if (item && item.conflictMessage) {
                    message = item.conflictMessage;
                }
                this.notifications.showItemExistsErrorMessage(detailErrorMessage || message);
            } else {
                let message = null;
                if (item && item.httpErrorMessage) {
                    message = item.httpErrorMessage;
                }
                this.notifications.showConnectionErrorMessage(detailErrorMessage || message);
            }

            this._extraErrorMessage(error);

        } else {
            if (item && item.onOtherError) {
                item.onOtherError(error);
            }
        }

        if (item && item.onDone) {
            item.onDone();
        }
    }

    handleForbiddenRequest(): void {
        this.router.navigateByUrl('/login');
        this.notifications.showSessionExpiredMessage();
    }

    handleOtherHttpError(error: HttpErrorResponse): void {
        error.error.detail
            ? this.notifications.showConnectionErrorMessage(error.error.detail)
            : this.notifications.showConnectionErrorMessage(error);
        this._extraErrorMessage(error);
    }

    private _extraErrorMessage(error: HttpErrorResponse): void {
        if (error.error.bridgeResponse && error.error.bridgeResponse.detail) {
            this.notifications.showConnectionErrorMessage(error.error.bridgeResponse.detail);
        }
    }
}
