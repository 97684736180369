import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {BridgeDetailedStatusService} from './commons/bridge-detailed-status.service';
import {AuthenticationService} from '../commons/security/authentication.service';
import {BridgeDetailsPublisherService} from './commons/bridge-details-publisher.service';
import {filter, map, switchMap, take} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {LoaderService} from '../commons/loader.service';
import {ErrorManagerService} from '../commons/error/error-manager.service';
import {NotificationsService} from '../commons/notifications.service';
import {SymbolsChangeService} from './services/symbols-change.service';
import {CallWrapperService} from '../commons/call-wrapper.service';
import {SessionRestartServiceService} from './services/session-restart.service';

@Component({
    selector: 'app-bridge-details',
    templateUrl: './bridge-details.component.html',
    styleUrls: ['./bridge-details.component.scss'],
    providers: [BridgeDetailsPublisherService,
        SymbolsChangeService,
        CallWrapperService,
        SessionRestartServiceService]
})
export class BridgeDetailsComponent implements OnInit, OnDestroy {

    displaySymbolChangeNotification = false;
    name = '';
    session = '';
    bridge = '';
    isFromHM = false;
    isFromExposureTab = false;

    private _subscription: Subscription;
    private _symbolsSub: Subscription;
    private _routeSub: Subscription;

    constructor(private activatedRoute: ActivatedRoute,
                private bridgeDetailedStatusService: BridgeDetailedStatusService,
                private authService: AuthenticationService,
                private router: Router,
                private bridgeDetailsPublisher: BridgeDetailsPublisherService,
                private loader: LoaderService,
                private errorManager: ErrorManagerService,
                private notifications: NotificationsService,
                private symbolsChange: SymbolsChangeService,
                private restartSessionService: SessionRestartServiceService,
    ) {

        this._subscription = this.activatedRoute
            .params
            .pipe(switchMap(param => this.getBridgeDetailsInCycle(param)))
            .subscribe(item => this.bridgeDetailsPublisher.set(item),
                err => console.error(err));
    }

    ngOnInit(): void {
        this._symbolsSub = this.symbolsChange.displayDialog.subscribe((data) => {
            this.displaySymbolChangeNotification = data['show'];
            this.name = data['name'];
            this.session = data['session'];
            this.bridge = data['bridge'];
        });
        this._routeSub = this.activatedRoute.queryParams.subscribe(data => {
            this.isFromHM = data.abookTab;
            this.isFromExposureTab = data.exposure;
        });
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
        this._symbolsSub.unsubscribe();
        this._routeSub.unsubscribe();
    }

    private getBridgeDetailsInCycle(param) {
        return timer(0, environment.detailsRefreshInterval)
            .pipe(
                switchMap(() => this.authService.authState),
                filter(isLogged => isLogged),
                switchMap(() => this.bridgeDetailedStatusService.getBridgeDetails(param['uuid']))
            );
    }

    refreshStatus() {
        this.loader.loading = true;
        this.activatedRoute.params.pipe(
            take(1),
            map(param => param['uuid'] as string),
            switchMap(uuid => this.bridgeDetailedStatusService.getBridgeDetails(uuid)),
        ).subscribe(item => {
                this.bridgeDetailsPublisher.set(item);
                this.loader.loading = false;
                this.notifications.showSuccessMessage('Status refreshed');
            },
            err => this.errorManager.handle(err, {
                onDone: () => this.loader.loading = false
            }));
    }

    restartSession() {
        this.displaySymbolChangeNotification = this.restartSessionService
            .restartSessionAfterSymbolsChanged(this.name, this.bridge, this.session);
        this.symbolsChange.mainTabChanged(false);
        this.symbolsChange.setShowDialog(false, this.name);
    }

    hideDialog() {
        this.displaySymbolChangeNotification = false;
        this.symbolsChange.mainTabChanged(false);
        this.symbolsChange.setShowDialog(false, this.name);
    }

    onTabChange(event) {
        if (event.originalEvent.srcElement.innerText !== 'RISK MANAGEMENT') {
            this.symbolsChange.mainTabChanged(true);
        }
    }
}
