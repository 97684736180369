import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'removeZero'
})
export class RemoveZeroPipe implements PipeTransform {

    transform(value: string, args?: any): any {
        if (!value) {
            return 0;
        }
        const splitted = value.split('.');
        if (splitted[1] !== undefined && splitted[1].length === 1 && splitted[1] === '0') {
            return splitted[0];
        }
        return value;
    }
}
