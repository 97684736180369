import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {ChartData, RevenuePerMillion, RevenueSummary, TurnoverInstrument, TurnoverClient} from '../interfaces';

@Injectable()
export class StatisticDataStoreService {
  public readonly revenueSummary$: Observable<RevenueSummary>;
  public readonly totalSummary$: Observable<ChartData[]>;
  public readonly revenue$: Observable<ChartData>;
  public readonly clients$: Observable<ChartData>;
  public readonly volume$: Observable<ChartData>;
  public readonly trade$: Observable<ChartData>;
  public readonly turnoverInstrument$: Observable<TurnoverInstrument[]>;
  public readonly turnoverClient$: Observable<TurnoverClient[]>;
  public readonly revenuePM$: Observable<RevenuePerMillion[]>;

  private readonly _revenueSummarySubject = new ReplaySubject<RevenueSummary>(1);
  private readonly _totalSummarySubject = new ReplaySubject<ChartData[]>(1);
  private readonly _revenueSubject = new ReplaySubject<ChartData>(1);
  private readonly _clientsSubject = new ReplaySubject<ChartData>(1);
  private readonly _volumeSubject = new ReplaySubject<ChartData>(1);
  private readonly _tradeSubject = new ReplaySubject<ChartData>(1);
  private readonly _turnoverInstrumentSubject = new BehaviorSubject<TurnoverInstrument[]>(null);
  private readonly _turnoverClientSubject = new BehaviorSubject<TurnoverClient[]>(null);
  private readonly _revenuePMSubject = new BehaviorSubject<RevenuePerMillion[]>(null);

  constructor() {
    this.revenueSummary$ = this._revenueSummarySubject.asObservable();
    this.totalSummary$ = this._totalSummarySubject.asObservable();
    this.revenue$ = this._revenueSubject.asObservable();
    this.clients$ = this._clientsSubject.asObservable();
    this.volume$ = this._volumeSubject.asObservable();
    this.trade$ = this._tradeSubject.asObservable();
    this.turnoverInstrument$ = this._turnoverInstrumentSubject.asObservable();
    this.turnoverClient$ = this._turnoverClientSubject.asObservable();
    this.revenuePM$ = this._revenuePMSubject.asObservable();
  }

  public setRevenueSummary(revenueSummary: RevenueSummary): void {
    this._revenueSummarySubject.next(revenueSummary);
  }

  public setTotalSummary(totalSummary: ChartData[]): void {
    this._totalSummarySubject.next(totalSummary);
  }

  public setRevenue(revenue: ChartData): void {
    this._revenueSubject.next(revenue);
  }

  public setClients(clients: ChartData): void {
    this._clientsSubject.next(clients);
  }

  public setVolume(volume: ChartData): void {
    this._volumeSubject.next(volume);
  }

  public setTrade(trade: ChartData): void {
    this._tradeSubject.next(trade);
  }

  public setTurnoverInstrument(turnover: TurnoverInstrument[] | null): void {
    this._turnoverInstrumentSubject.next(turnover);
  }

  public setTurnoverClient(turnover: TurnoverClient[] | null): void {
    this._turnoverClientSubject.next(turnover);
  }

  public setRevenuePM(revenuePM: RevenuePerMillion[] | null): void {
    this._revenuePMSubject.next(revenuePM);
  }
}
