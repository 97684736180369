import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  TicksMonitoringSummary,
  BrokerSymbolTick,
} from '../interfaces/ticks-history.interface';
import { CommonPage } from 'src/app/commons/interfaces/page';

@Injectable()
export class TicksHistoryHttpService {
  private baseUri = `${environment.hostUrl}/ticks`;
  private statisticsUri = `${environment.hostUrl}/statistics/ticks`;

  constructor(private http: HttpClient) {}

  public getTicksMonitoringSymbols(
    bridgeUuid: string,
    brokerName: string
  ): Observable<string[]> {
    return this.http.get<string[]>(
      `${this.baseUri}/${bridgeUuid}/enabled-symbols`,
      {
        params: { brokerName },
      }
    );
  }

  public getTicksHistorySummary(
    bridgeUuid: string,
    params: Params
  ): Observable<TicksMonitoringSummary> {
    return this.http.get<TicksMonitoringSummary>(
      `${this.statisticsUri}/${bridgeUuid}/summary`,
      { params }
    );
  }

  public getTicksHistory(
    bridgeUuid: string,
    params: Params
  ): Observable<CommonPage<BrokerSymbolTick>> {
    return this.http.get<CommonPage<BrokerSymbolTick>>(
      `${this.statisticsUri}/${bridgeUuid}`,
      { params }
    );
  }

  public downloadCsv(bridgeUuid: string, params: Params): void {
    const url = new URL(
      `${window.location.origin}${this.statisticsUri}/${bridgeUuid}/csv`
    );
    this._addQueryParametersInUrl(url, params);
    url.searchParams.append('ngsw-bypass', 'true');

    const anchor = document.createElement('a');
    anchor.href = url.toString();
    anchor.click();
  }

  private _addQueryParametersInUrl(url: URL, queryParams: Params): URL {
    Object.entries(queryParams).forEach(([key, value]) => {
      url.searchParams.append(key, `${value}`);
    });
    return url;
  }
}
