import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LPSymbol} from '../../../commons/model/symbol/lp/lp-symbol';
import {generatePrompt} from '../prompt-generator';

@Component({
    selector: 'app-new-lp-symbol',
    templateUrl: './new-lp-symbol.component.html',
    styleUrls: ['./new-lp-symbol.component.scss']
})
export class NewLpSymbolComponent implements OnInit {

    @Input()
    brokerDecimalPlaces: number | null;

    @Input()
    lpDecimalPlaces: number;

    @Input()
    symbol: LPSymbol;

    @Output()
    submit = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit() {
    }

    getMultiplierPrompt(): string {
        return generatePrompt(this.symbol.multiplier, this.brokerDecimalPlaces, this.lpDecimalPlaces);
    }

}
