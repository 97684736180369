import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConditionTypeEnum, ReceiversEnum, RuleTypeEnum} from '../../enums';
import {ExpositionRule} from '../../interfaces';
import {SelectItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {CallWrapperService} from '../../../../commons/call-wrapper.service';
import {BrokerSymbolsService} from '../../../commons/broker-symbols.service';
import {UserRole} from '../../../../commons/dto/user-role';
import {FormStatusEnum} from '../../../../commons/enums';

@Component({
  selector: 'app-exposition-rules-dialog',
  templateUrl: './exposition-rules-dialog.component.html',
  styleUrls: ['./exposition-rules-dialog.component.scss']
})
export class ExpositionRulesDialogComponent implements OnInit, OnDestroy {
  @Input() expositionForm: FormGroup;
  @Input() formStatus: FormStatusEnum;
  @Input() userRole: UserRole;
  @Output() createFormSubmitClick = new EventEmitter<ExpositionRule>();
  @Output() updateFormSubmitClick = new EventEmitter<ExpositionRule>();
  @Output() deleteFormSubmitClick = new EventEmitter<ExpositionRule>();

  public formStatusEnum = FormStatusEnum;
  public conditionTypeEnum = ConditionTypeEnum;
  public ruleTypeEnum = RuleTypeEnum;
  public receiversEnum = ReceiversEnum;
  public brokerItems: SelectItem[] = [];
  public userRoleEnum = UserRole;

  private _subscription: Subscription;

  constructor(
      private callWrapper: CallWrapperService,
      private brokerSymbolService: BrokerSymbolsService) {}

  public ngOnInit(): void {
    this._subscription = this.callWrapper.withStableUuid(uuid => this.brokerSymbolService.getBrokerSessionsNames(uuid),
      brokerSessions => {
        this.brokerItems = brokerSessions.map(el => {
              return {label: el, value: el};
        });
      }
    );
  }

  public onFormCreateSubmitClick(): void {
    this.createFormSubmitClick.emit(this._preparingSubmitData() );
  }

  public onFormUpdateSubmitClick(): void {
    this.updateFormSubmitClick.emit(this._preparingSubmitData());
  }

  public onDeleteFormSubmitClick(): void {
    this.deleteFormSubmitClick.emit(this.expositionForm.value);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private _preparingSubmitData(): ExpositionRule {
    return {
      ...this.expositionForm.value,
      brokerName: this.expositionForm.get('brokerName').value.join(','),
      ruleType: this.expositionForm.get('ruleType').value.value,
      conditionType: this.expositionForm.get('conditionType').value.value,
      receivers: this.expositionForm.get('receivers').value.value
    };
  }
}
