import {ColumnData} from '../../commons/interfaces';

export class DataForTableColumns {
    private static TURNOVER_TABLE: ColumnData[] = [
        {id: 'aBookVolume', title: 'a-book'},
        {id: 'bBookVolume', title: 'b-book'},
        {id: 'totalVolume', title: 'total'}
    ];

    public static TURNOVER_INSTRUMENT_TABLE: ColumnData[] = [
        {id: 'instrument', title: 'instrument'},
        ...DataForTableColumns.TURNOVER_TABLE
    ];

    public static TURNOVER_CLIENT_TABLE: ColumnData[] = [
        {id: 'clientLogin', title: 'client'},
        ...DataForTableColumns.TURNOVER_TABLE
    ];

    public static REVENUE_TABLE: ColumnData[] = [
        {id: 'instrument', title: 'instrument'},
        {id: 'revenuePerMillion', title: 'total'}
    ];
}
