import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TreeNode} from 'primeng/api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LoaderService} from 'src/app/commons/loader.service';
import {NotificationsService} from 'src/app/commons/notifications.service';
import {ABookRuleService} from 'src/app/details-bridge/abook-rules/a-book-rule.service';
import {toAbookRule} from 'src/app/details-bridge/abook-rules/model/abook-rule';
import {LPSymbolsService} from 'src/app/details-bridge/commons/lp-symbols.service';
import {LPSession} from 'src/app/details-bridge/commons/model/symbol/lp/l-p-session';
import {Book} from '../interfaces/book.interface';

@Injectable({
    providedIn: 'root'
})
export class BBookHedgeService {
    private isSingleAccountRule = false;

    constructor(
        private router: Router,
        private abookService: ABookRuleService,
        private notificationService: NotificationsService,
        private lpSymbolService: LPSymbolsService,
        private loaderService: LoaderService,
    ) {}

    public hedgeAbook(bbook: Book, allGroups: string, selectedAccountsToHedge: string, selectedBridgeToHedge: string): void {
        this.loaderService.setLoading(true);
        this.findSessionsForSymbol(bbook.uuid).subscribe(lp => {
            const abook = toAbookRule({...bbook, uuid: null}, lp, allGroups, selectedAccountsToHedge);
            this.abookService.create(bbook.uuid, abook).subscribe(() => {
                this.loaderService.setLoading(false);
                this.router.navigate([`/app/bridge/${bbook.uuid}`], {queryParams: {abookTab: true}});
            }, _ => this.notificationService.showWarningMessage('Rule already exists!', `This rule exists in bridge: ${selectedBridgeToHedge}.`));
        });
    }

    public selectInstrumentToHedge(rowData: Book, bbooks: TreeNode[]): {allGroups: string, selectedAccountsToHedge: string, selectedBridgeToHedge: string} {
        const foundBbook = this.findTreeNodeForHedge(rowData, bbooks);
        const allGroups = this.getGroups(foundBbook);
        const selectedAccountsToHedge = this.getAllAccounts(foundBbook);
        const selectedBridgeToHedge = this.getBridgeName(rowData.uuid, bbooks);

        return {
            allGroups,
            selectedAccountsToHedge,
            selectedBridgeToHedge
        };
    }

    private findTreeNodeForHedge(bbook: Book, bbooks: TreeNode[]): TreeNode {
        const bbooks4Bridge = bbooks.find(el => el.data.uuid === bbook.uuid);
        const treeNode = bbooks4Bridge.children.find(child => child.data.symbolName === bbook.symbolName);
        if (!bbook.login) {
            return treeNode;
        } else {
            return treeNode.children.find(el => el.data.login === bbook.login);
        }
    }

    private getBridgeName(uuid: string, bbooks: TreeNode[]): string {
        return bbooks.find(el => el.data.uuid === uuid).data.symbolName;
    }

    private getGroups(node: TreeNode): string {
        if (!node.data.login) {
            return '*';
        } else {
            return node.data.group;
        }
    }

    private getAllAccounts(node: TreeNode): string {
        if (!node.data.login) {
            return node.children.map(el => el.data.login).join(',');
        } else {
            this.isSingleAccountRule = true;
            return node.data.login;
        }
    }

    private findSessionsForSymbol(uuid: string): Observable<LPSession> {
        return this.lpSymbolService.getSessions(uuid).pipe(map(el => el[0]));
    }
}
