import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '../../commons/loader.service';
import {NotificationsService} from '../../commons/notifications.service';
import {ConfirmationService} from 'primeng/api';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {delay, map} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {CoverageRule, emptyCoverageRule} from './model/coverage-rule';
import {CoverageRuleService} from './coverage-rule.service';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {SymbolsChangeService} from '../services/symbols-change.service';
import {CoverageRuleDisplay} from './model/coverage-rule-display';
import {CoverageRules} from './model/coverage-rules';
import {RulesRowReorder} from '../commons/abstract/rules-row-reorder.abstract';

@Component({
    selector: 'app-coverage-rules',
    templateUrl: './coverage-rules.component.html',
    styleUrls: ['./coverage-rules.component.scss'],
    providers: [CallWrapperService]
})
export class CoverageRulesComponent extends RulesRowReorder<CoverageRules, CoverageRule, CoverageRuleDisplay> implements OnInit, OnDestroy {

    rules: CoverageRuleDisplay[];
    isLoading = true;
    selectedRule: CoverageRuleDisplay;
    newRule: CoverageRule;
    displaySymbolChangeNotification = false;
    private _subscription: Subscription;
    private _routerSub: Subscription;

    constructor(protected ruleService: CoverageRuleService,
                private loaderService: LoaderService,
                private notifications: NotificationsService,
                private confirmationService: ConfirmationService,
                protected callWrapper: CallWrapperService,
                private symbolsChange: SymbolsChangeService,
                private router: Router) {
        super(callWrapper, ruleService);
        this._routerSub = router
            .events
            .subscribe((routerEvent: RouterEvent) => this.checkEvent(routerEvent));
    }

    ngOnInit() {
        this._subscription = this.callWrapper.withStableUuid(
            uuid => this.ruleService.getRules(uuid).pipe(map(value => value.rules)),
            list => {
                this.isLoading = false;
                this.rules = this.mapToDisplay(list);
            });

        this._subscription = this.symbolsChange.tabChanged.subscribe(data => {
            if (data['tabChanged']) {
                this.symbolsChange.setShowDialog(this.displaySymbolChangeNotification, 'coverage');
                this.displaySymbolChangeNotification = false;
            }
        });
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
        this._routerSub.unsubscribe();
        this.symbolsChange.setShowDialog(this.displaySymbolChangeNotification, 'coverage');
    }

    checkEvent(routerEvent: RouterEvent): any {
        if (routerEvent instanceof NavigationStart) {
            this.symbolsChange.setShowDialog(this.displaySymbolChangeNotification, 'coverage');
            this.displaySymbolChangeNotification = false;
        }
    }

    showNewRuleDialog() {
        this.newRule = emptyCoverageRule();
    }

    applyChanges() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.applyChanges(uuid).pipe(delay(500)),
            () => this.notifications.showSuccessMessage('Changes loaded!')
        );

        this.displaySymbolChangeNotification = false;
    }

    refreshRules() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.refreshRules(uuid),
            rules => {
                this.rules = this.mapToDisplay(rules.rules);
                this.notifications.showSuccessMessage('Rules refreshed!');
            });
    }

    updateRule(rule: CoverageRule) {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.update(uuid, rule),
            () => {
                this.updateSymbols();
                this.closeUpdateRuleDialog();
                this.displaySymbolChangeNotification = true;
                this.notifications.showSuccessMessage('Rule updated.', ' Please remember to apply the changes in the bridge!');
            });
    }

    deleteRule(rule: CoverageRule) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want remove this rule?',
            accept: () => {
                this.callWrapper.withLoaderAndSingleUuid(
                    uuid => this.ruleService.delete(uuid, rule.uuid),
                    () => {
                        this.updateSymbols();
                        this.closeUpdateRuleDialog();
                        this.displaySymbolChangeNotification = true;
                        this.notifications.showSuccessMessage('Rule deleted.', 'Please remember to apply the changes in the bridge!');
                    }
                );
            }
        });
    }

    addNewRule(newRule: CoverageRule) {
        this.callWrapper.withLoaderAndSingleUuid(uuid => this.ruleService.create(uuid, {
            ...newRule,
            coverageWeight: Math.max(...this.rules.map(o => o.weight)) + 1
        }), () => {
                this.updateSymbols();
                this.closeNewRuleDialog();
                this.displaySymbolChangeNotification = true;
                this.notifications.showSuccessMessage('New rule created.', 'Please remember to apply the changes in the bridge!');
            });
    }

    closeUpdateRuleDialog() {
        this.selectedRule = null;
    }

    closeNewRuleDialog() {
        this.newRule = null;
    }

    private updateSymbols() {
        this.callWrapper.withLoaderAndSingleUuid(
            uuid => this.ruleService.getRules(uuid),
            results => this.rules = this.mapToDisplay(results.rules)
        );
    }

    private mapToDisplay(coverageRules: CoverageRule[]) {
        if (!coverageRules) {
            return null;
        }
        return coverageRules.map(el2 => {
            return {
                ...el2,
                brokerName: el2.brokerName.split(','),
                weight: el2.coverageWeight != null ? el2.coverageWeight : 0
            };
        });
    }
}
