import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PriceImprovementRules} from './model/price-improvement-rules';
import {BRIDGE_URL} from '../../utils/constants';
import {PriceImprovementRule} from './model/price-improvement-rule';
import {environment} from '../../../environments/environment.prod';
import {Observable} from 'rxjs';
import {RuleService} from '../commons/interfaces/rule-service.interface';

@Injectable({
  providedIn: 'root'
})
export class PriceImprovementService implements RuleService<PriceImprovementRules, PriceImprovementRule> {

  readonly PATH = '/rules/price-improvement';

  constructor(private http: HttpClient) {
  }

  getRules(uuid: string): Observable<PriceImprovementRules> {
    return this.http.get<PriceImprovementRules>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}`);
  }

  applyChanges(uuid: string): Observable<void> {
    return this.http.post<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/reload`, null);
  }

  refreshRules(uuid: string): Observable<PriceImprovementRules> {
    return this.http.post<PriceImprovementRules>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/refresh`, null);
  }

  delete(uuid: string, id: string): Observable<void> {
    return this.http.delete<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/${id}`);
  }

  update(uuid: string, model: PriceImprovementRule): Observable<void> {
    return this.http.put<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/${model.uuid}`, model);
  }

  create(uuid: string, model: PriceImprovementRule): Observable<void> {
    return this.http.post<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}`, model);
  }
}
