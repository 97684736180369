import {SelectItem} from 'primeng/api';
import {CONDITION_TYPE, RULE_TYPE} from 'src/app/details-bridge/exposition-rules/helpers/filter-data';
import {TableField} from '../../commons/interfaces/table-field';

export const BOOK_COLUMNS: TableField[] = [
    {field: 'symbolName', header: 'Symbol'},
    {field: 'login', header: 'Login'},
    {field: 'noOfOrders', header: 'No of orders'},
    {field: 'buyVolume', header: 'Buy volume'},
    {field: 'buyPrice', header: 'Buy price'},
    {field: 'sellVolume', header: 'Sell volume'},
    {field: 'sellPrice', header: 'Sell price'},
    {field: 'nettVolume', header: 'Net volume'},
    {field: 'profit', header: 'Profit'},
    {field: 'exposure', header: 'Exposure'}
];

export const EXPOSURE_RULES_COLUMNS: TableField[] = [
    {field: 'rule.brokerName', header: 'Broker Name'},
    {field: 'rule.instrument', header: 'Instrument'},
    {field: 'rule.groupName', header: 'Group Name'},
    {field: 'rule.account', header: 'Account'},
    {field: 'rule.ruleType', header: 'Rule Type'},
    {field: 'rule.conditionType', header: 'Condition Type'},
    {field: 'rule.conditionValue', header: 'Condition Value'},
    {field: 'currentValue', header: 'Current Value'},
];

export const EXPOSURE_RULES_COLUMNS_FILTERS: {field: string, isDropdown?: boolean, options?: SelectItem[]}[] = [
    {field: 'rule.brokerName'},
    {field: 'rule.instrument'},
    {field: 'rule.groupName'},
    {field: 'rule.account'},
    {field: 'rule.ruleType', isDropdown: true, options: RULE_TYPE},
    {field: 'rule.conditionType', isDropdown: true, options: CONDITION_TYPE},
    {field: 'rule.conditionValue'},
    {field: 'currentValue'},
];
