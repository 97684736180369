import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface ReportRecord {
    brokerCommission?: number;
    brokerProfit?:     number;
    contractSize?:     number;
    coverageRatio?:    number;
    execDuration?:     number;
    executionId?:      string;
    executionTime?:    string;
    finalPrice?:       number;
    groupName?:        string;
    login?:            number;
    lpCommission?:     number;
    lpExecutionTime?:  string;
    lpExecVolume?:     number;
    lpName?:           string;
    lpOrderSide?:      string;
    lpPrice?:          number;
    lpReqPrice?:       number;
    lpSlippage?:       number;
    lpSymbolName?:     string;
    openPrice?:        number;
    orderDate?:        string;
    orderId?:          string;
    orderReqPrice?:    number;
    orderSide?:        string;
    nativeExecVolume?: number;
    markupUSD?:        number;
    symbolName?:       string;
    totalMarkup?:      number;
    usdCrossPrice?:    number;
}

export interface ReportFilters {
    uuid: string;
    from: Date;
    to: Date;
  }

export interface ReportFiltersDetailed extends ReportFilters {
  bookType: 'BBOOK' | 'ABOOK' | 'BOOK_BOTH';
  orderStatus: 'ERROR' | 'SUCCESS' | 'BOTH';
}

export interface ReportQueryParams {
    uuid: string;
    from: string;
    to: string;
}

export interface ReportQueryParamsDetailed extends  ReportQueryParams {
    bookType: 'BBOOK' | 'ABOOK' | 'BOOK_BOTH';
    orderStatus: 'ERROR' | 'SUCCESS' | 'BOTH';
}

export type ReportType = 'DETAILED_HEDGE' | 'SINGLE_TRADES' | 'CLOSED_TRADES';

export interface ReportSummary {
    contractSizeSummary: number;
    nativeExecVolumeSummary: number;
    lpCommissionSummary: number;
    brokerCommissionSummary: number;
    totalMarkupSummary: number;
    markupUSDSummary: number;
    brokerProfitSummary: number;
    prImpMarkupSummary: number;
    prImpMarkupUSDSummary: number;
}

export interface Report {
    records: ReportRecord[];
    summary: ReportSummary;
}

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    constructor(private http: HttpClient) {}

    public getReport(reportType: ReportType, queryParams: ReportQueryParams | ReportQueryParamsDetailed): Observable<Report> {
        const url = new URL(`${window.location.origin}${environment.hostUrl}/reports/${reportType}`);
        this.addQueryParametersInUrl(url, queryParams);

        return this.http.get<Report>(url.toString());
    }

    public getReportFile(reportType: ReportType, queryParams: ReportQueryParams | ReportQueryParamsDetailed): void {
        const url = new URL(`${window.location.origin}${environment.hostUrl}/reports/download/${reportType}`);
        this.addQueryParametersInUrl(url, queryParams);
        url.searchParams.append('ngsw-bypass', 'true');

        const anchor = document.createElement('a');
        anchor.href = url.toString();
        anchor.click();
    }

    private addQueryParametersInUrl(url: URL, queryParams: ReportQueryParams | ReportQueryParamsDetailed): URL {
        Object.entries(queryParams).forEach(([key, value]) => {
            url.searchParams.append(key, `${value}`);
        });
        return url;
    }
}
