import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormCreatorService, RulesSchedulerDataHttpService} from './services';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {EditRulesScheduler, ManualOverrideData, OverrideType, RulesScheduler} from './interfaces';
import {FormGroup} from '@angular/forms';
import {FormStatusEnum} from '../../commons/enums';
import {BridgeDetailsPublisherService} from '../commons/bridge-details-publisher.service';
import {NotificationsService} from '../../commons/notifications.service';
import {ConfirmationService} from 'primeng/api';
import {DAY_FOR_THE_WEEK, RULE_SCHEDULER, STATUSES} from './helpers/filter-data';
import {BrokerSession} from '../commons/model/symbol/broker/broker-session';

@Component({
  selector: 'app-rules-scheduler',
  templateUrl: './rules-scheduler.component.html',
  styleUrls: ['./rules-scheduler.component.scss'],
  providers: [RulesSchedulerDataHttpService, FormCreatorService]
})
export class RulesSchedulerComponent implements OnInit, OnDestroy {
  public rulesScheduler: RulesScheduler[];
  public brokerSessions: BrokerSession[];
  public isLoading = true;
  public bridgeUUID: string;
  public rulesSchedulerForm: FormGroup;
  public statusList = STATUSES;
  public ruleScheduler = [{label: 'ALL', value: ''}, ...RULE_SCHEDULER];
  public daysOfTheWeek = [{label: 'ALL', value: ''}, ...DAY_FOR_THE_WEEK];
  public formStatusEnum = FormStatusEnum;
  public formStatus: FormStatusEnum | null;

  private _selectedSchedulerRule: RulesScheduler;
  private readonly _completeSubject = new Subject<void>();
  constructor(
      private readonly callWrapper: CallWrapperService,
      private readonly rulesSchedulerHttp:  RulesSchedulerDataHttpService,
      private readonly bridgeDetailsPublisher: BridgeDetailsPublisherService,
      private readonly notifications: NotificationsService,
      private readonly confirmationService: ConfirmationService,
      private readonly formCreator: FormCreatorService,
  ) { }

  public ngOnInit(): void {
    this._initialization();
  }

  public get hasRulesManual(): boolean {
    return this.rulesScheduler ? this.rulesScheduler.some(rule => rule.manualOverrideData) : false;
  }

  public get selectedSchedulerRule(): RulesScheduler {
    return this._selectedSchedulerRule;
  }

  public set selectedSchedulerRule(selectedRule: RulesScheduler) {
    this._selectedSchedulerRule = selectedRule;
    this.rulesSchedulerForm = this.formCreator.getRulesSchedulerForm(this.bridgeUUID, selectedRule);
    this.formStatus = FormStatusEnum.edit;
  }

  public ngOnDestroy(): void {
    this._completeSubject.next();
    this._completeSubject.complete();
  }

  public showNewRuleDialog(): void {
    this.rulesSchedulerForm = this.formCreator.getRulesSchedulerForm(this.bridgeUUID);
    this.formStatus = FormStatusEnum.create;
  }

  public closeNewRuleDialog(): void {
    this.formStatus = null;
    this.rulesSchedulerForm = null;
  }

  public closeUpdateRuleDialog(): void {
    this.formStatus = null;
    this._selectedSchedulerRule = null;
    this.rulesSchedulerForm = null;
  }

  public addNewRule(editRule: EditRulesScheduler): void {
    this.callWrapper.withLoaderAndSingleUuid(() =>
            this.rulesSchedulerHttp.postRulesScheduler(editRule),
        () => {
          this.refreshRules();
          this.closeNewRuleDialog();
          this.notifications.showSuccessMessage('New Rule created.');
        });
  }

  public setManualOverride(manualOverride: ManualOverrideData): void {
    this.callWrapper.withLoaderAndSingleUuid(() =>
      this.rulesSchedulerHttp.setManualOverride(manualOverride),
      () => {
        this.refreshRules();
        this.closeNewRuleDialog();
        this.notifications.showSuccessMessage(`Manual Override is ${manualOverride.overrideType === OverrideType.ACTIVATION ? 'enabled' : 'disabled'}.`);
      });
  }

  public setRuleToAuto(ruleId: string): void {
    this.callWrapper.withLoaderAndSingleUuid(() =>
    this.rulesSchedulerHttp.setRuleToAuto(ruleId),
        () => {
          this.refreshRules();
          this.closeNewRuleDialog();
          this.notifications.showSuccessMessage(`Rule is set to auto.`);
        });
  }

  public copyRule(selectedRule: RulesScheduler): void {
    const defaultDateRange = {
      day: 0,
      time: ''
    };

    this.rulesSchedulerForm = this.formCreator.getRulesSchedulerForm(this.bridgeUUID, selectedRule);
    this.rulesSchedulerForm.patchValue({
      from: defaultDateRange,
      to: defaultDateRange
    });

    this.formStatus = FormStatusEnum.create;
  }

  public editRule(editRule: EditRulesScheduler): void {
    this.callWrapper.withLoaderAndSingleUuid(
        () => this.rulesSchedulerHttp.putRulesScheduler(this.selectedSchedulerRule.id, editRule),
        () => {
          this.refreshRules();
          this.closeUpdateRuleDialog();
          this.notifications.showSuccessMessage('Rule updated.');
        });
  }

  public deleteRule(): void {
    this.confirmationService.confirm({
      message: 'Are you sure that you want remove this rule?',
      accept: () => {
        this.callWrapper.withLoaderAndSingleUuid(
            () => this.rulesSchedulerHttp.deleteRuleScheduler(this.selectedSchedulerRule.id),
            () => {
              this.rulesScheduler.splice(this.rulesScheduler.findIndex(elem => elem.id === this.selectedSchedulerRule.id), 1);
              this.closeUpdateRuleDialog();
              this.notifications.showSuccessMessage('Rule deleted.');
            }
        );
      }
    });
  }

  public refreshRules(): void {
    this.callWrapper.withLoaderAndSingleUuid(
        uuid => this.rulesSchedulerHttp.getRulesScheduler(uuid),
        rules => {
          this.rulesScheduler = rules;
          this.notifications.showSuccessMessage('Scheduler rules refreshed!');
        });
  }

  private _initialization(): void {
    this.callWrapper.withStableUuid(
        uuid => this.rulesSchedulerHttp.getRulesScheduler(uuid).pipe(
            takeUntil(this._completeSubject)),
        list => {
          this.rulesScheduler = list;
          this.isLoading = false;
        });

      this.callWrapper.withStableUuid(
          uuid => this.rulesSchedulerHttp.getBrokerSession(uuid).pipe(
              takeUntil(this._completeSubject)),
          list => {
              this.brokerSessions = list;
              this.isLoading = false;
          });

    this.bridgeDetailsPublisher.bridgeInfo
        .pipe(takeUntil(this._completeSubject))
        .subscribe(bridgeInfo => {
          this.bridgeUUID = bridgeInfo.uuid;
        });
  }
}
