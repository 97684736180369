import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[appRowHighlight]'
})
export class RowHighlightDirective {

    @Input()
    set current(current) {
        const value = +current.volumeDifference;
        const children = current.children;
        if ((value !== 0 && children !== null) || current.isRed > 0) {
            this.el.nativeElement.style.color = 'red';
        }
    }

    constructor(private el: ElementRef) {
    }

}
