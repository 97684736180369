import {TableField} from '../../commons/interfaces/table-field';

export const ACCOUNT_COLUMNS: TableField[] = [
    {field: 'account', header: 'Account'},
    {field: 'balance', header: 'Balance'},
    {field: 'equity', header: 'Equity'},
    {field: 'freeMargin', header: 'Free margin'},
    {field: 'margin', header: 'Margin'},
    {field: 'marginLevel', header: 'Margin Level (%)'},
    {field: 'profit', header: 'Profit'},
];

export const HEDGE_COLUMNS: TableField[] = [
    {field: 'alias', header: 'Broker name'},
    {field: 'timeOfOccurence', header: 'Time of Occurrence'},
    {field: 'volumeDifference', header: 'Difference'},
    {field: 'brokerVolume', header: 'Clients Volume'},
    {field: 'fixVolume', header: 'Volume LP'},
    {field: 'lastModifiedBroker', header: 'Last Modified Clients'},
    {field: 'lastModifiedLP', header: 'Last Modified LP'},
    {field: 'ml', header: 'Margin Level (%)'}
];

export const ERROR_COLUMNS: TableField[] = [
    {field: 'bridgeName', header: 'Bridge name'},
    {field: 'message', header: 'Message'},
    {field: 'occurrenceTime', header: 'Occurrence time'}
];
