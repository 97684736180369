import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BMValidators} from '../../../commons/b-m-validators';
import {SelectItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {CallWrapperService} from '../../../commons/call-wrapper.service';
import {BrokerSymbolsService} from '../../commons/broker-symbols.service';
import {GuaranteedRuleDisplay} from '../model/guaranteed-rule-display';
import {GuaranteedRule} from '../model/guaranteed-rule';

@Component({
  selector: 'app-new-guaranteed-rule',
  templateUrl: './new-guaranteed-rule.component.html',
  styleUrls: ['./new-guaranteed-rule.component.scss']
})
export class NewGuaranteedRuleComponent implements OnInit, OnDestroy {

  @Input()
  set value(newState: GuaranteedRuleDisplay) {
    this._value = newState;
    if (newState) {
      this.form.patchValue(this._value);
    } else {
      this.form.reset();
    }
  }

  get value() {
    return this._value;
  }

  @Output()
  update = new EventEmitter<GuaranteedRule>();

  @Output()
  delete = new EventEmitter<GuaranteedRule>();

  brokerName = new FormControl('', [Validators.required, Validators.maxLength(50)]);
  instrument = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
  groupName = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
  account = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);

  @Output()
  form = new FormGroup({
    uuid: new FormControl(),
    brokerName: this.brokerName,
    instrument: this.instrument,
    groupName: this.groupName,
    account: this.account,
  });

  brokerItems: SelectItem[] = [];

  private _value: GuaranteedRuleDisplay;
  private _subscription: Subscription;

  constructor(private callWrapper: CallWrapperService,
              private brokerSymbolService: BrokerSymbolsService) {
  }

  ngOnInit() {
    this._subscription = this.callWrapper.withStableUuid(uuid => this.brokerSymbolService.getBrokerSessionsNames(uuid),
        brokerSessions => {
          this.brokerItems = brokerSessions.map(el => {
            return {label: el, value: el};
          });
        }
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  send() {
    this.update.emit(this.convertToSlTpRule(this.form.value) as GuaranteedRule);
  }

  deleteItem() {
    this.delete.emit(this.form.value as GuaranteedRule);
  }

  private convertToSlTpRule(rule: GuaranteedRuleDisplay) {
    return {
      account: rule.account,
      brokerName: rule.brokerName.join(','),
      instrument: rule.instrument,
      groupName: rule.groupName,
      uuid: rule.uuid,
    };
  }
}
