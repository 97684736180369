import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {VolumeProfit} from '../interfaces/volume-profit.interface';
import {ExposureRulesTab} from '../interfaces/exposure-rules.interface';
import {BooksTable} from '../interfaces/books-table.interface';
import {BookFilterType} from '../helpers/book.helper';

@Injectable({
    providedIn: 'root'
})
export class ExposureDataHttpService {

    private PATH = `${environment.hostUrl}/exposure/table`;

    constructor(private http: HttpClient) {}

    public getVolumesAndProfits(bridgeUuid: string, bookType: BookFilterType, excludeSymbols: string): Observable<VolumeProfit> {
        return this.http.get<VolumeProfit>(`${this.PATH}/${bridgeUuid}/symbols-vol-pnl`, {
            params: {
                'book-type': bookType,
                'exclude-symbols': excludeSymbols
            }
        });
    }

    public getExposureRulesWithCurrent(bridgeUuid: string): Observable<ExposureRulesTab> {
        return this.http.get<ExposureRulesTab>(`${this.PATH}/${bridgeUuid}/rules-table-with-current`);
    }

    public getBridgeBooksTable(bridgeUuid: string): Observable<BooksTable> {
        return this.http.get<BooksTable>(`${this.PATH}/${bridgeUuid}/books-tables`);
    }
}
