import {Attribute, Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
    selector: '[appMinValidator]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => MinValidatorDirective), multi: true}
    ]
})
export class MinValidatorDirective implements Validator {

    constructor(@Attribute('appMinValidator') public validateMin: string) {
    }

    validate(input: AbstractControl): { [key: string]: any } {
        const value = input.value as number;
        if (value < +this.validateMin) {
            return {
                validateMin: true
            };
        }

        return null;

    }

}
