import {Injectable} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {AggregatedBook, Book} from '../interfaces/book.interface';
import {IsChildExpandedMap} from '../interfaces/books-table.interface';
import {TotalBookRow} from '../interfaces/total-book-row.interface';

@Injectable({
  providedIn: 'root',
})
export class BooksTableService {
  public convertBooksToTreeNodes(
    books: AggregatedBook[],
    isTableBookChildExpandedMap: IsChildExpandedMap,
    bridgeUuid: string,
    bridgeName: string
  ): TreeNode[] {
    return books.length
      ? [
          this.generateTreeNodeForBook(
            books,
            isTableBookChildExpandedMap,
            bridgeUuid,
            bridgeName
          ),
        ]
      : [];
  }

  private generateTreeNodeForBook(
    books: AggregatedBook[],
    isTableBookChildExpandedMap: IsChildExpandedMap,
    bridgeUuid: string,
    bridgeName: string
  ): TreeNode {
    if (!books.length) {
      return {};
    }

    const children = books.map((row) => {
      const grandChildren: TreeNode[] = row.children.map((child) =>
        this.mapToBookTreeData(child, bridgeUuid, child.group)
      );
      const expanded = isTableBookChildExpandedMap[row.symbolName];

      return this.mapToBookRootTreeData(
        bridgeUuid,
        row,
        grandChildren,
        expanded
      );
    });
    const totalBook = this.getTotalBookRowForBridge(children);

    const expandedTotal = isTableBookChildExpandedMap[bridgeName];

    return this.mapToTotalBookRow(
      bridgeUuid,
      bridgeName,
      totalBook,
      children,
      expandedTotal
    );
  }

  private mapToBookTreeData(
    child: Book,
    uuid: string,
    group: string
  ): TreeNode {
    return {
      data: {
        uuid,
        symbolName: child.symbolName,
        login: child.login,
        noOfOrders: child.noOfOrders,
        buyVolume: child.buyVolume,
        buyVolumeInUnits: child.buyVolumeInUnits,
        buyPrice: child.buyPrice,
        sellVolume: child.sellVolume,
        sellVolumeInUnits: child.sellVolumeInUnits,
        sellPrice: child.sellPrice,
        nettVolume: child.nettVolume,
        nettVolumeInUnits: child.nettVolumeInUnits,
        profit: child.profit,
        brokerName: child.brokerName,
        exposure: child.exposure,
        group: group,
      },
    };
  }

  private mapToBookRootTreeData(
    uuid: string,
    row: AggregatedBook,
    grandChildren: TreeNode[],
    expanded: boolean
  ): TreeNode {
    return {
      data: {
        uuid,
        symbolName: row.symbolName,
        noOfOrders: row.noOfOrders,
        buyVolume: row.buyVolume,
        buyVolumeInUnits: row.buyVolumeInUnits,
        buyPrice: row.buyPrice,
        sellVolume: row.sellVolume,
        sellVolumeInUnits: row.sellVolumeInUnits,
        sellPrice: row.sellPrice,
        nettVolume: row.nettVolume,
        nettVolumeInUnits: row.nettVolumeInUnits,
        profit: row.profit,
        exposure: row.exposure,
      },
      children: grandChildren,
      expanded: expanded,
    };
  }

  private mapToTotalBookRow(
    bridgeUuid: string,
    bridgeName: string,
    totalBook: TotalBookRow,
    children: TreeNode[],
    expanded: boolean
  ): TreeNode {
    return {
      data: {
        uuid: bridgeUuid,
        symbolName: bridgeName,
        login: 'TOTAL',
        noOfOrders: totalBook.totalOrders,
        buyVolume: totalBook.totalBuyVol,
        buyVolumeInUnits: totalBook.totalBuyVolInUnits,
        sellVolume: totalBook.totalSellVol,
        sellVolumeInUnits: totalBook.totalSellVolInUnits,
        nettVolume: totalBook.totalNetVol,
        nettVolumeInUnits: totalBook.totalNetVolInUnits,
        profit: totalBook.totalProfit,
      },
      children,
      expanded,
    };
  }

  private getTotalBookRowForBridge(books: TreeNode[]): TotalBookRow {
    const initialValues = {
      totalOrders: 0,
      totalBuyVol: 0,
      totalBuyVolInUnits: 0,
      totalSellVol: 0,
      totalSellVolInUnits: 0,
      totalNetVol: 0,
      totalNetVolInUnits: 0,
      totalProfit: 0,
    };

    return books.reduce(
      (
        {
          totalOrders,
          totalBuyVol,
          totalBuyVolInUnits,
          totalNetVol,
          totalNetVolInUnits,
          totalProfit,
          totalSellVol,
          totalSellVolInUnits,
        },
        book
      ) => ({
        totalOrders: +(totalOrders + book.data.noOfOrders).toFixed(2),
        totalBuyVol: +(totalBuyVol + book.data.buyVolume).toFixed(2),
        totalBuyVolInUnits: +(totalBuyVolInUnits + book.data.buyVolumeInUnits).toFixed(2),
        totalSellVol: +(totalSellVol + book.data.sellVolume).toFixed(2),
        totalSellVolInUnits: +(totalSellVolInUnits + book.data.sellVolumeInUnits).toFixed(2),
        totalNetVol: +(totalNetVol + book.data.nettVolume).toFixed(2),
        totalNetVolInUnits: +(totalNetVolInUnits + book.data.nettVolumeInUnits).toFixed(2),
        totalProfit: +(totalProfit + book.data.profit).toFixed(2),
      }),
      initialValues
    );
  }
}
