import {Component} from '@angular/core';
import {BrokerSessionState} from '../../commons/model/status/broker-session-state';
import {BridgeDetailsPublisherService} from '../../commons/bridge-details-publisher.service';
import {BrokerSession} from '../../commons/model/status/broker-session';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'app-broker-session-status',
    templateUrl: './broker-session-status.component.html',
    styleUrls: ['./broker-session-status.component.scss']
})
export class BrokerSessionStatusComponent {
    $sessions: Observable<BrokerSession[]>;
    isBrokerSessions = false;

    constructor(private bridgeDetailsPublisher: BridgeDetailsPublisherService) {
        this.$sessions = this.bridgeDetailsPublisher
            .bridgeInfo
            .pipe(
                distinctUntilChanged((x, y) => _.isEqual(x.brokerSessions, y.brokerSessions)),
                map(value => {
                    if (value.brokerSessions === null) {
                        this.isBrokerSessions = true;
                    }
                    return value.brokerSessions;
                }),
            );
    }

    private getStatusLightColor(state: BrokerSessionState): string {
        switch (BrokerSessionState[state.toString()]) {
            case BrokerSessionState.Active:
                return 'green';
            case BrokerSessionState.Inactive:
                return 'yellow';
            case BrokerSessionState.Warning:
                return 'red';
            case BrokerSessionState.New:
                return 'grey';
            case BrokerSessionState.NotConfigured:
                return '#1e284c';
            default:
                return 'red';
        }
    }
}
