import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BRIDGE_REGISTER_URL} from '../../utils/constants';

@Injectable({
    providedIn: 'root'
})
export class BridgeRegisterService {
    constructor(private http: HttpClient) {}

    deleteBridge(uuid: string): Observable<Response> {
        const url = `${BRIDGE_REGISTER_URL}/${uuid}`;
        const httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        };
        return this.http.delete<Response>(url, httpOptions);
    }
}
