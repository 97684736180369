import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Params} from '@angular/router';
import {Calculation, MultiplierCalculator} from '../interfaces/multiplier-calculator';

@Injectable()
export class LpHttpService {

  constructor(private http: HttpClient) {}

  public getLpSymbolsCsv(bridgeUUID: string, sessionName: string): void {
    const anchor = document.createElement('a');
    anchor.href = `${environment.hostUrl}/bridges/${bridgeUUID}/symbols/lp/${sessionName}/download-csv?ngsw-bypass=true`;
    anchor.click();
  }

  public uploadLpSymbolCsv(file: FormData, bridgeUUID: string, sessionName: string): any {
    return this.http.post(`${environment.hostUrl}/bridges/${bridgeUUID}/symbols/lp/${sessionName}/upload-csv`, file);
  }

  public getMultiplierData(queryParams: Params): Observable<MultiplierCalculator> {
    return this.http.get<MultiplierCalculator>(`${environment.hostUrl}/multiplier-calculator`, {
      params: queryParams
    });
  }

  public calculateMultiplier(calculation: Calculation, queryParams: Params): Observable<Calculation> {
    return this.http.post<Calculation>(`${environment.hostUrl}/multiplier-calculator/calculate`, calculation, {
      params: queryParams
    });
  }

  public getMultiplierCsv(multiplier: MultiplierCalculator, queryParams: Params): Observable<Blob> {
    return this.http.post(`${environment.hostUrl}/multiplier-calculator/to-csv`, multiplier, {
      params: queryParams,
      responseType: 'blob'
    });
  }

  public uploadMultiplierCsv(file: FormData, queryParams: Params): Observable<MultiplierCalculator> {
    return this.http.post<MultiplierCalculator>(`${environment.hostUrl}/multiplier-calculator/from-csv`, file, {
      params: queryParams
    });
  }

  public confirmMultiplier(multiplier: MultiplierCalculator): Observable<unknown> {
    return this.http.post(`${environment.hostUrl}/multiplier-calculator`, multiplier);
  }
}
