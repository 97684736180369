import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {BridgeSettings, UserSettings} from './dto/user-dto';
import {AuthenticationService} from './security/authentication.service';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import {UserService} from '../users/user.service';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService {

    _subject = new ReplaySubject<UserSettings>(1);

    constructor(private authService: AuthenticationService,
                private userService: UserService) {
        this.authService.userInfo.pipe(
            map(user => user ? user.settings : null)
        ).subscribe(value => this._subject.next(value));
    }

    getBridgeSettings(uuid: string): Observable<BridgeSettings | null> {
        return this._subject.pipe(
            filter(value => !!value),
            map(userSettings => userSettings.bridgeSettings.find(bridgeSettings => bridgeSettings.uuid === uuid))
        );
    }

    setBridgeSettings(settings: BridgeSettings): Observable<BridgeSettings> {
        return this._subject.pipe(
            take(1),
            tap(oldState => {
                const bridgeSettings = oldState.bridgeSettings;
                const oldBridge = bridgeSettings.find(bridge => bridge.uuid === settings.uuid);
                if (oldBridge) {
                    oldBridge.lpObSettings = settings.lpObSettings;
                    oldBridge.brokerObSettings = settings.brokerObSettings;
                    oldBridge.brokerNewMwSettings = settings.brokerNewMwSettings;
                    oldBridge.lpNewMwSettings = settings.lpNewMwSettings;
                } else {
                    oldState.bridgeSettings.push(settings);
                }
                this._subject.next(oldState);
            }),
            switchMap(() => this.userService.modifyOrderBookSettings(settings)),
        );
    }
}
