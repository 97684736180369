import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {UserDto} from '../commons/dto/user-dto';
import {UserService} from './user.service';
import {ErrorManagerService} from '../commons/error/error-manager.service';
import {LoaderService} from '../commons/loader.service';
import {emptyNewUserModel, NewUserModel} from './new-user-model';
import {NotificationsService} from '../commons/notifications.service';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {UserRole} from '../commons/dto/user-role';
import {AuthenticationService} from '../commons/security/authentication.service';
import {CallWrapperService} from '../commons/call-wrapper.service';
import {UsersDataHelper} from './helper/utils/users-data.helper';
import {TableField} from '../commons/interfaces/table-field';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [CallWrapperService]
})
export class UsersComponent implements OnInit, OnDestroy {
    public users: UserDto[] = null;
    public selectedUser: UserDto = null;
    public newUserModel: NewUserModel = emptyNewUserModel();
    public reportOptions: SelectItem[] = UsersDataHelper.REPORT_OPTION;
    public uploadCsv: SelectItem[] = UsersDataHelper.UPLOAD_CSV;
    public cols: TableField[] = UsersDataHelper.TABLE_COLUMNS;
    public isNewUserDialogDisplayed = false;
    public isChangePasswordDisplayed = false;

    private emailRegExp = new RegExp('[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{1,3}');
    private readonly _completeSubject = new Subject<void>();

    constructor(
        private userService: UserService,
        private errorManager: ErrorManagerService,
        private callWrapper: CallWrapperService,
        private authenticationService: AuthenticationService,
        private loadingService: LoaderService,
        private notifications: NotificationsService,
        private confirmationService: ConfirmationService) {}

    public ngOnInit(): void {
        this.loadingService.loading = true;
        this.setUsers();
    }

    public newUser(): void {
        this.newUserModel = emptyNewUserModel();
        this.isNewUserDialogDisplayed = true;
    }

    public onNewUserSubmit(): void {
        this.loadingService.loading = true;
        this.userService.new(this.newUserModel).subscribe(() => {
            this.setUsers();
            this.notifications.showSuccessMessage('New account created');
            this.isNewUserDialogDisplayed = false;
        }, err => this.errorManager.handle(err, {
            onDone: () => this.loadingService.loading = false
        }));
    }

    public deleteUser(user: UserDto): void {
        this.confirmationService.confirm({
            message: 'Are you sure that you want remove this account?',
            accept: () => {
                this.loadingService.loading = true;
                this.userService.remove(user.name).subscribe(() => {
                    this.setUsers();
                }, err => this.errorManager.handle(err, {
                    onDone: () => {
                        this.loadingService.loading = false;
                    }
                }));

            }
        });
    }


    public changeUserPassword(user: UserDto): void {
        this.isChangePasswordDisplayed = true;
        this.selectedUser = user;
    }

    public onChangeUserPasswordSubmit(isPasswordChanged: boolean): void {
        this.isChangePasswordDisplayed = !isPasswordChanged;
    }

    public changeUserSettings(event, user: UserDto): void {
        if (user.email && !this.emailRegExp.test(user.email)) { return; }

        this.callWrapper.withLoader(this.userService.updateUserSettings(user.name, user), () => {
            this.notifications.showSuccessMessage('User settings changed!');
        });
    }

    public ngOnDestroy(): void {
        this._completeSubject.next();
        this._completeSubject.subscribe();
    }

    private setUsers(): void {
        this.userService.getAll()
            .pipe(takeUntil(this._completeSubject))
            .subscribe(users => {
                this.users = users.filter(user => user.role !== UserRole.ADMIN);
                this.loadingService.loading = false;
            }, err => this.errorManager.handle(err, {
                onDone: () => {
                    this.users = [];
                    this.loadingService.loading = false;
                }
            }));
    }
}
