import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-bridge-status',
    templateUrl: './bridge-status.component.html',
    styleUrls: ['./bridge-status.component.scss']
})
export class BridgeStatusComponent {

    @Output()
    refreshEmitter = new EventEmitter<void>();

    constructor() {
    }

    refresh() {
        this.refreshEmitter.emit();
    }

}
