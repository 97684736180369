import {Component} from '@angular/core';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {Router} from '@angular/router';
import {NotificationsService} from '../../commons/notifications.service';
import {Observable} from 'rxjs';
import {VersionService} from '../../commons/version.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
    version: Observable<string>;

    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private notifications: NotificationsService,
                private versionService: VersionService) {
        this.version = versionService.version;
    }

    logout() {
        this.authenticationService
            .logout()
            .subscribe(() => {
                this.notifications.showInfoMessage('You have logged out of the site');
                this.router.navigateByUrl('/login');
            });
    }
}
