import {UserRole} from './user-role';

export interface OrderBookSettings {
    readonly symbol: string;
}

export function buildOrderBookSettings(symbol: string): OrderBookSettings {
    return {
        symbol: symbol
    };
}

export interface SessionSettings {
    readonly obs: OrderBookSettings[];
    readonly sessionName: string;
}

export interface BridgeSettings {
    readonly uuid: string;
    lpObSettings: SessionSettings[];
    brokerObSettings: SessionSettings[];
    brokerNewMwSettings: SessionSettings[];
    lpNewMwSettings: SessionSettings[];
}

export interface UserSettings {
    bridgeSettings: BridgeSettings[];
}

export interface UserDto {
    name: string;
    password: string;
    email: string;
    role: UserRole;
    reportsEnabled: boolean;
    uploadCSVEnabled: boolean;
    settings: UserSettings;
    repeatPassword: string;
    filterInterval: { [key: string]: { [key: string]: number } };
}
