import {Component, OnDestroy, OnInit} from '@angular/core';
import {BrokerSymbolsService} from '../commons/broker-symbols.service';
import {Subscription} from 'rxjs';
import {NotificationsService} from '../../commons/notifications.service';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {BrokerSession} from '../commons/model/symbol/broker/broker-session';

@Component({
    selector: 'app-broker-symbols',
    templateUrl: './broker-symbols.component.html',
    styleUrls: ['./broker-symbols.component.scss'],
    providers: [CallWrapperService]
})

export class BrokerSymbolsComponent implements OnInit, OnDestroy {
    sessions: BrokerSession[] = null;
    uuid: string;
    private _subscription: Subscription;

    constructor(private brokerSymbolService: BrokerSymbolsService,
                private callWrapper: CallWrapperService,
                private notifications: NotificationsService) {
    }

    ngOnInit(): void {
        this._subscription = this.callWrapper.withStableUuid(
            uuid => {
                this.uuid = uuid;
                return this.brokerSymbolService.getSessions(this.uuid);
            }, list => this.sessions = list);
    }

    sessionByName(index: number, item: BrokerSession): string {
        return item.sessionName;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private refreshSessions() {
        this.callWrapper.withLoader(this.brokerSymbolService.refreshSessions(this.uuid),
            data => {
                this.sessions = data;
                this.notifications.showSuccessMessage('Symbols refreshed');
            });
    }
}
