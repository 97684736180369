import {Attribute, Directive, forwardRef} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
    selector: '[appValidateEqual][formControlName],[appValidateEqual][formControl],[appValidateEqual][ngModel]',
    providers: [
        {provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true}
    ]
})
export class EqualValidator implements Validator {
    constructor(@Attribute('appValidateEqual') public validateEqual: string,
                @Attribute('reverse') public reverse: string) {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        // self value
        const currentValue = c.value;

        // control vlaue
        const comparedInput = c.root.get(this.validateEqual);

        // value not equal
        if (comparedInput && comparedInput.value && currentValue !== comparedInput.value) {
            return {
                validateEqual: true
            };
        }

        // value equal and reverse
        if (comparedInput && currentValue === comparedInput.value && this.isReverse) {
            if (comparedInput.errors) {
                delete comparedInput.errors['validateEqual'];

                if (!Object.keys(comparedInput.errors).length) {
                    comparedInput.setErrors(null);
                }
            }
        }

        // value not equal and reverse
        if (comparedInput && currentValue !== comparedInput.value && this.isReverse) {
            comparedInput.setErrors({validateEqual: true});
        }
        return null;
    }

    private get isReverse() {
        if (!this.reverse) {
            return false;
        }
        return this.reverse === 'true';
    }
}
