import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {EXPOSURE_RULES_COLUMNS, EXPOSURE_RULES_COLUMNS_FILTERS} from '../../helpers/table-columns.helper';
import {ExposureRulesTab} from '../../interfaces/exposure-rules.interface';

@Component({
  selector: 'app-exposure-rules-table',
  templateUrl: './exposure-rules-table.component.html',
  styleUrls: ['./exposure-rules-table.component.scss'],
})
export class ExposureRulesTableComponent {
  @Input() exposureRules: ExposureRulesTab;
  exposureRulesCols = EXPOSURE_RULES_COLUMNS;
  exposureRulesColsFilters = EXPOSURE_RULES_COLUMNS_FILTERS;

  constructor(private router: Router) {}

  public redirectToExposureRules(): void {
    this.router.navigate([`/app/bridge/${this.exposureRules.uuid}`], {
      queryParams: {
        exposure: true,
      },
    });
  }
}
