export interface CoverageRule {
    readonly uuid: string;
    readonly brokerName: string;
    readonly brokerGroup: string;
    readonly brokerAccount: string;
    readonly coveragePercent: number;
    readonly coverageWeight: number;
}


export function emptyCoverageRule(): CoverageRule {
    return {
        uuid: null,
        brokerGroup: null,
        brokerAccount: null,
        brokerName: null,
        coveragePercent: null,
        coverageWeight: 0
    };
}
