import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from './all-bridges/components/dashboard/dashboard.component';
import {LoginComponent} from './login/login.component';
import {AppHolderComponent} from './app-holder/app-holder/app-holder.component';
import {BridgeDetailsComponent} from './details-bridge/bridge-details.component';
import {SetupComponent} from './setup/view/setup.component';
import {UsersComponent} from './users/users.component';
import {ReportsComponent} from './reports/reports.component';
import {ChangeUserPasswordComponent} from './change-user-password/change-user-password.component';
import {MarketDepthComponent} from './market-depth/market-depth.component';
import {BridgeStatisticsComponent} from './bridge-statistics/bridge-statistics.component';
import {HedgeMonitorComponent} from './hedge-monitor/hedge-monitor.component';
import {AuthGuard} from './commons/auth-guard';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {BmLogsComponent} from './bm-logs/bm-logs.component';
import {MarketWatchComponent} from './market-watch/market-watch.component';
import {ExposureComponent} from './exposure/exposure.component';

export const APP_ROUTES_INIT: Routes = [
    {path: '', redirectTo: '/app/dashboard', pathMatch: 'full'},
    {
        path: 'app',
        component: AppHolderComponent,
        canActivate: [AuthGuard],
        children: [
            {path: '', redirectTo: '/app/dashboard', pathMatch: 'full'},
            {path: 'dashboard', component: DashboardComponent},
            {path: 'change-password', component: ChangeUserPasswordComponent},
            {path: 'bridge/:uuid', component: BridgeDetailsComponent},
            {path: 'market-depth', component: MarketDepthComponent},
            {path: 'market-watch', component: MarketWatchComponent},
            {path: 'users', component: UsersComponent},
            {path: 'reports', component: ReportsComponent},
            {path: 'statistics', component: BridgeStatisticsComponent},
            {path: 'hedge-monitor', component: HedgeMonitorComponent},
            {path: 'exposure', component: ExposureComponent},
            {path: 'logs', component: BmLogsComponent},
            {path: 'admin-dashboard', component: AdminDashboardComponent}
        ],
        data: {animation: 'AppHolder'}
    },
    {path: 'login', component: LoginComponent, data: {animation: 'Login'}},
    {path: 'setup', component: SetupComponent}
];

@NgModule({
    exports: [RouterModule]
})

export class AppRoutingModule {
}
