import {Injectable} from '@angular/core';
import {ErrorManagerService} from './error/error-manager.service';
import {LoaderService} from './loader.service';
import {Observable, of, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {catchError, switchMap, take, tap} from 'rxjs/operators';

@Injectable()
export class CallWrapperService {

    constructor(private errorManager: ErrorManagerService,
                private loaderService: LoaderService,
                private router: ActivatedRoute) {
    }

    withStableUuid<T>(emitter: (uuid: string) => Observable<T[]>, onEmit: (list: T[]) => void): Subscription {
        return this.router.params.pipe(
            switchMap(params => emitter(params['uuid'])
                .pipe(
                    catchError(err => {
                        this.errorManager.handle(err, {});
                        return of([]);
                    })
                ))
        ).subscribe(value => {
            onEmit(value);
        });
    }


    withStableUuidAndNotEmitIfError<T>(emitter: (uuid: string) => Observable<T>, onEmit: (object: T) => void): Subscription {
        return this.router.params.pipe(
            switchMap(params => emitter(params['uuid'])
                .pipe(
                    catchError(err => {
                        this.errorManager.handle(err, {});
                        return of(null);
                    })
                ))
        ).subscribe(value => {
            if (value) {
                onEmit(value);
            }
        });
    }

    withStableUuidAndLoader<T>(emitter: (uuid: string) => Observable<T[]>, onEmit: (list: T[]) => void): Subscription {
        return this.router.params.pipe(
            tap(() => this.loaderService.loading = true),
            switchMap(params => emitter(params['uuid'])
                .pipe(
                    catchError(err => {
                        this.errorManager.handle(err, {});
                        return of([]);
                    })
                ))
        ).subscribe(value => {
            onEmit(value);
            this.loaderService.loading = false;
        }, err => this.errorManager.handle(err, {
            onDone: () => this.loaderService.loading = false
        }));
    }

    withLoader<T>(emitter: Observable<T>, onEmit: (data: T) => void): Subscription {
        this.loaderService.loading = true;
        return emitter.subscribe(value => {
            onEmit(value);
            this.loaderService.loading = false;
        }, err => this.errorManager.handle(err, {
            onDone: () => this.loaderService.loading = false
        }));
    }

    withStartingLoader<T>(emitter: Observable<T>, onEmit: (data: T) => void): Subscription {
        this.loaderService.loading = true;
        return emitter.subscribe(value => {
            onEmit(value);
        }, err => this.errorManager.handle(err, {
            onDone: () => this.loaderService.loading = false
        }));
    }


    withEndingLoader<T>(emitter: Observable<T>, onEmit: (data: T) => void): Subscription {
        return emitter.subscribe(value => {
            onEmit(value);
            this.loaderService.loading = false;
        }, err => this.errorManager.handle(err, {
            onDone: () => this.loaderService.loading = false
        }));
    }

    withLoaderAndSingleUuid<T>(emitter: (uuid: string) => Observable<T>, onEmit: (results: T) => void) {
        this.loaderService.loading = true;
        this.router.params.pipe(
            take(1),
            switchMap(params => emitter(params['uuid']))
        ).subscribe(value => {
            onEmit(value);
            this.loaderService.loading = false;
        }, err => this.errorManager.handle(err, {
            onDone: () => this.loaderService.loading = false
        }));
    }

    withOnlyDisableLoaderAndSingleUuid<T>(emitter: (uuid: string) => Observable<T>, onEmit: (results: T) => void) {
        this.router.params.pipe(
            take(1),
            switchMap(params => emitter(params['uuid']))
        ).subscribe(value => {
            onEmit(value);
            this.loaderService.loading = false;
        }, err => this.errorManager.handle(err, {
            onDone: () => this.loaderService.loading = false
        }));
    }

    withSingleUuid<T>(emitter: (uuid: string) => Observable<T>, onEmit: (results: T) => void) {
        this.router.params.pipe(
            take(1),
            switchMap(params => emitter(params['uuid']))
        ).subscribe(value => {
            onEmit(value);
        });
    }
}
