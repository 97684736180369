import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[appBookProfitHighlight]'
})
export class BookProfitHighlightDirective {

    @Input() bookType: 'abook' | 'bbook' = 'abook';
    @Input()
    set profit(value: string) {
        const val = +value;
        const condition = this.bookType === 'abook' ? val < 0 : val > 0;
        if (val) {
            condition ? this.el.nativeElement.style.color = 'red' : this.el.nativeElement.style.color = 'green';
        }
    }

    constructor(private el: ElementRef) {
    }
}
