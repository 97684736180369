import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BrokerSymbol} from '../../../commons/model/symbol/broker/broker-symbol';
import {SetSymoblPrecisionService} from '../set-symobl-precision.service';
import {Subscription} from 'rxjs';
import {BrokerSessionType} from '../../../commons/model/status/broker-session-type';

@Component({
    selector: 'app-update-broker-symbol',
    templateUrl: './update-broker-symbol.component.html',
    styleUrls: ['./update-broker-symbol.component.scss']
})
export class UpdateBrokerSymbolComponent implements OnInit, OnDestroy {
    @Input() symbol: BrokerSymbol;
    @Input() isFOKLimitHedgingEnabled: boolean;
    @Input() brokerSessionType: BrokerSessionType;

    @Output() submit = new EventEmitter<void>();
    @Output() delete = new EventEmitter<void>();

    public brokerSessionTypeEnum = BrokerSessionType;
    public isAboveBid = false;
    public isAboveAsk = false;
    public step = 1;
    public limit = 100;

    private subscription: Subscription;

    constructor(private setPrecision: SetSymoblPrecisionService) {
    }

    ngOnInit() {
        this.subscription = this.setPrecision.precisionChange.subscribe(data => {
            if (!data['showUpdateDialog']) {
                this.isAboveAsk = false;
                this.isAboveBid = false;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    checkValueBid() {
        this.isAboveBid = this.isAbove(+this.symbol.bidMarkup, this.limit);
    }

    checkValueAsk() {
        this.isAboveAsk = this.isAbove(+this.symbol.askMarkup, this.limit);
    }

    private isAbove(diff: number, limit: number): boolean {
        return diff >= 0 ? diff > limit : diff < -limit;
    }
}
