import {Component, Input} from '@angular/core';
import {RevenueSummary} from '../../interfaces';

@Component({
  selector: 'app-total-block',
  templateUrl: './total-block.component.html',
  styleUrls: ['./total-block.component.scss']
})
export class TotalBlockComponent {
  @Input() totalData?: RevenueSummary;

  constructor() { }
}
