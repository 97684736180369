import {Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {TreeTableToggler} from 'primeng/primeng';

@Component({
  selector: 'app-clipped-table-column',
  templateUrl: './clipped-table-column.component.html',
  styleUrls: ['./clipped-table-column.component.scss']
})
export class ClippedTableColumnComponent implements OnInit {
  @Input() data: string | number | undefined;
  @Input() rowNode: TreeTableToggler;

  @ViewChild('clipped', { static: true }) clipped: TemplateRef<HTMLElement>;

  constructor(private viewContainerRef: ViewContainerRef) { }

  public ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.clipped);
  }
}
