import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CoverageRule} from '../model/coverage-rule';
import {CallWrapperService} from 'src/app/commons/call-wrapper.service';
import {BrokerSymbolsService} from '../../commons/broker-symbols.service';
import {Subscription} from 'rxjs';
import {SelectItem} from 'primeng/components/common/api';
import {CoverageRuleDisplay} from '../model/coverage-rule-display';
import {BMValidators} from '../../../commons/b-m-validators';

@Component({
    selector: 'app-coverage-rule',
    templateUrl: './coverage-rule.component.html',
    styleUrls: ['./coverage-rule.component.scss'],
    providers: [CallWrapperService]
})
export class CoverageRuleComponent implements OnInit, OnDestroy {

    @Input()
    set value(newState: CoverageRuleDisplay) {
        this._value = newState;
        if (newState) {
            this.form.patchValue(newState);
        } else {
            this.form.reset();
        }
    }

    get value() {
        return this._value;
    }

    @Output()
    update = new EventEmitter<CoverageRule>();

    @Output()
    delete = new EventEmitter<CoverageRule>();

    brokerName = new FormControl('', [Validators.required, Validators.maxLength(50)]);
    brokerGroup = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
    brokerAccount = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
    coveragePercent = new FormControl('', [
        Validators.required,
        Validators.min(-1000),
        Validators.max(Number.MAX_VALUE),
        Validators.pattern(/^-?\d+$/)
    ]);


    @Output()
    form = new FormGroup({
        uuid: new FormControl(),
        brokerName: this.brokerName,
        brokerGroup: this.brokerGroup,
        brokerAccount: this.brokerAccount,
        coveragePercent: this.coveragePercent,
    });

    brokerItems: SelectItem[] = [];

    private _value: CoverageRuleDisplay;
    private _subscription: Subscription;

    constructor(private callWrapper: CallWrapperService,
                private brokerSymbolService: BrokerSymbolsService) {
    }

    ngOnInit() {
        this._subscription = this.callWrapper.withStableUuid(uuid => this.brokerSymbolService.getBrokerSessionsNames(uuid),
            list => {
                this.brokerItems = list.map(el => {
                    return {label: el, value: el};
                });
            }
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    send() {
        this.update.emit(this.convertToCoverageRule(this.form.value) as CoverageRule);
    }

    deleteItem() {
        this.delete.emit(this.form.value as CoverageRule);
    }

    private convertToCoverageRule(coverageRuleDisp: CoverageRuleDisplay): CoverageRule {
        return {
            uuid: coverageRuleDisp.uuid,
            brokerGroup: coverageRuleDisp.brokerGroup,
            brokerName: coverageRuleDisp.brokerName.join(','),
            brokerAccount: coverageRuleDisp.brokerAccount,
            coveragePercent: coverageRuleDisp.coveragePercent,
            coverageWeight: coverageRuleDisp.weight
        };
    }
}
