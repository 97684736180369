import {Component, OnDestroy, OnInit} from '@angular/core';
import {BridgesPublisherService} from '../all-bridges/services/bridges-publisher.service';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {SelectItem} from 'primeng/api';
import {ChartDateRange} from './interfaces/date-range';
import {StatisticDataHttpService, StatisticDataStoreService} from './services';
import {ChartData, RevenueSummary} from './interfaces';
import {combineLatest, of, Subject} from 'rxjs';
import {DateStoreService} from './services/date-store.service';
import {StatisticBlock} from './enums';
import {DataChartsFormatter, DataForTableColumns, DateLabels} from './helpers';
import {BridgeSimpleStatus} from '../all-bridges/interfaces/bridge-simple-status';

@Component({
    selector: 'app-bridge-statistics',
    templateUrl: './bridge-statistics.component.html',
    styleUrls: ['./bridge-statistics.component.scss'],
    providers: [StatisticDataHttpService, StatisticDataStoreService, DateStoreService]
})
export class BridgeStatisticsComponent implements OnInit, OnDestroy {
    public totalSummary$ = this.statisticDataStore.totalSummary$;
    public totalBlock$ = this.statisticDataStore.revenueSummary$;
    public revenueSummary$ = this.statisticDataStore.revenue$;
    public clientsAllocation$ = this.statisticDataStore.clients$;
    public volumeTrends$ = this.statisticDataStore.volume$;
    public tradeTrends$ = this.statisticDataStore.trade$;
    public turnoversInstrument$ = this.statisticDataStore.turnoverInstrument$;
    public turnoversClient$ = this.statisticDataStore.turnoverClient$;
    public revenuesPM$ = this.statisticDataStore.revenuePM$;

    public dateLabels = DateLabels.getDateLabels();
    public shortDateLabels = DateLabels.getShortDateLabels();
    public turnoverInstrumentColumn = DataForTableColumns.TURNOVER_INSTRUMENT_TABLE;
    public turnoverClientColumn = DataForTableColumns.TURNOVER_CLIENT_TABLE;
    public revenueColumn = DataForTableColumns.REVENUE_TABLE;
    public bridgeSimpleStatusList: BridgeSimpleStatus[];
    public statisticBlockEnums = StatisticBlock;

    private readonly _completeSubject = new Subject<void>();

    bridges: SelectItem[] = [];

    constructor(
        private readonly statusPublisher: BridgesPublisherService,
        private readonly statisticDataHttp: StatisticDataHttpService,
        private readonly statisticDataStore: StatisticDataStoreService,
        private readonly dateStoreService: DateStoreService
        ) {}

    ngOnInit() {
        this.statusPublisher.bridgesInfo.pipe(
            take(1),
            tap(bridgeSimpleStatusList => this.bridgeSimpleStatusList = bridgeSimpleStatusList),
            map(bridgeSimpleStatusList => bridgeSimpleStatusList.map(el => this.mapToSelectItem(el))),
            filter(list => list.length > 0),
        ).subscribe(list => {
            this.bridges = list.sort((a, b) => a.label.localeCompare(b.label));
            this.dateStoreService.setSelectedBridge(this.bridges[0].value);
            this.initialization();
        });
    }

    public getTotalSummary(): void {
        combineLatest([this.dateStoreService.selectedBridge$, this.dateStoreService.totalSummaryDate$]).pipe(
            tap(() => this.statisticDataStore.setTotalSummary([])),
            switchMap(([bridgeName, totalSummaryDate]) => {
                return combineLatest([
                    this.statisticDataHttp.getClientsSummary(totalSummaryDate.date, bridgeName),
                    this.statisticDataHttp.getVolumeSummary(totalSummaryDate.date, bridgeName),
                    this.statisticDataHttp.getTransactionsSummary(totalSummaryDate.date, bridgeName)
                ]);
            }),
            takeUntil(this._completeSubject)
        ).subscribe(summaries => {
            this.statisticDataStore.setTotalSummary(DataChartsFormatter.getDoughnutChartData(summaries));
        });
    }

    public getRevenueSummaryData(): void {
        combineLatest([this.dateStoreService.selectedBridge$, this.dateStoreService.revenueSummaryDate$]).pipe(
            tap(() => {
                this.statisticDataStore.setRevenue(<ChartData>{});
                this.statisticDataStore.setRevenueSummary(<RevenueSummary>{});
            }),
            switchMap(([bridgeName, revenueSummaryDate]) => {
                return combineLatest([
                    this.statisticDataHttp.getRevenueSummary(revenueSummaryDate.date, bridgeName),
                    this.statisticDataHttp.getRevenue(revenueSummaryDate.date, bridgeName),
                    of(revenueSummaryDate.isLineChart)
                ]);
            }),
            takeUntil(this._completeSubject)
        ).subscribe(([revenueSummary, revenue, isLinerChart]) => {
            this.statisticDataStore.setRevenueSummary(revenueSummary);
            const diagramData = {...revenue};
            diagramData.datasets = DataChartsFormatter.getChartConfigurationData(diagramData.datasets, isLinerChart);
            this.statisticDataStore.setRevenue(diagramData);
        });
    }

    public getClientsAllocation(): void {
        combineLatest([this.dateStoreService.selectedBridge$, this.dateStoreService.clientsDate$]).pipe(
            tap(() => this.statisticDataStore.setClients(<ChartData>{})),
            switchMap(([bridgeName, clientsDate]) => {
                return combineLatest([
                    this.statisticDataHttp.getClients(clientsDate.date, bridgeName),
                    of(clientsDate.isLineChart)
                ]);
            }),
            takeUntil(this._completeSubject)
        ).subscribe(([clients, isLinerChart]) => {
            const diagramData = {...clients};
            diagramData.datasets = DataChartsFormatter.getChartConfigurationData(diagramData.datasets, isLinerChart);
            this.statisticDataStore.setClients(diagramData);

        });
    }

    public getVolumeTrends(): void {
        combineLatest([this.dateStoreService.selectedBridge$, this.dateStoreService.volumeDate$]).pipe(
            tap(() => this.statisticDataStore.setVolume(<ChartData>{})),
            switchMap(([bridgeName, volumeDate]) => {
                return combineLatest([
                    this.statisticDataHttp.getVolume(volumeDate.date, bridgeName),
                    of(volumeDate.isLineChart)
                ]);
            }),
            takeUntil(this._completeSubject)
        ).subscribe(([volume, isLinerChart]) => {
            const diagramData = {...volume};
            diagramData.datasets = DataChartsFormatter.getChartConfigurationData(diagramData.datasets, isLinerChart);
            this.statisticDataStore.setVolume(diagramData);

        });
    }

    public getTradeTrends(): void {
        combineLatest([this.dateStoreService.selectedBridge$, this.dateStoreService.tradeDate$]).pipe(
            tap(() => this.statisticDataStore.setTrade(<ChartData>{})),
            switchMap(([bridgeName, tradeDate]) => {
                return combineLatest([
                    this.statisticDataHttp.getTrade(tradeDate.date, bridgeName),
                    of(tradeDate.isLineChart)
                ]);
            }),
            takeUntil(this._completeSubject)
        ).subscribe(([trade, isLineChart]) => {
            const diagramData = {...trade};
            diagramData.datasets = DataChartsFormatter.getChartConfigurationData(diagramData.datasets, isLineChart);
            this.statisticDataStore.setTrade(diagramData);

        });
    }

    public getTableData(): void {
        combineLatest([this.dateStoreService.selectedBridge$, this.dateStoreService.tableData$]).pipe(
            tap(() => {
                this.statisticDataStore.setTurnoverClient(null);
                this.statisticDataStore.setTurnoverInstrument(null);
                this.statisticDataStore.setRevenuePM(null);
            }),
            switchMap(([bridgeName, tableData]) => {
                return combineLatest([
                    this.statisticDataHttp.getTurnoverClient(tableData.date, bridgeName),
                    this.statisticDataHttp.getTurnoverInstrument(tableData.date, bridgeName),
                    this.statisticDataHttp.getRevenuePM(tableData.date, bridgeName),
                ]);
            }),
            takeUntil(this._completeSubject)
        ).subscribe(([turnoverClient, turnoverInstrument, revenuePM]) => {
            this.statisticDataStore.setTurnoverClient(turnoverClient);
            this.statisticDataStore.setTurnoverInstrument(turnoverInstrument);
            this.statisticDataStore.setRevenuePM(revenuePM);
        });
    }

    public onChangeDateClick(date: ChartDateRange, section: StatisticBlock): void {
        switch (section) {
            case StatisticBlock.SUMMARY:
                this.dateStoreService.setTotalSummaryDate(date);
                break;
            case StatisticBlock.REVENUE:
                this.dateStoreService.setRevenueSummaryDate(date);
                break;
            case StatisticBlock.CLIENTS:
                this.dateStoreService.setClientsDate(date);
                break;
            case StatisticBlock.VOLUME:
                this.dateStoreService.setVolumeDate(date);
                break;
            case StatisticBlock.TRADE:
                this.dateStoreService.setTradeDate(date);
                break;
            case StatisticBlock.TABLE:
                this.dateStoreService.setTableDate(date);
                break;
        }
    }

    ngOnDestroy(): void {
        this._completeSubject.next();
        this._completeSubject.complete();
    }

    displayStatsOnBridgeChange(event) {
        this.dateStoreService.setSelectedBridge(event.value);
    }

    private mapToSelectItem(el: BridgeSimpleStatus): SelectItem {
        return {label: el.name, value: el.name};
    }

    private initialization(): void {
        this.getTotalSummary();
        this.getRevenueSummaryData();
        this.getClientsAllocation();
        this.getVolumeTrends();
        this.getTradeTrends();
        this.getTableData();
    }
}
