export interface PriceImprovementRule {
    readonly uuid: string;
    readonly brokerName: string;
    readonly instrument: string;
    readonly groupName: string;
    readonly account: string;
    readonly weight: number;
}


export function emptyPriceRule(): PriceImprovementRule {
    return {
        uuid: null,
        brokerName: null,
        instrument: null,
        groupName: null,
        account: null,
        weight: null
    };
}
