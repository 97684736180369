import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {BrokerSession} from '../../commons/model/symbol/broker/broker-session';

@Injectable()
export class BrokerHttpService {

  constructor(private http: HttpClient) {}

  public getBrokerSymbolsCsv(bridgeUUID: string, sessionName: string): void {
    const anchor = document.createElement('a');
    anchor.href = `${environment.hostUrl}/bridges/${bridgeUUID}/symbols/broker/${sessionName}/download-csv?ngsw-bypass=true`;
    anchor.click();
  }

  public uploadBrokerSymbolCsv(file: FormData, bridgeUUID: string, sessionName: string): Observable<BrokerSession> {
    return this.http.post<BrokerSession>(`${environment.hostUrl}/bridges/${bridgeUUID}/symbols/broker/${sessionName}/upload-csv`, file);
  }
}
