import {Injectable} from '@angular/core';
import {RxStompService} from '@stomp/ng2-stompjs';
import {Observable} from 'rxjs';
import {OrderBooksRequest} from './dto/order-books-request';
import {finalize, map} from 'rxjs/operators';
import {OrderBook} from './dto/order-book';
import {MarketWatchRow} from '../market-watch/model/market-watch-row';

@Injectable({
    providedIn: 'root'
})
export class OrderBookService {

    constructor(private stomp: RxStompService) {
    }

    watchToOrderBooks(request: OrderBooksRequest): Observable<OrderBook[]> {
        const reqId = Date.now() + '';
        this.stomp.publish({
            destination: '/app/order-book',
            body: JSON.stringify(request),
            headers: {
                'reqId': reqId
            }
        });

        return this.stomp.watch('/user/queue/order-books').pipe(
            map(value => JSON.parse(value.body) as OrderBook[]),
            finalize(() => this.stomp.publish({
                destination: '/app/order-book/dispose',
                headers: {
                    'reqId': reqId
                }
            }))
        );
    }

    watchToNewMarketWatchRow(request: OrderBooksRequest): Observable<MarketWatchRow[]> {
        const reqId = Date.now() + '';
        this.stomp.publish({
            destination: '/app/market-watch',
            body: JSON.stringify(request),
            headers: {
                'reqId': reqId
            }
        });

        return this.stomp.watch('/user/queue/market-watch').pipe(
            map(val => JSON.parse(val.body) as MarketWatchRow[]),
            finalize(() => this.stomp.publish({
                destination: '/app/market-watch/dispose',
                headers: {
                    'reqId': reqId
                }
            }))
        );
    }
}
