import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartData, DateRange, RevenuePerMillion, RevenueSummary, TurnoverInstrument, TurnoverClient } from '../interfaces';
import { environment } from '../../../environments/environment';
import { BridgeSimpleStatus } from '../../all-bridges/interfaces/bridge-simple-status';

@Injectable()
export class StatisticDataHttpService {
  private baseUri = `${environment.hostUrl}/statistics`;

  constructor(private readonly http: HttpClient) {}

  public getClientsSummary(dateRange: DateRange, bridgeName: string): Observable<ChartData> {
    return this.http.get<ChartData>(`${this.baseUri}/clients-number-summary?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`);
  }

  public getVolumeSummary(dateRange: DateRange, bridgeName: string): Observable<ChartData> {
    return this.http.get<ChartData>(`${this.baseUri}/volume-summary?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`);
  }

  public getTransactionsSummary(dateRange: DateRange, bridgeName: string): Observable<ChartData> {
    return this.http.get<ChartData>(`${this.baseUri}/transactions-number-summary?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`);
  }

  public getRevenueSummary(dateRange: DateRange, bridgeName: string): Observable<RevenueSummary> {
    return this.http.get<RevenueSummary>(`${this.baseUri}/a-book-revenue-summary?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`);
  }

  public getRevenue(dateRange: DateRange, bridgeName: string): Observable<ChartData> {
    return this.http.get<ChartData>(`${this.baseUri}/a-book-revenue?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`);
  }

  public getClients(dateRange: DateRange, bridgeName: string): Observable<ChartData> {
    return this.http.get<ChartData>(`${this.baseUri}/clients-number?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`);
  }

  public getVolume(dateRange: DateRange, bridgeName: string): Observable<ChartData> {
    return this.http.get<ChartData>(`${this.baseUri}/volume?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`);
  }

  public getTrade(dateRange: DateRange, bridgeName: string): Observable<ChartData> {
    return this.http.get<ChartData>(`${this.baseUri}/transactions-number?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`);
  }

  public getTurnoverInstrument(dateRange: DateRange, bridgeName: string): Observable<TurnoverInstrument[]> {
    return this.http.get<TurnoverInstrument[]>(`${this.baseUri}/turnover?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`)
        .pipe(map((turnovers) =>
            this.getSortedData(turnovers, 'totalVolume')
                .filter(turnover => turnover.totalVolume !== 0)));
  }

  public getTurnoverClient(dateRange: DateRange, bridgeName: string): Observable<TurnoverClient[]> {
    return this.http.get<TurnoverClient[]>(`${this.baseUri}/turnover-by-client?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`)
        .pipe(map((turnovers) =>
            this.getSortedData(turnovers, 'totalVolume')
                .filter(turnover => turnover.totalVolume !== 0)));
  }

  public getRevenuePM(dateRange: DateRange, bridgeName: string): Observable<RevenuePerMillion[]> {
    return this.http.get<RevenuePerMillion[]>(`${this.baseUri}/a-book-rpm?dbname=${bridgeName}&from=${dateRange.from}&to=${dateRange.to}`)
        .pipe(map((revenues) =>
            this.getSortedData(revenues, 'revenuePerMillion')
                .filter(revenue => revenue.revenuePerMillion !== 0)));
  }

  public getOpenPosition(bridge: BridgeSimpleStatus): Observable<ChartData> {
    return this.http.get<ChartData>(`${this.baseUri}/${bridge.uuid}/open-positions`);
  }

  private getSortedData<T>(data: T[], accessKey?: keyof T): T[] {
    const filteredData = accessKey
        ? data.sort((first, second) => (first[accessKey] < second[accessKey] ? -1 : 1))
        : data.sort((first, second) => (first < second ? -1 : 1));
    return filteredData.reverse();
  }
}
