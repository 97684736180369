import {Component, OnInit} from '@angular/core';
import {SetupService} from '../setup.service';
import {Router} from '@angular/router';
import {NotificationsService} from '../../commons/notifications.service';
import {LoaderService} from '../../commons/loader.service';
import {ErrorManagerService} from '../../commons/error/error-manager.service';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {

    password: string;
    repeatPassword: string;

    constructor(private setupService: SetupService,
                private router: Router,
                private notifications: NotificationsService,
                private errorManager: ErrorManagerService,
                private loadingService: LoaderService) {
    }

    ngOnInit() {
        this.setupService.isAdminExist()
            .pipe(filter(isExist => isExist))
            .subscribe(() => {
                this.notifications.showInfoMessage('System has already been configured. Back to login page.');
                this.router.navigateByUrl('/login');
            });
    }

    submit() {
        this.loadingService.loading = true;
        this.setupService.setConfiguration({password: this.password})
            .subscribe(() => {
                this.notifications.showSuccessMessage('System configuration completed. Now you can log in.');
                this.router.navigateByUrl('/login');
            }, err => this.errorManager.handle(err, {
                onDone: () => this.loadingService.loading = false
            }));
    }

}
