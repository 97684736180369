import { Injectable, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';

@Injectable()
export abstract class LazyLoadSymbols<T> {
  @ViewChild(Table, { static: true }) table: Table;
  public symbols: T[];
  public slicedSymbols: T[];
  public isLoading = false;
  public totalRecords: number;

  protected lazyLoadEvent: LazyLoadEvent;

  onSort(): void {
    const body = this.table.el.nativeElement.querySelector(
      '.ui-table-scrollable-body'
    );
    if (body) {
      body.scrollTop = 0;
    }
  }

  loadLazyData(event: LazyLoadEvent): void {
    this.lazyLoadEvent = event;
    this.isLoading = true;
    if (this.slicedSymbols && event) {
      const arr = this._applyFiltersAndSorting(this.slicedSymbols, event);
      this.totalRecords = arr.length;

      setTimeout(() => {
        this.symbols = arr.slice(event.first, event.first + event.rows);
        this.isLoading = false;
      }, 100);
    }
  }

  private _applyFiltersAndSorting(data: T[], event: LazyLoadEvent): T[] {
    let filteredAndSortedData = data;

    if (Object.entries(event.filters).length) {
      filteredAndSortedData = this._applyFilters(
        filteredAndSortedData,
        event.filters
      );
    }

    if (event.sortField && event.sortOrder) {
      filteredAndSortedData = this._applySorting(
        filteredAndSortedData,
        event.sortField,
        event.sortOrder
      );
    }

    return filteredAndSortedData;
  }

  private _applyFilters(data: T[], filters: { [key: string]: any }): T[] {
    if (!Object.entries(filters).length) {
      return data;
    }

    return data.filter((item) => {
      for (const field in filters) {
        if (filters.hasOwnProperty(field)) {
          const filterValue = filters[field].value;
          const itemValue = item[field];

          if (typeof filterValue === 'boolean' && itemValue !== filterValue) {
            return false;
          }

          if (
            typeof filterValue !== 'boolean' &&
            itemValue &&
            !itemValue
              .toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase())
          ) {
            return false;
          }
        }
      }

      return true;
    });
  }

  private _applySorting(data: T[], sortField: string, sortOrder: number): T[] {
    if (!sortField || sortOrder === undefined || sortOrder === 0) {
      return data;
    }

    return data.sort((a, b) => {
      const value1 = a[sortField];
      const value2 = b[sortField];

      if (value1 === value2) {
        return 0;
      }

      const result = value1 < value2 ? -1 : 1;
      return sortOrder === 1 ? result : -result;
    });
  }
}
