import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {BridgesPublisherService} from '../../all-bridges/services/bridges-publisher.service';
import {BridgeSimpleStatus} from '../../all-bridges/interfaces/bridge-simple-status';
import {SelectItem} from 'primeng/api';

@Injectable()
export class InitializationFilterService {
  constructor(private statusPublisher: BridgesPublisherService) { }

  public getFilter(): Observable<SelectItem[]> {
    return this.statusPublisher.bridgesInfo.pipe(
        take(1),
        map(bridgeSimpleStatusList => bridgeSimpleStatusList.map(el => this.mapToSelectItem(el))),
        filter(list => list.length > 0),
    );
  }

  private mapToSelectItem(bridgeStatus: BridgeSimpleStatus): SelectItem {
    return { label: bridgeStatus.name, value: bridgeStatus.uuid };
  }
}
