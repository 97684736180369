import {Injectable} from '@angular/core';
import {RxStompService} from '@stomp/ng2-stompjs';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {BridgeSimpleStatus} from '../../all-bridges/interfaces/bridge-simple-status';

@Injectable({
    providedIn: 'root'
})
export class BridgeStatusesService {

    constructor(private stomp: RxStompService) {
    }

    watchBridgeStatuses(): Observable<BridgeSimpleStatus> {
        return this.stomp.watch('/user/queue/bridge-statuses').pipe(
            map(value => JSON.parse(value.body) as BridgeSimpleStatus),
        );
    }
}
