import { Component } from '@angular/core';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { finalize, take, map } from 'rxjs/operators';
import { BrokerSymbolsService } from 'src/app/details-bridge/commons/broker-symbols.service';
import { CommonPage } from 'src/app/commons/interfaces/page';
import { ReportRecord, ReportSummary } from '../services/reports.service';
import { InitializationFilterService } from '../services/initialization-filter.service';
import { TicksHistoryHttpService } from '../services/ticks-history-http.service';
import { TicksMonitoringSummary } from '../interfaces/ticks-history.interface';
import { BrokerSymbolTick } from '../interfaces/ticks-history.interface';
import { FilterParametersHelper } from '../helpers';
import { BrokerSession } from 'src/app/details-bridge/commons/model/symbol/broker/broker-session';

interface TicksHistoryFilters {
  bridgeUuid: string;
  from: Date;
  to: Date;
  symbol: string;
  brokerName: string;
  size?: number;
}

@Component({
  selector: 'app-ticks-history',
  templateUrl: './ticks-history.component.html',
  styleUrls: ['./ticks-history.component.scss'],
  providers: [
    FilterParametersHelper,
    InitializationFilterService,
    TicksHistoryHttpService,
  ],
})
export class TicksHistoryComponent {
  public filters: TicksHistoryFilters;
  public bridges: SelectItem[] = [];
  public loading = false;
  public ticksPaginationLoading = false;
  public reportRecords: ReportRecord[] = [];
  public reportSummary: ReportSummary = {} as ReportSummary;
  public sessions: SelectItem[] = [];
  public symbols: SelectItem[] = [];
  public ticksHistory: CommonPage<BrokerSymbolTick>;
  public ticksHistorySummary: TicksMonitoringSummary;
  public first = 0;

  constructor(
    private initializationFilter: InitializationFilterService,
    private brokerSymbolService: BrokerSymbolsService,
    private ticksHistoryHttp: TicksHistoryHttpService
  ) {
    this._setDate();
    this._loadBridges();
  }

  search(): void {
    this.loading = true;
    const { bridgeUuid, ...params } = this.filters;
    this.first = 0;
    const ticksHistoryReq = this.ticksHistoryHttp.getTicksHistory(bridgeUuid, {
      page: 0,
      size: 15,
      ...params,
      ...this._convertFilterToISODate(params),
    });

    const ticksHistorySummaryReq = this.ticksHistoryHttp.getTicksHistorySummary(
      bridgeUuid,
      { ...params, ...this._convertFilterToISODate(params) }
    );
    forkJoin([ticksHistoryReq, ticksHistorySummaryReq])
      .pipe(
        take(1),
        finalize(() => (this.loading = false))
      )
      .subscribe(([ticksHistory, ticksHistorySummary]) => {
        this.ticksHistory = ticksHistory;
        this.ticksHistorySummary = ticksHistorySummary;
      });
  }

  downLoadReportClick(): void {
    const { bridgeUuid, ...params } = this.filters;
    this.ticksHistoryHttp.downloadCsv(bridgeUuid, {
      ...params,
      ...this._convertFilterToISODate(params),
    });
  }

  handleBridgeChange(): void {
    this._loadSessions();
  }

  handleSessionNameChange(selectedSessionName: string): void {
    this._loadSymbols(this.filters.bridgeUuid, selectedSessionName);
  }

  nextPage({ rows, first }: LazyLoadEvent): void {
    const { bridgeUuid, ...params } = this.filters;
    if (!bridgeUuid || !params.symbol) {
      return;
    }
    this.ticksPaginationLoading = true;
    const pagination = { size: rows };
    this.filters = {
      ...this.filters,
      ...pagination,
    };
    this.ticksHistoryHttp
      .getTicksHistory(bridgeUuid, {
        ...params,
        ...this._convertFilterToISODate(params),
        ...pagination,
        page: first / rows,
      })
      .pipe(take(1))
      .subscribe((ticksHistory) => {
        this.ticksHistory = ticksHistory;
        this.ticksPaginationLoading = false;
      });
  }

  onChangeDate(isFromDate: boolean): void {
    const targetDate = isFromDate ? this.filters.from : this.filters.to;
    const offset = (isFromDate ? 1 : -1) * 4 * 60 * 60 * 1000;
    const updatedDate = new Date(targetDate.getTime() + offset);
    if (!isFromDate) {
      if (this.filters.from && this.filters.from < updatedDate) {
        this._updateFilters({ from: updatedDate });
      }
    } else {
      if (this.filters.to && this.filters.to > updatedDate) {
        this._updateFilters({ to: updatedDate });
      }
    }
  }

  private _loadBridges(): void {
    this.initializationFilter
      .getFilter()
      .pipe(take(1))
      .subscribe((bridges) => {
        this.bridges = bridges;
        this._updateFilters({ bridgeUuid: bridges[0].value });
        this._loadSessions();
      });
  }

  private _loadSessions(): void {
    this.sessions = [];
    this.symbols = [];
    this.brokerSymbolService
      .getSessions(this.filters.bridgeUuid)
      .pipe(take(1))
      .subscribe((sessions) => this._handleSessionsUpdate(sessions));
  }

  private _handleSessionsUpdate(sessions: BrokerSession[]): void {
    if (!sessions.length) {
      this._updateFilters({ symbol: null, brokerName: null });
      return;
    }

    const { sessionName } = sessions[0];
    this.sessions = sessions.map(({ sessionName: label }) => ({
      label,
      value: label,
    }));
    this._updateFilters({ brokerName: sessionName });

    this._loadSymbols(this.filters.bridgeUuid, sessionName);
  }

  private _loadSymbols(bridgeUuid: string, sessionName: string): void {
    this.symbols = [];

    this.ticksHistoryHttp
      .getTicksMonitoringSymbols(bridgeUuid, sessionName)
      .pipe(
        map((symbols) => symbols.sort((a, b) => a.localeCompare(b))),
        take(1)
      )
      .subscribe((symbols) => this._handleSymbolsUpdate(symbols));
  }

  private _handleSymbolsUpdate(symbols: string[]): void {
    if (!symbols.length) {
      this._updateFilters({ symbol: null });
      return;
    }

    this.symbols = symbols.map((symbolName) => ({
      label: symbolName,
      value: symbolName,
    }));

    this._updateFilters({ symbol: symbols[0] });
  }

  private _setDate(): void {
    const from = new Date();
    from.setMinutes(from.getMinutes() - 60);
    from.setSeconds(0, 0);

    const to = new Date();
    to.setSeconds(0, 0);

    this._updateFilters({ from, to });
  }

  private _updateFilters(filters: Partial<TicksHistoryFilters>): void {
    this.filters = {
      ...this.filters,
      ...filters,
    };
  }

  private _convertFilterToISODate({ from, to }: Partial<TicksHistoryFilters>): {
    from: string;
    to: string;
  } {
    return {
      to: to.toISOString(),
      from: from.toISOString(),
    };
  }
}
