import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CoverageRules} from './model/coverage-rules';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {CoverageRule} from './model/coverage-rule';
import {BRIDGE_URL} from '../../utils/constants';
import {RuleService} from '../commons/interfaces/rule-service.interface';

@Injectable({
    providedIn: 'root'
})
export class CoverageRuleService implements RuleService<CoverageRules, CoverageRule> {

    readonly PATH = '/rules/coverage';

    constructor(private http: HttpClient) {
    }

    getRules(uuid: string): Observable<CoverageRules> {
        return this.http.get<CoverageRules>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}`);
    }

    applyChanges(uuid: string): Observable<void> {
        return this.http.post<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/reload`, null);
    }

    refreshRules(uuid: string): Observable<CoverageRules> {
        return this.http.post<CoverageRules>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/refresh`, null);
    }

    delete(uuid: string, id: string): Observable<void> {
        return this.http.delete<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/${id}`);
    }

    update(uuid: string, model: CoverageRule): Observable<void> {
        return this.http.put<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}/${model.uuid}`, model);
    }

    create(uuid: string, model: CoverageRule): Observable<void> {
        return this.http.post<void>(`${environment.hostUrl}${BRIDGE_URL}/${uuid}${this.PATH}`, model);
    }
}
