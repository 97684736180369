export const VOLUME_PROFIT_CHART_OPTIONS = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true
        },
    },
    tooltips: {
        intersect: false,
        callbacks: {
            title: function() {}
        }
    },
    scales: {
      yAxes: [{
        minBarLength: 1,
        ticks: {
          beginAtZero: true
        }
      }]
    }
};
