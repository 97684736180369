import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BrokerSymbolsService} from '../../commons/broker-symbols.service';
import {BrokerSymbol} from '../../commons/model/symbol/broker/broker-symbol';
import * as _ from 'lodash';
import {NotificationsService} from '../../../commons/notifications.service';
import {ConfirmationService, SelectItem} from 'primeng/api';
import {BrokerSession} from '../../commons/model/symbol/broker/broker-session';
import {BrokerSession as BrokerSessionStatus} from '../../commons/model/status/broker-session';

import {CallWrapperService} from '../../../commons/call-wrapper.service';
import {BridgeDetailsPublisherService} from '../../commons/bridge-details-publisher.service';
import {distinctUntilChanged, finalize, map, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {SetSymoblPrecisionService} from './set-symobl-precision.service';
import {AuthenticationService} from '../../../commons/security/authentication.service';
import {BrokerHttpService, TicksHttpService} from '../services';
import {UserRole} from '../../../commons/dto/user-role';
import {FileUpload} from 'primeng/fileupload';
import {BridgeValidHelper} from '../../helper';
import {BrokerSessionType} from '../../commons/model/status/broker-session-type';
import {LazyLoadSymbols} from '../../commons/abstract/lazy-load-symbols.abstract';

@Component({
    selector: 'app-broker-session-symbols',
    templateUrl: './broker-session-symbols.component.html',
    styleUrls: ['./broker-session-symbols.component.scss'],
    providers: [CallWrapperService, BrokerHttpService, BridgeValidHelper, TicksHttpService]
})
export class BrokerSessionSymbolsComponent extends LazyLoadSymbols<BrokerSymbol> implements OnInit, OnDestroy {
    @Input() session: BrokerSession;
    @Input() bridgeUuid: string;
    @Output() refresh = new EventEmitter<void>();

    public brokerSessionType: BrokerSessionType;
    public isUserValid?: boolean;
    public isBridgeVersionValid?: boolean;
    public displayAddDialog = false;
    public displayUpdateDialog = false;
    public displayTicksMonitoring = false;
    public newSymbol: BrokerSymbol;
    public selectedSymbol: BrokerSymbol;
    public isFOKLimitHedgingEnabled: boolean;
    public isIOCLimitHedgingEnabled: boolean;
    public brokerSessionInfo: BrokerSessionStatus = null;
    public currentBid: string;
    public currentAsk: string;
    public filterEnabled: SelectItem[] = [
        {label: 'All', value: null},
        {label: 'Enabled', value: true},
        {label: 'Disabled', value: false},
    ];
    public isRefreshButtonLoading = false;

    private _subscription: Subscription;
    private _getUsersSub: Subscription;

    constructor(private brokerSymbolService: BrokerSymbolsService,
                private notifications: NotificationsService,
                private confirmationService: ConfirmationService,
                private callWrapper: CallWrapperService,
                private bridgeDetailsPublisher: BridgeDetailsPublisherService,
                private setPrecisionService: SetSymoblPrecisionService,
                private authenticationService: AuthenticationService,
                private brokerHttp: BrokerHttpService,
                private bridgeValid: BridgeValidHelper
    ) {
        super();
    }

    ngOnInit() {
        this.setState(this.session);

        this._getUsersSub = this.authenticationService.userInfo.subscribe(userInfo => {
            this.isUserValid = userInfo.role === UserRole.ADMIN || userInfo.uploadCSVEnabled;
        });

        this._subscription = this.bridgeDetailsPublisher
            .bridgeInfo
            .pipe(
                distinctUntilChanged((x, y) => _.isEqual(x.brokerSessions, y.brokerSessions)),
                tap(bridgeDetails => this.isBridgeVersionValid = this.bridgeValid.isBridgeVersionAbove(bridgeDetails.version.split('-')[0], '6.2')),
                tap(bridgeDetails => this.brokerSessionType = bridgeDetails.brokerSessionType),
                map(value => value.brokerSessions),
            ).subscribe(tab => this.brokerSessionInfo = tab === null ? null : tab.find(el => el.sessionName === this.session.sessionName));
    }

    public getBrokerSymbolsCsv(): void {
        this.brokerHttp.getBrokerSymbolsCsv(this.bridgeUuid, this.session.sessionName);
    }

    public uploadBrokerSymbolCsv(event: FileUpload, fileUpload: FileUpload) {
        this.confirmationService.confirm({
            icon: 'pi pi-exclamation-triangle',
            header: 'Upload confirmation',
            message: 'Uploading symbols via a file replaces all symbols that are currently in the broker symbols configuration. Please make sure that the file you have prepared is correct.',
            accept: () => {
                const formData: FormData = new FormData();
                formData.append('file', event.files[0]);
                this.brokerHttp.uploadBrokerSymbolCsv(formData, this.bridgeUuid, this.session.sessionName)
                    .pipe(finalize(() => fileUpload.clear()))
                    .subscribe(() => {
                        this.notifications.showSuccessMessage('Broker Symbol Upload', 'Broker symbol uploaded successfully');
                        this.reloadSymbols();
                    });
            },
            reject: () => {
                fileUpload.clear();
            }
        });
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
        this._getUsersSub.unsubscribe();
    }

    cancelUpdate() {
        this.selectedSymbol = null;
        this.displayUpdateDialog = false;
        this.setPrecisionService.setCurrentSymbolPrecisionAndBidAskValues(0, false);
    }

    cancelAdd() {
        this.newSymbol = null;
        this.displayAddDialog = false;
    }

    hideTicksMonitoring() {
        this.displayTicksMonitoring = false;
    }

    showUpdateSymbolDialog(event) {
        this.displayUpdateDialog = true;
        const brokerSymbol: BrokerSymbol = event.data as BrokerSymbol;

        this.setPrecisionService.setCurrentSymbolPrecisionAndBidAskValues(brokerSymbol.precision, this.displayUpdateDialog);
        this.selectedSymbol = _.cloneDeep(brokerSymbol);
        this.currentBid = brokerSymbol.bidMarkup.toString();
        this.currentAsk = brokerSymbol.askMarkup.toString();
    }

    updateSymbol() {
        let message = 'Are you sure that you want update this symbol? Please remember that changes will be applied in the bridge immediately.';
        let acceptLabel = 'Yes';
        let rejectLabel = 'No';

        const bid = this.selectedSymbol.bidMarkup.toString();
        const ask = this.selectedSymbol.askMarkup.toString();

        if ((this.brokerSessionInfo === undefined || this.brokerSessionInfo === null || this.brokerSessionInfo.feederSession === null) && (this.currentAsk !== ask || this.currentBid !== bid) && (+bid > 0 || +ask > 0)) {
            message = 'Please don\'t set up markups on bridge if your price session is not connected. Your clients will not see applied markups in their platforms.';
            acceptLabel = 'Accept';
            rejectLabel = 'Cancel';
        }

        this.confirmationService.confirm({
            message: message,
            acceptLabel: acceptLabel,
            rejectLabel: rejectLabel,
            accept: () => {
                this.callWrapper.withStartingLoader(
                    this.brokerSymbolService.updateSymbol(
                        this.bridgeUuid,
                        this.selectedSymbol
                    ), () => {
                        this.notifications.showSuccessMessage('Symbol updated!', 'Changes applied in the bridge!');
                        this.reloadSymbols();
                    });
                this.selectedSymbol = null;
                this.displayUpdateDialog = false;
            }
        });
    }

    deleteSymbol() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want remove this symbol? Please remember that changes will be applied in the bridge immediately.',
            accept: () => {
                this.callWrapper.withStartingLoader(
                    this.brokerSymbolService.deleteSymbol(
                        this.bridgeUuid,
                        this.selectedSymbol.id
                    ), () => {
                        this.reloadSymbols();
                        this.notifications.showSuccessMessage('Symbol removed!', 'Changes applied in the bridge!');
                    });
                this.selectedSymbol = null;
                this.displayUpdateDialog = false;
            }
        });
    }

    refreshSymbols() {
        this.isRefreshButtonLoading = true;
        this.callWrapper.withLoader(
            this.brokerSymbolService
                .refreshSymbol(this.bridgeUuid, this.session.sessionName),
            session => {
                this.session = session;
                this.setState(session);
                this.refresh.emit();
                this.isRefreshButtonLoading = false;
                this.notifications.showSuccessMessage('Symbols refreshed!');
            });
    }

    restartSession() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want restart session?',
            accept: () => {
                this.callWrapper.withLoader(
                    this.brokerSymbolService
                        .restartSession(this.bridgeUuid, this.session.sessionName),
                    () =>
                        this.notifications
                            .showSuccessMessage('Session is restarting. Check recent logs'));
            }
        });

    }

    addNewSymbol() {
        this.confirmationService.confirm({
            message: 'Are you sure that you want add this symbol? ' +
                'Please remember that changes will be applied in the bridge immediately.',
            accept: () => {
                this.callWrapper.withStartingLoader(this.brokerSymbolService.addNewSymbol(
                    this.bridgeUuid, {
                        sessionName: this.session.sessionName,
                        symbol: this.newSymbol
                    }
                ), () => {
                    this.reloadSymbols();
                    this.notifications.showSuccessMessage('Symbol saved!', 'Changes applied in the bridge!');
                });
                this.newSymbol = null;
                this.displayAddDialog = false;
            }
        });
    }

    openTicksNonitorPopup() {
        this.displayTicksMonitoring = true;
    }

    trackByFn(index: number, item: BrokerSymbol): number {
        return item.id;
    }

    private reloadSymbols() {
        this.callWrapper.withEndingLoader(this.brokerSymbolService.getSession(this.bridgeUuid, this.session.sessionName),
            data => {
                this.session = data;
                this.setState(this.session);
                this.notifications.showSuccessMessage('Symbols refreshed!');
            });
    }

    private setState(session: BrokerSession) {
        this.slicedSymbols = session.symbols;
        this.loadLazyData(this.lazyLoadEvent);
        this.isFOKLimitHedgingEnabled = session.fokOrderType && session.fokOrderType.length !== 0;
        this.isIOCLimitHedgingEnabled = session.iocOrderType && session.iocOrderType.length !== 0;
    }

    private showNewSymbolDialog() {
        this.newSymbol = new BrokerSymbol();
        if (!this.isFOKLimitHedgingEnabled) {
            this.newSymbol.limitDeviation = '0';
        }

        this.newSymbol.enabled = true;
        this.newSymbol.bidMarkup = '0';
        this.newSymbol.askMarkup = '0';
        this.newSymbol.coverageRatio = 100;
        this.newSymbol.bbookPriceMultiplier = '1';
        this.newSymbol.bbookVolumeMultiplier = '1';
        this.newSymbol.minSpread = '0';
        this.newSymbol.max1Spread = '0';
        this.newSymbol.max2Spread = '0';
        this.newSymbol.bbookExecDelay = 0;
        this.displayAddDialog = true;
    }
}
