export const environment = {
    production: true,
    hostUrl: '/api',
    wsPath: '/api/stream',
    statusRefreshInterval: 30000,
    detailsRefreshInterval: 30000,
    logsRefreshInterval: 5000,
    fullFIXTraceLoggingTime: 60000,
    hedgeMonitorRefreshInterval: 25000,
    pentahoUrl: 'https://reports.match-trade.com/pentaho/',
    orderBookLimit: 20,
    grafanaUrl: 'https://bridge.match-trade.com/mtstats/d/zZlY9_HMk/bridge-statistics?orgId=1&refresh=15s&kiosk&var-uuid=',
    gitlab: 'http://gitlab',
    refreshTimeAdminDashboard: 12000,
    jenkins: 'http://jenkins:8080',
    jenkinsinstallbridge: 'KNpxGG8BaMiKehPvTVzR',
    jenkinsinstallgatewayapi: 'INpxGG8BaMiKehPvSlxC'
};
