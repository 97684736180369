import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ChartDateRange, DateRange} from '../../interfaces/date-range';
import {ColumnData} from '../../../commons/interfaces';
import {DateSnack, RevenuePerMillion, TurnoverInstrument, TurnoverClient} from '../../interfaces';

@Component({
  selector: 'app-open-position',
  templateUrl: './open-position.component.html',
  styleUrls: ['./open-position.component.scss']
})
export class OpenPositionComponent implements OnChanges {
  @Input() turnoverInstrumentColumn: ColumnData[];
  @Input() turnoverClientColumn: ColumnData[];
  @Input() revenueColumn: ColumnData[];
  @Input() turnoversInstrument: TurnoverInstrument[] | null;
  @Input() turnoversClient: TurnoverClient[] | null;
  @Input() revenues: RevenuePerMillion[] | null;
  @Input() dateLabels: DateSnack[];
  @Input() shortDateLabels: DateSnack[];

  @Output() changeDateClick = new EventEmitter<ChartDateRange>();

  public selectedLabel: string | null = null;
  public readonly options = {
    legend: false,
    cutoutPercentage: '80',
    responsive: true,
    maintainAspectRatio: true,
    tooltips: {
      enabled: false
    }
  };

  public readonly hoverLabel = {
    id: 'hoverLabel',
    afterDraw(chart) {
      const { ctx } = chart;
      ctx.save();

      if (chart.config.data.datasets[0]) {
        let textLabel = chart.config.data.labels[0];
        let numberLabel = chart.config.data.datasets[0].data[0];

        if (chart.active && chart.active.length > 0) {
          textLabel = chart.config.data.labels[chart.active[0]._index];
          numberLabel = chart.config.data.datasets[0].data[chart.active[0]._index];
        }

        ctx.textAlign = 'center';
        ctx.fillStyle = '#14284f';

        if (textLabel && numberLabel) {
          ctx.font = 'bold 20px Montserrat, sans-serif';
          ctx.fillText(`${textLabel}: ${numberLabel}`, chart.width / 2, (chart.height + 20) / 2);
        } else {
          ctx.font = '600 16px Montserrat, sans-serif';
          ctx.fillText(`Currently, there are no`, chart.width / 2, (chart.height + 20) / 2);
          ctx.fillText(`open positions on your bridge.`, chart.width / 2, (chart.height + 55) / 2);
        }
      }
      ctx.restore();
    }
  };

  ngOnChanges({shortDateLabels}: SimpleChanges): void {
    if (shortDateLabels && shortDateLabels.currentValue) {
      this.selectedLabel = shortDateLabels.currentValue[0].value;
    }
  }

  public onSetDateRangeClick(dateRange: DateRange): void {
    this.selectedLabel = null;
    this.changeDateClick.emit({
      date: {
        from: dateRange.from,
        to: dateRange.to
      }
    });
  }

  public onSelectedDateChange(selectedDate: string): void {
    this.selectedLabel = selectedDate;
    this.changeDateClick.emit({
      date: {
        from: selectedDate,
        to: this.dateLabels[0].value
      }
    });
  }
}
