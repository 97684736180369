import {Component, Input} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {BOOK_COLUMNS} from '../../helpers/table-columns.helper';
import {Book} from '../../interfaces/book.interface';
import {BooksTable, IsChildExpandedMap} from '../../interfaces/books-table.interface';
import {BBookHedgeService} from '../../services/b-book-hedge.service';
import {BooksTableService} from '../../services/books-table.service';

@Component({
  selector: 'app-books-tables',
  templateUrl: './books-tables.component.html',
  styleUrls: ['./books-tables.component.scss'],
})
export class BooksTablesComponent {
  @Input() isShowUnits: boolean;
  @Input() set bridgeBooksTable(value: BooksTable) {
    if (!value) {
      this.abooks = [];
      this.bbooks = [];
      return;
    }
    const { bridgeUuid, bridgeName, abookTable, bbookTable } = value;

    this.abooks = this.booksTableService.convertBooksToTreeNodes(
      abookTable,
      this.isAbookChildExpandedMap,
      bridgeUuid,
      bridgeName
    );
    this.bbooks = this.booksTableService.convertBooksToTreeNodes(
      bbookTable,
      this.isBbookChildExpandedMap,
      bridgeUuid,
      bridgeName
    );
  }

  abooks: TreeNode[] = [];
  bbooks: TreeNode[] = [];

  abookCols = BOOK_COLUMNS;
  bbookCols = BOOK_COLUMNS;

  allGroups: string;
  selectedAccountsToHedge: string;
  selectedBridgeToHedge: string;
  showHedgeAbookConfirmDialog = false;
  selectedInstrument: Book;

  isBbookChildExpandedMap: IsChildExpandedMap = {};
  isAbookChildExpandedMap: IsChildExpandedMap = {};

  constructor(
    private booksTableService: BooksTableService,
    private bbookHedge: BBookHedgeService
  ) {}

  public onNodeExpandCollapse(
    childExpandedMap: IsChildExpandedMap,
    symbolName: string,
    isExpanded: boolean
  ): void {
    childExpandedMap[symbolName] = isExpanded;
  }

  public selectInstrumentToHedge(rowData: Book): void {
    const { allGroups, selectedAccountsToHedge, selectedBridgeToHedge } =
      this.bbookHedge.selectInstrumentToHedge(rowData, this.bbooks);
    this.allGroups = allGroups;
    this.selectedAccountsToHedge = selectedAccountsToHedge;
    this.selectedBridgeToHedge = selectedBridgeToHedge;

    this.selectedInstrument = rowData;
    this.showHedgeAbookConfirmDialog = true;
  }

  public hedgeAbook(
    bbook: Book,
    allGroups: string,
    selectedAccountsToHedge: string,
    selectedBridgeToHedge: string
  ): void {
    this.bbookHedge.hedgeAbook(
      bbook,
      allGroups,
      selectedAccountsToHedge,
      selectedBridgeToHedge
    );
  }

  public rejectHedgeRule(): void {
    this.selectedInstrument = null;
    this.showHedgeAbookConfirmDialog = false;
  }
}
