import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {BMValidators} from '../../../commons/b-m-validators';
import {SelectItem} from 'primeng/api';
import {Subscription} from 'rxjs';
import {CallWrapperService} from '../../../commons/call-wrapper.service';
import {BrokerSymbolsService} from '../../commons/broker-symbols.service';
import {DelayExecutionRuleDisplay} from '../model/delay-execution-rule-display';
import {DelayExecutionRule} from '../model/delay-execution-rule';

@Component({
    selector: 'app-new-delay-execution-rule',
    templateUrl: './new-delay-execution-rule.component.html',
    styleUrls: ['./new-delay-execution-rule.component.scss']
})
export class NewDelayExecutionRuleComponent implements OnInit, OnDestroy {

    @Input()
    set value(newState: DelayExecutionRuleDisplay) {
        this._value = newState;
        if (newState) {
            this.form.patchValue(this._value);
        } else {
            this.form.reset();
        }
    }

    get value() {
        return this._value;
    }

    @Output()
    update = new EventEmitter<DelayExecutionRule>();

    @Output()
    delete = new EventEmitter<DelayExecutionRule>();

    brokerName = new FormControl('', [Validators.required, Validators.maxLength(50)]);
    instrument = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
    groupName = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
    account = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50)]);
    delayFrom = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50),
        Validators.required,
        Validators.min(0),
        Validators.max(Number.MAX_VALUE),
        Validators.pattern(/^\d+$/)]);
    delayTo = new FormControl('', [Validators.required, BMValidators.blank, Validators.maxLength(50),
        Validators.required,
        Validators.min(this.delayFrom.value),
        Validators.max(Number.MAX_VALUE),
        Validators.pattern(/^\d+$/)]);


    @Output()
    form = new FormGroup({
        uuid: new FormControl(),
        brokerName: this.brokerName,
        instrument: this.instrument,
        groupName: this.groupName,
        account: this.account,
        delayFrom: this.delayFrom,
        delayTo: this.delayTo
    });

    brokerItems: SelectItem[] = [];

    private _value: DelayExecutionRuleDisplay;
    private _subscription: Subscription;

    constructor(private callWrapper: CallWrapperService,
                private brokerSymbolService: BrokerSymbolsService) {
    }

    ngOnInit(): void {
        this._subscription = this.callWrapper.withStableUuid(uuid => this.brokerSymbolService.getBrokerSessionsNames(uuid),
            brokerSessions => {
                this.brokerItems = brokerSessions.map(el => {
                    return {label: el, value: el};
                });
            }
        );
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    send(): void {
        this.update.emit(this.convertToDelayExecutionRule(this.form.value) as DelayExecutionRule);
    }

    deleteItem(): void {
        this.delete.emit(this.form.value as DelayExecutionRule);
    }

    private convertToDelayExecutionRule(rule: DelayExecutionRuleDisplay): DelayExecutionRule {
        return {
            account: rule.account,
            brokerName: rule.brokerName.join(','),
            instrument: rule.instrument,
            groupName: rule.groupName,
            uuid: rule.uuid,
            delayTo: rule.delayTo,
            delayFrom: rule.delayFrom,
            weight: rule.weight
        };
    }
}

