import {Component, OnDestroy} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {BridgeSimpleStatusService} from '../../all-bridges/services/bridge-simple-status.service';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {BridgesPublisherService} from '../../all-bridges/services/bridges-publisher.service';
import {LoaderService} from '../../commons/loader.service';
import {UserRole} from '../../commons/dto/user-role';
import {BridgeSimpleStatus} from '../../all-bridges/interfaces/bridge-simple-status';
import * as _ from 'lodash';
import {filter, switchMap, tap} from 'rxjs/operators';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnDestroy {
    items: MenuItem[];

    private _subscription: Subscription;

    constructor(private bridgeSimpleStatusService: BridgeSimpleStatusService,
                private authenticationService: AuthenticationService,
                private loaderService: LoaderService,
                private statusPublisher: BridgesPublisherService) {


        this._subscription = this.authenticationService
            .userInfo
            .pipe(
                filter(value => value !== null),
                tap(auth => {
                    this.initialItems();
                    if (auth.role === UserRole.ADMIN) {
                        this.addAdminTabs();
                    } else if (auth.role === UserRole.USER) {
                        this.addPasswordTab();
                    }
                    if (auth.reportsEnabled) {
                        this.addReportsTab();
                    }
                    this.addBridgesRoot();
                    this.addMarketWatchTab();
                    this.addNewMarketWatchTab();
                    this.addStatisticsTab();
                    this.addExposureTab();
                    this.addHedgeMonitorTab();
                }),
                switchMap(() => this.statusPublisher.bridgesInfo)
            )
            .subscribe(bridges => this.addBridges(_.sortBy(bridges, ['name'])));
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    private initialItems() {
        this.items = [{
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/app/dashboard']
        }];
    }

    private addReportsTab() {
        this.items.push({
            label: 'Reports',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: ['/app/reports']
        });
    }

    private addBridges(bridges: BridgeSimpleStatus[]) {
        const root = this.items.find(value => value.id === 'bridges');
        root.items = bridges.map(bridgeInfo => {
            return {
                label: bridgeInfo.name,
                routerLink: `/app/bridge/${bridgeInfo.uuid}`,
            };
        });
    }

    private addAdminTabs() {
        this.items.push({
            label: 'Admin dashboard',
            icon: 'pi pi-fw pi-briefcase',
            routerLink: ['/app/admin-dashboard']
        });
        this.items.push({
            label: 'Users',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/app/users']
        });
        this.items.push({
            label: 'Logs',
            icon: 'pi pi-table',
            routerLink: ['/app/logs']
        });
    }

    private addPasswordTab() {
        this.items.push({
            label: 'Change password',
            icon: 'pi pi-key',
            routerLink: ['/app/change-password']
        });
    }

    private addMarketWatchTab() {
        this.items.push({
            label: 'Market depth',
            icon: 'pi pi-angle-double-down',
            routerLink: ['/app/market-depth']
        });
    }

    private addNewMarketWatchTab() {
        this.items.push({
            label: 'Market Watch',
            icon: 'pi pi-dollar',
            routerLink: ['/app/market-watch']
        });
    }

    private addStatisticsTab() {
        this.items.push({
            label: 'Statistics',
            icon: 'pi pi-chart-bar',
            routerLink: ['/app/statistics']
        });
    }

    private addHedgeMonitorTab() {
        this.items.push({
            label: 'Hedge monitor',
            icon: 'pi pi-table',
            routerLink: ['/app/hedge-monitor']
        });
    }

    private addBridgesRoot() {
        const item: MenuItem = {
            id: 'bridges',
            label: 'Bridges',
            icon: 'pi pi-fw pi-cog',
            items: []
        };
        this.items.push(item);
    }

    private addExposureTab() {
        this.items.push({
            label: 'Exposure',
            icon: 'pi pi-fw pi-briefcase',
            routerLink: ['/app/exposure']
        });
    }
}
