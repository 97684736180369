import {ReportFilters, ReportQueryParams, ReportQueryParamsDetailed} from '../services/reports.service';

export class FilterParametersHelper {
  public getQueryParameters(filters: ReportFilters): ReportQueryParams | ReportQueryParamsDetailed {
    return {
      ...filters,
      from: this.toIsoOffsetString(filters.from),
      to: this.toIsoOffsetString(filters.to),
    };
  }

  private toIsoOffsetString(date: Date) {
    const tzOffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
      return (new Date(date.getTime() - tzOffset)).toISOString();
  }
}
