import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Exposition, ExpositionRule} from '../interfaces';

@Injectable()
export class ExpositionDataHttpService {
  readonly PATH = '/exposure/rules';

  constructor(private http: HttpClient) {}

  public getExposition(uuid: string): Observable<Exposition> {
    return this.http.get<Exposition>(`${environment.hostUrl}${this.PATH}/${uuid}/get-rules`);
  }

  public createExposition(uuid: string, bridgeName: string, exposition: ExpositionRule): Observable<Exposition> {
    return this.http.post<Exposition>(`${environment.hostUrl}${this.PATH}/${uuid}/add-rule/${bridgeName}`, exposition);
  }

  public updateExposition(uuid: string, bridgeName: string, exposition: ExpositionRule): Observable<Exposition> {
    return this.http.post<Exposition>(`${environment.hostUrl}${this.PATH}/${uuid}/update-rule/${bridgeName}`, exposition);
  }

  public deleteExposition(uuid: string, bridgeName: string, ruleUUID: string): Observable<Exposition> {
    return this.http.delete<Exposition>(`${environment.hostUrl}${this.PATH}/${uuid}/delete-rule/${bridgeName}/${ruleUUID}`);
  }
}
