import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from '../commons/loader.service';
import {SortEvent, TreeNode} from 'primeng/api';
import {Subscription, timer} from 'rxjs';
import {NotificationsService} from '../commons/notifications.service';
import {environment} from 'src/environments/environment';
import {filter, switchMap, tap} from 'rxjs/operators';
import {HedgeMonitorService} from './service/hedge-monitor.service';
import {AuthenticationService} from '../commons/security/authentication.service';
import {BridgesPublisherService} from '../all-bridges/services/bridges-publisher.service';
import {BridgeAbooksExposition, HedgeMonitorDto} from './dto/hedge-monitor-dto';
import {AccountInfo, AccountStatus} from './dto/account-info';
import {UserRole} from '../commons/dto/user-role';
import {BridgeSimpleStatus} from '../all-bridges/interfaces/bridge-simple-status';
import {UserService} from '../users/user.service';
import {ErrorManagerService} from '../commons/error/error-manager.service';
import {BridgeHMErros} from './dto/bridge-hm-errors.dto';
import {ManualHedgeRequest, PositionToHedge} from './dto/manual-hedge-request';
import {ManualHedgeService} from './service/manual-hedge.service';
import {TableField} from '../commons/interfaces/table-field';
import {ACCOUNT_COLUMNS, ERROR_COLUMNS, HEDGE_COLUMNS} from './helpers/table-columns';

@Component({
    selector: 'app-hedge-monitor',
    templateUrl: './hedge-monitor.component.html',
    styleUrls: ['./hedge-monitor.component.scss']
})
export class HedgeMonitorComponent implements OnInit, OnDestroy {
    public hedgeMonitor: HedgeMonitorDto;
    public isLoading = true;
    public abooksHedge: TreeNode[] = [];
    public accountInfos: AccountInfo[] = [];
    public errorBridges: BridgeHMErros[] = [];
    public bridgeSimpleInfos: { [key: string]: BridgeSimpleStatus } = {};
    public cols: TableField[] = HEDGE_COLUMNS;
    public accountCols: TableField[] = ACCOUNT_COLUMNS;
    public errorCols: TableField[] = ERROR_COLUMNS;
    public isUser = false;
    public isShowUnits = false;
    public showManualHedgeDialog = false;
    public manualHedgeClicked = false;

    private uuids: string[] = [];
    private isChildExpandedMap: { [key: string]: boolean } = {};
    private accountStatuses: AccountStatus[] = [];
    private sortEvent: SortEvent;
    private manualHedgeRequest: ManualHedgeRequest;
    private _sub: Subscription;

    constructor(private loadingService: LoaderService,
                private notificationService: NotificationsService,
                private hedgeMonitorService: HedgeMonitorService,
                private autohedgeService: ManualHedgeService,
                private authenticationService: AuthenticationService,
                private statusPublisher: BridgesPublisherService,
                private userService: UserService,
                private errorManger: ErrorManagerService) {
    }

    public ngOnInit(): void {
        this._sub = timer(0, environment.hedgeMonitorRefreshInterval)
            .pipe(
                switchMap(() => this.authenticationService.authState),
                filter(isLogged => isLogged),
                switchMap(() => this.statusPublisher.bridgesInfo),
                tap(data => {
                    data.forEach(bridgeStatus => this.bridgeSimpleInfos[bridgeStatus.uuid] = bridgeStatus);
                    this.uuids = data.map(bridgeStatus => bridgeStatus.uuid);
                }),
                switchMap(() => this.hedgeMonitorService.getDataForHedgeMonitor(this.uuids))
            ).subscribe(hedgeMonitorDto => {
                if (hedgeMonitorDto == null) {
                    this.notificationService.showConnectionErrorMessage('Hedge service unavailable');
                    this.errorBridges = [];
                    this.hedgeMonitor = null;
                    this.accountStatuses = [];
                    this.accountInfos = [];
                    this.abooksHedge = [];
                    return;
                }
                this.errorBridges = hedgeMonitorDto.bridgeHMErrors;
                this.hedgeMonitor = hedgeMonitorDto;
                this.isLoading = false;
                this.loadingService.setLoading(false);
                this.accountStatuses = hedgeMonitorDto.accountStatuses;
                this.accountInfos = this.accountStatuses.map(el => el.accountInfo);
                this.abooksHedge = this.convertHedgeDataToTreeNodeArray(this.hedgeMonitor.bridgeAbooks).filter(el => el.data.brokerVolume !== 0 || el.data.fixVolume !== 0);
                if (this.abooksHedge.length > 0) {
                    this.notificationService.showInfoMessage('Data has been updated!');
                }
                this.initSortEvent();
                this.customSort(this.sortEvent);
            });

        this.authenticationService.userInfo.subscribe(auth => this.isUser = auth.role !== UserRole.ADMIN);
    }

    public setAutoHedge(uuid: string, event: any): void {
        const bridgeInfo = this.bridgeSimpleInfos[uuid];
        bridgeInfo.autoHedgeEnabled = event.checked;
        this.abooksHedge = this.convertHedgeDataToTreeNodeArray(this.hedgeMonitor.bridgeAbooks).filter(el => el.data.brokerVolume !== 0 || el.data.fixVolume !== 0);
        if (event.checked) {
            this.notificationService.showInfoMessage('Please be informed that you have enabled option to automatically hedge all the differences between ' +
                'your liquidity account and abook clients accounts if they occur.');
        }
        this.userService.setSettings(bridgeInfo)
            .subscribe(() => {
                this.notificationService.showSuccessMessage('Bridge (uuid: ' + bridgeInfo.uuid + ') settings changed');
            }, err => this.errorManger.handle(err, {
                httpErrorMessage: 'Bridge (uuid: ' + bridgeInfo.uuid + ') could not change settings',
                badRequestMessage: 'Bridge (uuid: ' + bridgeInfo.failoverBridgeUuid + ') already has failover slave. Please choose other bridge.',
                onDone: () => {
                    this.bridgeSimpleInfos[uuid].autoHedgeEnabled = event.checked;
                }
            }));
    }

    public customSort(event: SortEvent): void {
        this.sortEvent = {
            data: event.data,
            field: event.field,
            mode: event.mode,
            order: event.order
        };
        event.data.sort((data1, data2) => {
            const value1 = data1.data[event.field];
            const value2 = data2.data[event.field];
            let result = null;
            if (value1 === undefined && value2 === undefined) {
                return 0;
            } else if (value1 === undefined) {
                return (event.order * -1);
            } else if (value2 === undefined) {
                return event.order;
            } else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
                return (event.order * result);
            }
        });
    }

    public onNodeExpand(event): void {
        this.isChildExpandedMap[event.node.data.alias] = true;
    }

    public onNodeCollapse(event): void {
        this.isChildExpandedMap[event.node.data.alias] = false;
    }

    public refreshBridgeData(uuid: string) {
        this.hedgeMonitorService.getDataForOneBridge(uuid)
            .subscribe(data => {
                this.updateHedgeMonitorTreeNodeArray(data);
                this.notificationService.showInfoMessage('Data has been updated!');
            });
    }

    public hedgeCurrentInstrument(rowData: any): void {
        this.manualHedgeRequest = {
            uuid: rowData.bridgeUuid,
            positionsToHedge: [{
                abookUuid: rowData.abookUuid,
                symbol: rowData.alias,
                volume: rowData.volumeDifference
            }]
        };
        this.showManualHedgeDialog = true;
    }

    public hedgeAllInstruments(rowData: any): void {
        const treeNodes = this.abooksHedge.find(el => el.data.uuid === rowData.uuid).children.filter(el => el.data.volumeDifference !== 0);
        this.manualHedgeRequest = {
            uuid: rowData.uuid,
            positionsToHedge: treeNodes.map(el => this.createPositionToHedge(el))
        };
        this.showManualHedgeDialog = true;
    }

    public manualHedge(): void {
        this.showManualHedgeDialog = false;
        this.manualHedgeClicked = true;
        this.autohedgeService.executeManualHedge(this.manualHedgeRequest)
            .subscribe(() => {
                this.hedgeMonitorService.getDataForOneBridge(this.manualHedgeRequest.uuid)
                    .subscribe(data => {
                        this.updateHedgeMonitorTreeNodeArray(data);
                        this.notificationService.showInfoMessage('Data has been updated!');
                        this.handleSuccessManualHedge();
                    }, err => this.handleErrorRefreshManualHedge());
            }, err => this.handleErrorManualHedge(err));
    }

    public trackByFn(index: number, row: any) {
        return row.node.children;
    }

    public rejectManualHedge(): void {
        this.manualHedgeRequest = null;
        this.showManualHedgeDialog = false;
    }

    public ngOnDestroy(): void {
        this._sub.unsubscribe();
    }

    private handleSuccessManualHedge(): void {
        this.notificationService.showInfoMessage('Positions has been successfully hedged');
        this.manualHedgeClicked = false;
    }

    private handleErrorRefreshManualHedge(): void {
        this.notificationService.showInfoMessage('Error occurred during hedge monitor data refresh, after successful manual hedge');
        this.manualHedgeClicked = false;
    }

    private handleErrorManualHedge(err: any): void {
        const symbols = err.error.map(el => el.symbol);
        this.manualHedgeClicked = false;
        this.notificationService.showWarningMessage('Manual hedge problem', `Not all positions has been hedged: ${symbols}`);
    }

    private initSortEvent(): void {
        if (!this.sortEvent) {
            this.sortEvent = {
                data: this.abooksHedge,
                field: 'ml',
                mode: 'single',
                order: 0
            };
        }
    }

    private convertHedgeDataToTreeNodeArray(bridgeBbooks: BridgeAbooksExposition[]): TreeNode[] {
        return bridgeBbooks.map(bridge => this.generateTreeNodeForBridge(bridge));
    }

    private updateHedgeMonitorTreeNodeArray(bridgeHMData: HedgeMonitorDto): void {
        if (bridgeHMData == null) {
            return;
        }

        if (bridgeHMData.bridgeAbooks != null && bridgeHMData.bridgeAbooks.length > 0) {
            const treeNodeIdx = this.abooksHedge.findIndex(abook => abook.data.uuid === bridgeHMData.bridgeAbooks[0].uuid);
            this.abooksHedge[treeNodeIdx] = this.generateTreeNodeForBridge(bridgeHMData.bridgeAbooks[0]);
        }


        if (bridgeHMData.accountStatuses != null && bridgeHMData.accountStatuses.length > 0) {
            const idx = this.accountStatuses.findIndex(account => account.uuid === bridgeHMData.accountStatuses[0].uuid);
            this.accountStatuses[idx] = bridgeHMData.accountStatuses[0];
        }

        this.accountInfos = this.accountStatuses.map(el => el.accountInfo);
        this.abooksHedge = [...this.abooksHedge];
    }

    private generateTreeNodeForBridge(bridge: BridgeAbooksExposition): TreeNode {
        let volDiffSum = 0.0;
        let volDiffSumUnit = 0.0;
        let volMT4Sum = 0.0;
        let volMT4SumUnit = 0.0;
        let volLPSum = 0.0;
        let volLPSumUnit = 0.0;

        const children = bridge.abooks.filter(abook => +abook.brokerVolume !== 0 || +abook.fixVolume !== 0 || +abook.positionsDifference !== 0).map(row => {
            const grandChildren: TreeNode[] = [];
            row.children.map(child => {
                grandChildren.push({data: child});
            });

            volDiffSum += +row.volumeDifference;
            volDiffSumUnit += +row.volumeDifferenceInUnits;
            volMT4Sum += +row.brokerVolume;
            volMT4SumUnit += +row.brokerVolumeInUnits;
            volLPSum += +row.fixVolume;
            volLPSumUnit += +row.lpVolumeInUnits;
            row.bridgeUuid = bridge.uuid;
            return {
                data: row,
                children: grandChildren,
                expanded: this.isChildExpandedMap[row.alias] !== undefined ? this.isChildExpandedMap[row.alias] : false
            };
        });

        const isRed = (children.filter(child => +child.data.volumeDifference !== 0 || +child.data.positionsDifference !== 0).length > 0 && !bridge.bridgeName.toLowerCase().includes('reverse')) ||
            (bridge.bridgeName.toLowerCase().includes('reverse') && children.filter(child => (-1 * +child.data.brokerVolume) !== +child.data.fixVolume).length > 0);
        const currentAccount = this.accountStatuses.find(el => el.uuid === bridge.uuid);
        const ml = currentAccount !== undefined && currentAccount.accountInfo != null ? +currentAccount.accountInfo.marginLevel : 0;
        children.forEach(el => el.data.enableHedgeBtnForInstrument = !this.bridgeSimpleInfos[bridge.uuid].autoHedgeEnabled && (+el.data.volumeDifference !== 0 || +el.data.positionsDifference !== 0));
        return {
            data: {
                uuid: bridge.uuid,
                alias: bridge.bridgeName,
                volumeDifference: +volDiffSum.toFixed(2),
                volumeDifferenceInUnits: +volDiffSumUnit.toFixed(2),
                brokerVolume: +volMT4Sum.toFixed(2),
                brokerVolumeInUnits: +volMT4SumUnit.toFixed(2),
                fixVolume: +volLPSum.toFixed(2),
                lpVolumeInUnits: +volLPSumUnit.toFixed(2),
                ml: +ml.toFixed(2),
                isRed: isRed,
                enableHedgeBtn: !this.bridgeSimpleInfos[bridge.uuid].autoHedgeEnabled && +volDiffSum.toFixed(2) !== 0
            },
            children: children,
            expanded: bridge.abooks.length < 2 || this.isChildExpandedMap[bridge.bridgeName] !== undefined
                ? this.isChildExpandedMap[bridge.bridgeName] : false
        };
    }

    private createPositionToHedge(node: TreeNode): PositionToHedge {
        return {
            abookUuid: node.data.abookUuid,
            symbol: node.data.alias,
            volume: node.data.volumeDifference
        };
    }
}
