import {Component} from '@angular/core';
import {BridgeDetailedStatus} from '../../commons/model/status/bridge-detailed-status';
import {BridgeDetailsPublisherService} from '../../commons/bridge-details-publisher.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-bridge-info',
    templateUrl: './bridge-info.component.html',
    styleUrls: ['./bridge-info.component.scss']
})
export class BridgeInfoComponent {
    details: Observable<BridgeDetailedStatus>;

    constructor(private bridgeDetailsPublisher: BridgeDetailsPublisherService) {
        this.details = this.bridgeDetailsPublisher.bridgeInfo;
    }
}
