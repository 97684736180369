import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DateRange} from '../../interfaces/date-range';
import {DateSnack} from '../../interfaces';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-date-block',
  templateUrl: './date-block.component.html',
  styleUrls: ['./date-block.component.scss']
})
export class DateBlockComponent implements OnChanges {
  @Input() public labels: DateSnack[];
  @Input() public selectedLabel: string;

  @Output() selectedDate = new EventEmitter<string>();
  @Output() setDateRangeClick = new EventEmitter<DateRange>();

  public dateFrom: Date | null;
  public dateTo: Date | null;
  public currentDate = new Date();

  constructor() {}

  public ngOnChanges({selectedLabel}: SimpleChanges) {
    if (selectedLabel && selectedLabel.currentValue) {
      this.dateFrom = new Date(selectedLabel.currentValue);
      this.dateTo = this.currentDate;
    }
  }

  public onSelectLabelClick(id: string): void {
    this.selectedDate.emit(id);
  }

  public onSetDateClick(): void {
    const datePipe = new DatePipe('en');
    this.setDateRangeClick.emit({
      from: datePipe.transform(this.dateFrom, 'yyyy-MM-dd'),
      to: datePipe.transform(this.dateTo, 'yyyy-MM-dd')
    });
  }
}
