import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {BridgeSettings, buildOrderBookSettings, SessionSettings, UserSettings} from '../../commons/dto/user-dto';
import {LPSession} from '../../details-bridge/commons/model/symbol/lp/l-p-session';
import {LPSymbol} from '../../details-bridge/commons/model/symbol/lp/lp-symbol';
import {BrokerSession} from '../../details-bridge/commons/model/symbol/broker/broker-session';
import {BrokerSymbol} from '../../details-bridge/commons/model/symbol/broker/broker-symbol';
import {combineLatest, Subscription} from 'rxjs';
import {AuthenticationService} from '../../commons/security/authentication.service';
import {LPSymbolsService} from '../../details-bridge/commons/lp-symbols.service';
import {BrokerSymbolsService} from '../../details-bridge/commons/broker-symbols.service';
import {CallWrapperService} from '../../commons/call-wrapper.service';
import {UserSettingsService} from '../../commons/user-settings.service';
import {NotificationsService} from '../../commons/notifications.service';
import {SelectedBridgeService} from '../../market-depth/selected-bridge.service';

@Component({
    selector: 'app-mw-instrument-selector',
    templateUrl: './mw-instrument-selector.component.html',
    styleUrls: ['./mw-instrument-selector.component.scss']
})
export class MwInstrumentSelectorComponent implements OnInit, OnDestroy {
    @Output()
    submit = new EventEmitter<BridgeSettings>();
    settings: BridgeSettings;
    lpSessions: LPSession[];
    selectedLpSessions: { [key: string]: LPSymbol[]; };
    brokerSessions: BrokerSession[];
    selectedBrokerSessions: { [key: string]: BrokerSymbol[]; };
    uuid = '';
    private _subscription: Subscription;

    constructor(private authService: AuthenticationService,
                private lpSymbolService: LPSymbolsService,
                private brokerSymbolService: BrokerSymbolsService,
                private callWrapper: CallWrapperService,
                private userSettings: UserSettingsService,
                private notifications: NotificationsService,
                private selectedBridgeService: SelectedBridgeService) {
    }

    ngOnInit() {
        this.initSymbols();
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    saveChanges() {
        const lpSessionSettings: SessionSettings[] = Object.entries(this.selectedLpSessions)
            .map(v => {
                return {
                    sessionName: v[0],
                    obs: v[1].map(value => buildOrderBookSettings(value.symbolName))
                };
            });

        const brokerSessionSettings: SessionSettings[] = Object.entries(this.selectedBrokerSessions).map(v => {
            return {
                sessionName: v[0],
                obs: v[1].map(value => buildOrderBookSettings(value.symbolName))
            };
        });

        const settings: BridgeSettings = {
            uuid: this.uuid,
            lpObSettings: this.settings ? this.settings.lpObSettings : [],
            brokerObSettings: this.settings ? this.settings.brokerObSettings : [],
            brokerNewMwSettings: brokerSessionSettings,
            lpNewMwSettings: lpSessionSettings,
        };

        this.submit.emit(settings);
    }

    initSymbols() {
        this._subscription = this.selectedBridgeService.bridgeChange.subscribe(uuid => {
            this.uuid = uuid;
            combineLatest([this.brokerSymbolService.getSessions(this.uuid),
                this.lpSymbolService.getSessions(this.uuid),
                this.userSettings.getBridgeSettings(this.uuid)])
                .subscribe(data => {
                    this.brokerSessions = data[0];
                    this.lpSessions = data[1];
                    this.settings = data[2];

                    if (this.settings) {
                        this.setSelectedItems(this.settings);
                    } else {
                        this.setSessions();
                    }
                });
        });
    }

    private setSelectedItems(settings: BridgeSettings) {
        this.selectedLpSessions = {};
        settings.lpNewMwSettings.forEach(lpSession => {
            const obs = new Set<string>(lpSession.obs.map(ob => ob.symbol));
            const lpSymbols = this.lpSessions.find(lp => lp.sessionName === lpSession.sessionName);
            if (lpSymbols) {
                this.selectedLpSessions[lpSession.sessionName] = lpSymbols.symbols.filter(symbol => obs.has(symbol.symbolName));
            }
        });

        this.selectedBrokerSessions = {};
        settings.brokerNewMwSettings.forEach(brokerSession => {
            const obs = new Set<string>(brokerSession.obs.map(ob => ob.symbol));
            const brokerSymbols = this.brokerSessions.find(lp => lp.sessionName === brokerSession.sessionName);
            if (brokerSymbols) {
                this.selectedBrokerSessions[brokerSession.sessionName] = brokerSymbols.symbols.filter(symbol => obs.has(symbol.symbolName));
            }
        });
    }

    private setSessions() {
        this.selectedLpSessions = {};
        this.lpSessions.forEach(session => {
            this.selectedLpSessions[session.sessionName] = [];
        });

        this.selectedBrokerSessions = {};
        this.brokerSessions.forEach(session => {
            this.selectedBrokerSessions[session.sessionName] = [];
        });
    }
}
