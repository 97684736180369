import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class SymbolsChangeService {

    private subject = new Subject<any>();
    displayDialog = this.subject.asObservable();
    tabChanged = this.subject.asObservable();

    constructor() {
    }

    setShowDialog(isVisible: boolean, name: string, session: string = '', bridge: string = '') {
        this.displayDialog['name'] = name;
        this.displayDialog['show'] = isVisible;
        this.displayDialog['session'] = session;
        this.displayDialog['bridge'] = bridge;

        this.subject.next(this.displayDialog);
    }

    mainTabChanged(isTabChanged: boolean) {
        this.tabChanged['tabChanged'] = isTabChanged;
        this.subject.next(this.tabChanged);
    }
}
