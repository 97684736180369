import {Component} from '@angular/core';
import {LoaderService} from './commons/loader.service';
import {mainRouteAnimations} from './commons/animations';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [mainRouteAnimations]
})
export class AppComponent {
    isLoading = false;

    constructor(private loaderService: LoaderService) {
        loaderService.loader.subscribe(value => this.isLoading = value);
    }
}
